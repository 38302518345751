import styled from "styled-components";
import imgMarker2 from "../../img/Marker2Dark.png";
import imgMarker3 from "../../img/Marker3Long.png";
import btnImg from "../../img/miniIcons/Button.png";

export const Wrapper = styled.div`
  position: absolute;
  bottom: 72px;
  left: 436px;
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 27px;
  height: 27px;
  background-image: url("${btnImg}");
  background-size: cover;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  will-change: transform;
  & img {
    backface-visibility: hidden;
    will-change: transform;
    transition: 0.2s ease;
  }
  &:hover img {
    filter: brightness(1.2);
  }
  &:active img {
    filter: brightness(0.9);
  }
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 0.7fr 1fr;
  gap: 10px;
  padding-bottom: 10px;

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
`;

export const Header = styled.span`
  color: #ffcf82;
  font-size: 18px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 3px;
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  font-size: 12px;
  font-weight: 600;
  height: ${(props) => props.height && `${props.height}px`};
  gap: ${(props) => (props.gap ? `${props.gap}px` : "6px")};
`;

export const ColumnLeft = styled(Column)`
  justify-content: space-between;
  min-height: 400px;
`;

export const ColumnRight = styled(Column)`
  padding: 0;
  min-height: 280px;
  max-height: 280px;
`;

export const ColumnRight2 = styled(ColumnRight)`
  min-height: 110px;
  max-height: 110px;
`;

export const ColumnBottom = styled(Column)`
  position: relative;
  padding-bottom: 20px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
  }
`;

export const StringRef = styled.div`
  cursor: pointer;
  font-weight: 400;
`;

export const Names = styled.div`
  width: 100%;
  gap: 5px;
  display: grid;
  grid-template-columns: 0.8fr 0.8fr 1.5fr 0.7fr 1.2fr;
  padding: 10px 15px 10px 10px;
  border-bottom: 2px solid #fc9800;
`;

export const Users = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  gap: 5px;
  padding: 0px 5px;
  margin: 0px 5px 5px 5px;
`;

export const User = styled.div`
  width: 100%;
  gap: 5px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  line-height: 15px;
  grid-template-columns: 0.8fr 0.8fr 1.5fr 0.7fr 1.2fr;

  background-image: url("${(props) =>
    props.isActive ? imgMarker2 : imgMarker3}");
  background-size: contain;

  & div {
    overflow: hidden;
    width: 100%;
    justify-content: center;
  }
`;

export const Avatar = styled.span`
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};
  display: flex;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
`;

export const Place = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

export const History = styled.div`
  overflow-y: scroll;
  flex: 1;
  gap: 5px;
  padding: 0px 10px;
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-weight: 400;
  & > div {
    display: flex;
    gap: 2px;
    & > span:first-child {
      font-weight: 600;
    }
  }
`;

export const BottomRight = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const StringBottom = styled.span`
  font-size: 11px;
`;
