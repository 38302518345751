import React, { useEffect, useState } from "react";

import * as S from "./Map.styles";

import backImg from "../../img/map/MapBackWinter.png";
import closeImg from "../../img/map/Close.png";
import closeHoverImg from "../../img/map/CloseHover.png";
import infoImg from "../../img/map/Info.png";
import infoHoverImg from "../../img/map/InfoHover.png";
import topImg from "../../img/map/Top.png";
import homeImg from "../../img/map/Home.png";
import bankImg from "../../img/map/Bank.png";
import classroomsImg from "../../img/map/Classrooms.png";
import arenaImg from "../../img/map/Arena.png";
import ordenImg from "../../img/map/Orden.png";
import gamesImg from "../../img/map/Games.png";
import worksImg from "../../img/map/Works.png";
import newsImg from "../../img/map/News.png";
import bossesImg from "../../img/map/Bosses.png";
import noteImg from "../../img/map/Note.png";
import potionRoomImg from "../../img/map/PotionRoom.png";
import grassRoomImg from "../../img/map/GrassRoom.png";
import potionRoomHoverImg from "../../img/map/PotionRoomHover.png";
import grassRoomHoverImg from "../../img/map/GrassRoomHover.png";
import potionLabImg from "../../img/map/PotionLab.png";
import potionLabHoverImg from "../../img/map/PotionLabHover.png";
import greenHouseImg from "../../img/map/GreenHouse.png";
import greenHouseHoverImg from "../../img/map/GreenHouseHover.png";
import soloBossesImg from "../../img/map/SoloBosses.png";
import soloBossesHoverImg from "../../img/map/SoloBossesHover.png";
import bosses1Img from "../../img/map/Bosses1.png";
import bosses1HoverImg from "../../img/map/Bosses1Hover.png";
import bosses2Img from "../../img/map/Bosses2.png";
import bosses2HoverImg from "../../img/map/Bosses2Hover.png";
import arenaTournamentImg from "../../img/map/ArenaTournament.png";
import game21TournamentImg from "../../img/map/Game21Tournament.png";
import diceGameTournamentImg from "../../img/map/DiceGameTournament.png";
import shytImg from "../../img/map/Shyt.png";
import raidBoss1Img from "../../img/map/RaidBoss1.png";
import raidBoss2Img from "../../img/map/RaidBoss2.png";
import raidBoss3Img from "../../img/map/RaidBoss3.png";
import raidBoss4Img from "../../img/map/RaidBoss4.png";
import raidBoss5Img from "../../img/map/RaidBoss5.png";
import raidBoss6Img from "../../img/map/RaidBoss6.png";
import bankSaleImg from "../../img/map/BankSale.png";
import newsHoverImg from "../../img/map/NewsHover.png";
import pageArrowImg from "../../img/map/PageArrow.png";
import pageArrowHoverImg from "../../img/map/PageArrowHover.png";

import Popup from "../Popup/Popup";

const locationsOpen = {
  news: {
    name: "События",
    info: [
      {
        type: "news",
        location: "raidBoss",
        name: "Рейд Босс",
        src: [
          raidBoss1Img,
          raidBoss2Img,
          raidBoss3Img,
          raidBoss4Img,
          raidBoss5Img,
          raidBoss6Img,
        ],
        srcHover: newsHoverImg,
      },
      {
        type: "news",
        location: "shyt",
        name: "Шут",
        src: shytImg,
        srcHover: newsHoverImg,
      },
      {
        type: "news",
        location: "gameRoom",
        name: "Турнир 21",
        src: game21TournamentImg,
        srcHover: newsHoverImg,
      },
      {
        type: "news",
        location: "gameRoom",
        name: "Турнир Кости",
        src: diceGameTournamentImg,
        srcHover: newsHoverImg,
      },
      {
        type: "news",
        location: "arena",
        name: "Турнир Дуэлей",
        src: arenaTournamentImg,
        srcHover: newsHoverImg,
      },
      {
        type: "news",
        location: "bank",
        name: "Банк",
        src: bankSaleImg,
        srcHover: newsHoverImg,
      },
    ],
  },
  bosses: {
    name: "Боссы",
    info: [
      {
        location: "soloBoss",
        name: "Соло Боссы",
        src: soloBossesImg,
        srcHover: soloBossesHoverImg,
      },
      {
        location: "bosses",
        name: "Территория замка",
        src: bosses1Img,
        srcHover: bosses1HoverImg,
      },
      {
        location: "bosses2",
        name: "Окресности замка",
        src: bosses2Img,
        srcHover: bosses2HoverImg,
      },
    ],
  },
  works: {
    name: "Практика",
    info: [
      {
        location: "greenHouse",
        name: "Растениеводство",
        src: greenHouseImg,
        srcHover: greenHouseHoverImg,
      },
      {
        location: "potionLab",
        name: "Зельеварение",
        src: potionLabImg,
        srcHover: potionLabHoverImg,
      },
    ],
  },
  classrooms: {
    name: "Уроки",
    info: [
      {
        location: "grassRoom",
        name: "Растениеводство",
        src: grassRoomImg,
        srcHover: grassRoomHoverImg,
      },
      {
        location: "potionRoom",
        name: "Зельеварение",
        src: potionRoomImg,
        srcHover: potionRoomHoverImg,
      },
    ],
  },
};

const locations = [
  {
    isOpen: true,
    location: "bosses",
    name: "Бестиарий",
    x: 651,
    y: 274,
    w: 172,
    h: 253,
    src: bossesImg,
    clip: "polygon(0 0, 100% 0, 100% 100%, 13% 100%, 22% 61%, 0 56%)",
  },
  {
    location: "topsRoom",
    name: "Зал Славы",
    x: 392,
    y: 131,
    w: 131,
    h: 100,
    src: topImg,
  },
  {
    location: "home",
    name: "Гостиная",
    x: 257,
    y: 218,
    w: 105,
    h: 81,
    src: homeImg,
  },
  {
    location: "bank",
    name: "Банк",
    x: 133,
    y: 339,
    w: 129,
    h: 105,
    src: bankImg,
  },
  {
    isOpen: true,
    location: "classrooms",
    name: "Уроки",
    x: 260,
    y: 332,
    w: 131,
    h: 89,
    src: classroomsImg,
    clip: "polygon(0 0, 100% 0, 100% 75%, 47% 75%, 39% 100%, 0 100%)",
  },
  {
    location: "arena",
    name: "Дуэли",
    x: 306,
    y: 407,
    w: 132,
    h: 61,
    src: arenaImg,
  },
  {
    isPopup: true,
    location: "ordens",
    name: "Ордена",
    x: 400,
    y: 231,
    w: 113,
    h: 123,
    src: ordenImg,
  },
  {
    location: "gameRoom",
    name: "Игровая",
    x: 527,
    y: 468,
    w: 147,
    h: 65,
    src: gamesImg,
  },
  {
    isOpen: true,
    location: "works",
    name: "Практика",
    x: 524,
    y: 332,
    w: 154,
    h: 131,
    src: worksImg,
    clip: "polygon(0 0, 87% 0, 87% 65%, 97% 87%, 97% 100%, 0 100%)",
  },

  {
    isOpen: true,
    location: "news",
    name: "События",
    x: 41,
    y: 441,
    w: 249,
    h: 207,
    src: newsImg,
  },
];

function Map({
  isOpen,
  setIsOpen,
  isFetching,
  setActivePanel,
  changeActiveMenuModal,
  activePanel,
  setModalError,
  disableMove,
  serverTime,
  admin,
  arenaTournamentRegister,
  activeBoss,
  raidBoss,
  setActiveMenuModal,
  activeArenaTournament,
  setActiveArenaTournament,
  activeTournament,
  setActiveTournament,
  isRaidBoss,
  setIsRaidBoss,
  isShyt,
  setIsShyt,
  isRaid,
  setIsRaid,
  orden,
  setOrdenBonus,
}) {
  const [locationsOpenArray, setLocationsOpenArray] = useState([]);
  const [locationsArray, setLocationsArray] = useState({ ...locationsOpen });
  const [notePage, setNotePage] = useState(0);

  const [note, setNote] = useState("");

  const [isDamage, setIsDamage] = useState(false);
  const [isStock, setIsStock] = useState(false);
  const [isBox, setIsBox] = useState(false);

  useEffect(() => {
    if (note) {
      if (
        locationsArray[note].info.length > -(note === "news" ? 1 : 2) &&
        notePage > locationsArray[note].info.length - -(note === "news" ? 1 : 2)
      ) {
        setNotePage(
          locationsArray[note].info.length - -(note === "news" ? 1 : 2)
        );
      }
      if (locationsArray[note].info.length <= 1) {
        setNotePage(0);
      }

      if (note === "news") {
        setLocationsOpenArray(
          locationsArray[note].info.slice(notePage, notePage + 1)
        );
      } else {
        setLocationsOpenArray(
          locationsArray[note].info.slice(notePage, notePage + 2)
        );
      }
    } else {
      setNotePage(0);
      setLocationsOpenArray([]);
    }
  }, [note, notePage, locationsArray]);

  useEffect(() => {
    if (orden) {
      const bonus = {};
      const bonusTalent = orden.bonus?.talent;
      const bonusEssence = orden.bonus?.essence;
      if (
        bonusTalent &&
        serverTime > bonusTalent.startTime &&
        serverTime < bonusTalent.startTime + bonusTalent.time * 3600000
      ) {
        bonus.talent = bonusTalent;
      }
      if (
        bonusEssence &&
        serverTime > bonusEssence.startTime &&
        serverTime < bonusEssence.startTime + bonusEssence.time * 3600000
      ) {
        bonus.essence = bonusEssence;
      }
      if (bonus.talent || bonus.essence) {
        setOrdenBonus(bonus);
      } else {
        setOrdenBonus(null);
      }
    } else {
      setOrdenBonus(null);
    }

    if (
      orden &&
      admin.isRaid?.time * 3600 -
        Math.floor((serverTime - admin?.isRaid?.startTime) / 1000) >
        0 &&
      admin?.isRaid?.startTime <= serverTime
    ) {
      setIsRaid({ name: "isRaid", noteTime: admin?.isRaid?.startTime });
    } else {
      setIsRaid(false);
    }

    if (
      !raidBoss.isEnd &&
      admin.raidBoss.isActive > -1 &&
      serverTime > admin.raidBoss.endTime - admin.raidBoss.maxTime * 3600000
    ) {
      if (!raidBoss.isAttack && serverTime > admin.raidBoss.endTime) {
        setIsRaidBoss(null);
      } else {
        setIsRaidBoss({
          noteTime: admin.raidBoss.endTime - admin.raidBoss.maxTime * 3600000,
          endTime: admin.raidBoss.endTime,
        });
      }
    } else {
      setIsRaidBoss(null);
    }

    if (
      (serverTime > admin.shyt.startTime &&
        serverTime < admin.shyt.startTime + admin.shyt.time * 3600000) ||
      (activeBoss.number > -1 && activeBoss.isShyt)
    ) {
      setIsShyt({ name: "shyt", noteTime: admin.shyt.startTime });
    } else {
      setIsShyt(null);
    }

    if (
      serverTime <
        admin.diceGameTournament.startTime +
          admin.diceGameTournament.time * 3600000 &&
      serverTime > admin.diceGameTournament.startTime
    ) {
      setActiveTournament({
        name: "diceGameTournament",
        noteTime: admin.diceGameTournament.startTime,
      });
    } else if (
      serverTime <
        admin.game21Tournament.startTime +
          admin.game21Tournament.time * 3600000 &&
      serverTime > admin.game21Tournament.startTime
    ) {
      setActiveTournament({
        name: "game21Tournament",
        noteTime: admin.game21Tournament.startTime,
      });
    } else {
      setActiveTournament("");
    }

    if (
      serverTime > admin.arenaTournament.registerTime &&
      (serverTime < admin.arenaTournament.startTime ||
        arenaTournamentRegister) &&
      serverTime <
        admin.arenaTournament.startTime + admin.arenaTournament.time * 3600000
    ) {
      setActiveArenaTournament({
        name: "arenaTournament",
        startTime: admin.arenaTournament.startTime,
        noteTime: admin.arenaTournament.registerTime,
      });
    } else {
      setActiveArenaTournament(false);
    }

    if (
      serverTime > admin.isDamageStock.startTime &&
      serverTime <
        admin.isDamageStock.startTime + admin.isDamageStock.time * 3600000
    ) {
      setIsDamage(true);
    } else {
      setIsDamage(false);
    }

    const secs =
      admin.stock?.time * 3600 -
      Math.floor((serverTime - admin?.stock?.startTime) / 1000);
    if (secs > 0 && admin?.stock?.startTime <= serverTime) {
      setIsStock(true);
    } else {
      setIsStock(false);
    }

    const secsBox =
      admin.isBoxStock?.time * 3600 -
      Math.floor((serverTime - admin?.isBoxStock?.startTime) / 1000);
    if (secsBox > 0 && admin?.isBoxStock?.startTime <= serverTime) {
      setIsBox(true);
    } else {
      setIsBox(false);
    }
  }, [serverTime, admin]);

  useEffect(() => {
    const arrayNews = locationsOpen.news.info.filter((elem) => {
      if (elem.name === "Рейд Босс" && isRaidBoss) return true;
      if (elem.name === "Турнир Дуэлей" && activeArenaTournament) return true;
      if (elem.name === "Шут" && isShyt) return true;
      if (
        elem.name === "Турнир 21" &&
        activeTournament?.name === "game21Tournament"
      )
        return true;
      if (
        elem.name === "Турнир Кости" &&
        activeTournament?.name === "diceGameTournament"
      )
        return true;

      if (elem.name === "Банк" && (isDamage || isStock || isBox)) return true;

      return false;
    });

    setLocationsArray({
      ...locationsOpen,
      news: {
        name: "События",
        info: arrayNews,
      },
    });
  }, [
    activeArenaTournament,
    activeTournament,
    isRaidBoss,
    isShyt,
    isDamage,
    isStock,
    isBox,
  ]);

  function handleClose() {
    setIsOpen("");
  }

  function changeNotePage(page) {
    if (page > 0) {
      setNotePage((prev) => prev + 1);
    } else {
      setNotePage((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }
  }

  function changeNote(note) {
    setNote(note);
  }

  function moveLocation({ name, isPopup }) {
    if (isPopup) {
      setActiveMenuModal(name);
      setNote("");
    } else if (activePanel !== name) {
      changeActiveMenuModal();
      setActivePanel(name);
      setNote("");
      handleClose();
    } else {
      setModalError("Вы уже находитесь в этой локации");
    }
  }

  return (
    <>
      <S.Wrapper isOpen={isOpen} onClick={handleClose}>
        <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
          <S.Close onClick={handleClose}>
            <img width={36} height={36} src={closeImg} alt="" />
            <img width={36} height={36} src={closeHoverImg} alt="" />
          </S.Close>
          <S.Info>
            <img width={36} height={36} src={infoImg} alt="" />
            <img width={36} height={36} src={infoHoverImg} alt="" />
          </S.Info>

          <div>
            {locations.map((elem, i) => {
              return (
                <S.LocationImg
                  onClick={
                    !isFetching && !disableMove && !elem.isOpen
                      ? moveLocation.bind(null, {
                          name: elem.location,
                          isPopup: elem.isPopup,
                        })
                      : elem.isOpen
                      ? changeNote.bind(null, elem.location)
                      : null
                  }
                  {...elem}
                  key={i}
                  src={elem.src}
                />
              );
            })}
          </div>
        </S.Content>
      </S.Wrapper>

      <Popup
        isOpen={isOpen && note}
        setIsOpen={setNote}
        w={900}
        h={340}
        back={noteImg}
      >
        <S.CloseNote onClick={changeNote.bind(null, "")}>
          <img width={36} height={36} src={closeImg} alt="" />
          <img width={36} height={36} src={closeHoverImg} alt="" />
        </S.CloseNote>
        {note && locationsArray[note] && (
          <>
            <S.NameNote>{locationsArray[note].name}</S.NameNote>

            <S.ArrowPagePrev
              disabled={notePage - 1 < 0}
              onClick={notePage - 1 >= 0 ? changeNotePage.bind(null, -1) : null}
            >
              <img width={56} height={56} src={pageArrowImg} alt="" />
              <img width={56} height={56} src={pageArrowHoverImg} alt="" />
            </S.ArrowPagePrev>

            <S.ArrowPage
              disabled={
                notePage >=
                locationsArray[note].info.length - (note === "news" ? 1 : 2)
              }
              onClick={
                notePage + 1 <=
                locationsArray[note].info.length - (note === "news" ? 1 : 2)
                  ? changeNotePage.bind(null, 1)
                  : null
              }
            >
              <img width={56} height={56} src={pageArrowImg} alt="" />
              <img width={56} height={56} src={pageArrowHoverImg} alt="" />
            </S.ArrowPage>

            <S.LocationsNote>
              {locationsOpenArray.map(
                ({ src, srcHover, location, name, type }, i) => {
                  return (
                    <S.LocationNote
                      key={name}
                      type={type}
                      location={location}
                      onClick={
                        !isFetching && !disableMove && location
                          ? moveLocation.bind(null, {
                              name: location,
                            })
                          : null
                      }
                    >
                      {location === "raidBoss" &&
                      admin.raidBoss.isActive > -1 ? (
                        <img
                          width={300}
                          height={233}
                          src={src[admin.raidBoss.isActive]}
                          alt={name}
                        />
                      ) : (
                        <img width={300} height={233} src={src} alt={name} />
                      )}

                      {srcHover && (
                        <img
                          width={300}
                          height={233}
                          src={srcHover}
                          alt={name}
                        />
                      )}
                    </S.LocationNote>
                  );
                }
              )}
            </S.LocationsNote>
          </>
        )}
      </Popup>
    </>
  );
}

export default React.memo(Map);
