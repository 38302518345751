import React, { useCallback, useEffect, useRef, useState } from "react";
import Dice from "../Dice/Dice";

import * as S from "./DiceGame.styles";
import Popup from "../Popup/Popup";
import Reward from "../Reward/Reward";

import { Close } from "../../App.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";

import betFrame from "../../img/BetFrame.png";

import { Button } from "../../panels/Arena/Arena.styles";
import { ButtonsXWrapper, ButtonXChange } from "../Game21/Game21.styles";
import { Header } from "../Ordens/Ordens.styles";

const coordsDices = [
  {
    x: 0,
    y: -60,
  },
  {
    x: 60,
    y: 0,
  },
  {
    x: -60,
    y: 0,
  },
  {
    x: 0,
    y: 60,
  },
];

const prizes = [
  {
    numbers: 1,
    counts: {
      2: {
        property: "silver",
        name: "серебро",
        count: 100,
      },
      3: {
        property: "silver",
        name: "серебро",
        count: 200,
      },
      4: {
        property: "silver",
        name: "серебро",
        count: 600,
      },
    },
  },
  {
    numbers: 2,
    counts: {
      2: {
        property: "mana",
        name: "мана",
        count: 5,
      },
      3: {
        property: "mana",
        name: "мана",
        count: 10,
      },
      4: {
        property: "mana",
        name: "мана",
        count: 30,
      },
    },
  },
  {
    numbers: 3,
    counts: {
      2: {
        property: "silver",
        name: "серебро",
        count: 250,
      },
      3: {
        property: "silver",
        name: "серебро",
        count: 500,
      },
      4: {
        property: "silver",
        name: "серебро",
        count: 1500,
      },
    },
  },
  {
    numbers: 4,
    counts: {
      2: {
        property: "mana",
        name: "мана",
        count: 20,
      },
      3: {
        property: "mana",
        name: "мана",
        count: 40,
      },
      4: {
        property: "mana",
        name: "мана",
        count: 120,
      },
    },
  },
  {
    numbers: 5,
    counts: {
      2: {
        property: "soul",
        number: 1,
        count: 1,
      },
      3: {
        property: "soul",
        number: 2,
        count: 1,
      },
      4: {
        property: "soul",
        number: 3,
        count: 1,
      },
    },
  },
  {
    numbers: 6,
    counts: {
      2: {
        property: "gold",
        name: "золото",
        count: 4,
      },
      3: {
        property: "gold",
        name: "золото",
        count: 8,
      },
      4: {
        property: "gold",
        name: "золото",
        count: 24,
      },
    },
  },
];

function checkPrizes(dices, isComb = false) {
  const obj = dices.reduce((acc, cur) => {
    acc[cur] = acc[cur] ? acc[cur] + 1 : 1;
    return acc;
  }, {});

  if (isComb) {
    return obj;
  }

  const prizesInfo = [];
  Object.keys(obj).map((key) => {
    if (obj[key] > 1) {
      prizesInfo.push(prizes.find((el) => el.numbers == key).counts[obj[key]]);
    }
  });
  return prizesInfo;
}

const chancesArray = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
  2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6,
  6, 6, 6,
];

function randomNumber() {
  return chancesArray[Math.floor(Math.random() * chancesArray.length)];
}

function upLvlCheck(lvl) {
  if (lvl >= 500) {
    return {
      activeDices: 4,
      maxRolls: 6,
    };
  } else if (lvl >= 250) {
    return {
      activeDices: 4,
      maxRolls: 5,
    };
  } else if (lvl >= 100) {
    return {
      activeDices: 4,
      maxRolls: 4,
    };
  } else if (lvl >= 50) {
    return {
      activeDices: 3,
      maxRolls: 4,
    };
  } else if (lvl >= 10) {
    return {
      activeDices: 3,
      maxRolls: 3,
    };
  } else if (lvl >= 3) {
    return {
      activeDices: 2,
      maxRolls: 3,
    };
  } else {
    return {
      activeDices: 2,
      maxRolls: 2,
    };
  }
}

function DiceGame({
  user,
  app,
  setUser,
  isModalOpen,
  setIsModalOpen,
  setIsFetching,
  setActiveGame,
  isFetching,
  setModalError,
  serverTime,
  warOrdenTask,
  betOpen,
  setBetOpen,
}) {
  const [rolling, setRolling] = useState(false);
  const [isFirstRollGame, setIsFirstRollGame] = useState(
    user.diceGame.firstRoll,
  );
  const [gameX, setGamesX] = useState(user.diceGame?.gameX || 1);
  const [allPrizes, setAllPrizes] = useState([]);
  const [prisesComb, setPrisesComb] = useState([]);
  const [isFirstRoll, setIsFirstRoll] = useState(true);
  const [countRoll, setCountRoll] = useState(user.diceGame.countRolls);
  const [gameStarted, setGameStarted] = useState(user.diceGame.isGame);
  const [dices, setDices] = useState(user.diceGame.dices);
  const countRollRef = useRef(user.diceGame.countRolls);
  const [dicesClass, setDicesClass] = useState([0, 0, 0, 0]);

  useEffect(() => {
    return () => {
      setActiveGame("");
    };
  }, []);

  // useEffect(() => {
  //   if (
  //     user.diceGame.isFree > 0 &&
  //     user.diceGame.isFree <= 10 &&
  //     gameX > user.diceGame.isFree
  //   ) {
  //     setGamesX(user.diceGame.isFree);
  //   }
  // }, [user]);

  useEffect(() => {
    if (isFirstRoll && gameStarted) {
      firstRollDice()
        .then((array) => {
          setTimeout(() => {
            setRolling(false);
          }, 800);
          setIsFirstRoll(false);
          if (array.length > 0) {
            app
              .service("users")
              .patch(
                user._id,
                {
                  "diceGame.dices": array,
                  "diceGame.isGame": true,
                  "diceGame.firstRoll": false,
                  field: serverTime,
                },
                {
                  query: {
                    $select: ["_id", "email", "diceGame"],
                  },
                },
              )
              .then((data) => {
                setDices(array);
                setUser((prev) => ({ ...prev, ...data }));
              })
              .catch((e) => {
                setModalError(e);
                console.log(e);
              });
          }
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    }
  }, [isFirstRoll, gameStarted]);

  useEffect(() => {
    countRollRef.current = countRoll;
    if (countRoll === user.diceGame.maxRolls && !rolling) {
      setIsModalOpen(true);
    }
  }, [countRoll, rolling]);

  useEffect(() => {
    setAllPrizes(checkPrizes(dices));
    setPrisesComb(checkPrizes(dices, true));
    setTimeout(() => {
      setDicesClass(dices);
    }, 100);
    if (countRollRef.current > 0) {
      app
        .service("users")
        .patch(
          user._id,
          {
            "diceGame.dices": dices,
            "diceGame.countRolls": countRollRef.current,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "diceGame"],
            },
          },
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    }
  }, [dices]);

  function changeCount(value) {
    if (value > 100) {
      if (value >= 1) {
        value = 100;
      } else {
        value = 1;
      }
    }
    setGamesX(value ? Math.abs(+Math.floor(value)) : "");
  }

  function changeCountInput(e) {
    let value = e.target.value;

    if (value > 100) {
      if (value >= 1) {
        value = 100;
      } else {
        value = 1;
      }
    }
    setGamesX(value ? Math.abs(+Math.floor(value)) : "");
  }

  const rollDice = useCallback(
    ({ diceIndex, diceNumber }) => {
      if (!diceNumber) {
        setRolling(true);
      }
      const rollNumber = diceNumber ? diceNumber : randomNumber();
      if (!isFirstRoll) {
        setCountRoll((prev) => prev + 1);
      }
      if (!diceNumber && !isFirstRoll) {
        setDices((prev) => {
          const array = Array.from(prev);
          array[diceIndex] = rollNumber;
          return array;
        });
      }

      setDicesClass((prev) => {
        const array = Array.from(prev);
        array[diceIndex] = 0;
        return array;
      });

      if (!diceNumber) {
        setTimeout(() => {
          setRolling(false);
        }, 800);
      }
      return rollNumber;
    },
    [isFirstRoll],
  );

  async function firstRollDice() {
    setRolling(true);
    setIsFirstRollGame(true);
    if (countRoll > 0 || !isFirstRollGame) {
      rollDice({
        diceIndex: 0,
        diceNumber: dices[0],
      });
      rollDice({
        diceIndex: 1,
        diceNumber: dices[1],
      });
      rollDice({
        diceIndex: 2,
        diceNumber: dices[2],
      });
      rollDice({
        diceIndex: 3,
        diceNumber: dices[3],
      });
      return [];
    } else {
      return [
        rollDice({ diceIndex: 0 }),
        rollDice({ diceIndex: 1 }),
        rollDice({ diceIndex: 2 }),
        rollDice({ diceIndex: 3 }),
      ];
    }
  }

  function startGame() {
    setIsFetching(true);
    const price = (gameX || 1) - user.diceGame.isFree;
    let free = 0;

    if (user.diceGame.isFree > 0) {
      if (user.diceGame.isFree >= (gameX || 1)) {
        free = gameX || 1;
      } else {
        free = user.diceGame.isFree;
      }
    }

    if (price > 0 || user.gold >= price) {
      app
        .service("users")
        .patch(
          user._id,
          {
            "diceGame.gameX": gameX || 1,
            $inc: {
              gold: price > 0 ? -price : 0,
              "diceGame.isFree": -free,
              "dayQuest.diceGameGames": 1 * (gameX || 1),
              "summerSave.results.diceGameGames": 1 * (gameX || 1),
            },
            field: serverTime,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "diceGame",
                "gold",
                "dayQuest",
                "summerSave",
              ],
            },
          },
        )
        .then((data) => {
          setBetOpen(false);
          setGameStarted(true);
          setIsFirstRoll(true);
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    } else {
      setIsFetching(false);
      setModalError("Недостаточно золота");
      console.log("Недостаточно золота");
    }
  }

  function endGame() {
    setIsFetching(true);
    const isLvlUp = user.diceGame.gameOnLvl + 1 >= user.diceGame.gameOnLvlNeed;

    const gameOnLvlDone =
      user.diceGame.gameOnLvl + 1 >= user.diceGame.gameOnLvlNeed
        ? user.diceGame.gameOnLvl + 1 - user.diceGame.gameOnLvlNeed
        : user.diceGame.gameOnLvl + 1;

    const info = upLvlCheck(
      isLvlUp ? user.diceGame.lvl + 1 : user.diceGame.lvl,
    );

    const prizes = allPrizes.reduce((acc, cur) => {
      acc[cur.property] = {
        count: acc[cur.property]?.count
          ? acc[cur.property]?.count + Number(cur.count)
          : Number(cur.count),
        number: cur.number,
      };

      return acc;
    }, {});

    const points = Object.entries(prisesComb).reduce((acc, cur) => {
      if (cur[1] >= 2) {
        return acc + cur[1] * cur[0];
      }
      return acc;
    }, 0);

    app
      .service("users")
      .patch(
        user._id,
        {
          "diceGame.gameX": 1,
          "diceGame.isGame": false,
          "diceGame.firstRoll": true,
          "diceGame.countRolls": 0,
          "diceGame.gameOnLvl": gameOnLvlDone,
          "diceGame.activeDices": info.activeDices,
          "diceGame.maxRolls": info.maxRolls,
          "diceGame.dices": [],
          field: serverTime,
          $inc: {
            gold: prizes?.gold?.count
              ? prizes.gold.count * (user.diceGame?.gameX || 1)
              : 0,
            silver: prizes?.silver?.count
              ? prizes.silver.count * (user.diceGame?.gameX || 1)
              : 0,
            essence: prizes?.essence?.count
              ? prizes.essence.count * (user.diceGame?.gameX || 1)
              : 0,
            mana: prizes?.mana?.count
              ? prizes.mana?.count * (user.diceGame?.gameX || 1)
              : 0,
            "diceGame.lvl": isLvlUp ? 1 : 0,
            [`bosses.${prizes?.soul?.number ? prizes?.soul?.number : 0}.souls`]:
              prizes?.soul?.count
                ? prizes?.soul?.count * (user.diceGame?.gameX || 1)
                : 0,
            "dayQuest.diceGameGold": prizes?.gold?.count
              ? prizes.gold?.count * (user.diceGame?.gameX || 1)
              : 0,
            weakGamesPoints: points,
            "newQuest.results.diceGame": 1,
            "summerSave.results.diceGame5555":
              dices.filter((item) => item === 5).length === 4 ? 1 : 0,

            "achievements.diceGame.1111":
              dices.filter((item) => item === 1).length === 4 ? 1 : 0,
            "achievements.diceGame.2222":
              dices.filter((item) => item === 2).length === 4 ? 1 : 0,
            "achievements.diceGame.3333":
              dices.filter((item) => item === 3).length === 4 ? 1 : 0,
            "achievements.diceGame.4444":
              dices.filter((item) => item === 4).length === 4 ? 1 : 0,
            "achievements.diceGame.5555":
              dices.filter((item) => item === 5).length === 4 ? 1 : 0,
            "achievements.diceGame.6666":
              dices.filter((item) => item === 6).length === 4 ? 1 : 0,

            [`raidActiveTask.tasks.diceGameComb4444`]:
              dices.filter((item) => item === 4).length === 4 ? 1 : 0,
            [`raidActiveTask.tasks.diceGameComb5555`]:
              dices.filter((item) => item === 5).length === 4 ? 1 : 0,
          },
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "diceGame",
              "gold",
              "silver",
              "essence",
              "mana",
              "bosses",
              "dayQuest",
              "weakGamesPoints",
              "newQuest",
              "summerSave",
              "achievements",
              "raidActiveTask",
            ],
          },
        },
      )
      .then((data) => {
        setIsModalOpen(false);
        setIsFirstRollGame(true);
        setCountRoll(0);
        setGameStarted(false);
        setAllPrizes([]);
        setPrisesComb([]);
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);

        const tasks = [
          {
            property: `diceGameGames`,
            count: 1,
          },
        ];

        if (prizes?.gold?.count) {
          tasks.push({
            property: `diceGameGold`,
            count: prizes.gold.count * (user.diceGame?.gameX || 1),
          });
        }

        warOrdenTask(tasks);
      })
      .catch((e) => {
        console.log(e);
        setIsFetching(false);
        setIsModalOpen(false);
        setIsFirstRollGame(true);
        setCountRoll(0);
        setGameStarted(false);
      });
  }

  return (
    <S.DiceGameWrapper>
      <S.PrizesWrapper>
        <S.TopInfo>
          <span>Уровень: {user.diceGame.lvl}</span>
          <span>
            Игр на уровень:{" "}
            {user.diceGame.gameOnLvlNeed - user.diceGame.gameOnLvl}
          </span>
          <span>Комбинации</span>
        </S.TopInfo>
        <S.Prizes>
          <div>
            <span>x2</span>
            {prizes.map((elem, i) => {
              return (
                <S.Prize key={i} isActive={prisesComb[i + 1] === 2}>
                  <img
                    width={24}
                    src={require(`../../img/DicePen${i + 1}.png`)}
                    alt="кубик"
                  />
                  <Reward
                    name={elem.counts[2].property}
                    soul={elem.counts[2].number}
                    count={elem.counts[2].count}
                    w={16}
                  />
                </S.Prize>
              );
            })}
          </div>
          <div>
            <span>x3</span>
            {prizes.map((elem, i) => {
              return (
                <S.Prize key={i} isActive={prisesComb[i + 1] === 3}>
                  <img
                    width={24}
                    src={require(`../../img/DicePen${i + 1}.png`)}
                    alt="кубик"
                  />
                  <Reward
                    name={elem.counts[3].property}
                    soul={elem.counts[3].number}
                    count={elem.counts[3].count}
                    w={16}
                  />
                </S.Prize>
              );
            })}
          </div>
          <div>
            <span>x4</span>
            {prizes.map((elem, i) => {
              return (
                <S.Prize key={i} isActive={prisesComb[i + 1] === 4}>
                  <img
                    width={24}
                    src={require(`../../img/DicePen${i + 1}.png`)}
                    alt="кубик"
                  />
                  <Reward
                    name={elem.counts[4].property}
                    soul={elem.counts[4].number}
                    count={elem.counts[4].count}
                    w={16}
                  />
                </S.Prize>
              );
            })}
          </div>
        </S.Prizes>
        <S.BottomWrapper>
          {!gameStarted ? (
            <>
              <S.BetString>
                Ставка:{" "}
                {user.diceGame.isFree > 0 ? (
                  user.diceGame.isFree >= (gameX || 1) ? (
                    <Reward
                      name={"diceGame"}
                      count={gameX || 1}
                      w={16}
                      button={true}
                    />
                  ) : (
                    <>
                      <Reward
                        name={"gold"}
                        count={(gameX || 1) - user.diceGame.isFree}
                        w={16}
                        button={true}
                      />

                      <Reward
                        name={"diceGame"}
                        count={user.diceGame.isFree}
                        w={16}
                        button={true}
                      />
                    </>
                  )
                ) : (
                  <Reward
                    name={"gold"}
                    count={gameX || 1}
                    w={16}
                    button={true}
                  />
                )}
              </S.BetString>
              <S.MarkerButtonStyled
                minW={100}
                onClick={!isFetching ? startGame : null}
              >
                <div>Начать</div>
              </S.MarkerButtonStyled>
            </>
          ) : (
            <>
              <span>
                Доступно перебросов: {user.diceGame.maxRolls - countRoll}
              </span>
              <S.MarkerButtonStyled
                minW={100}
                onClick={
                  !rolling && !isModalOpen && !isFetching
                    ? () => setIsModalOpen(true)
                    : null
                }
              >
                <div>Закончить</div>
              </S.MarkerButtonStyled>
            </>
          )}
        </S.BottomWrapper>
      </S.PrizesWrapper>

      <S.DicesArea>
        {coordsDices.map((info, i) => (
          <Dice
            key={i}
            diceIndex={i}
            number={dicesClass[i]}
            x={info.x}
            y={info.y}
            rollDice={rollDice}
            rolling={rolling}
            disabled={
              rolling ||
              isModalOpen ||
              !gameStarted ||
              countRoll >= user.diceGame.maxRolls
            }
            gameStarted={gameStarted}
            active={user.diceGame.activeDices > i}
          />
        ))}
      </S.DicesArea>

      <Popup
        isOpen={betOpen}
        setIsOpen={setBetOpen}
        w={317}
        h={217}
        back={betFrame}
        justify={"start"}
      >
        <Close
          onClick={() => {
            setBetOpen(false);
          }}
        />

        <Header>Выбор ставки</Header>

        <ButtonsXWrapper>
          <ButtonXChange
            disabled={gameX === 100}
            onClick={changeCount.bind(null, 100)}
          >
            <div>100</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 50}
            onClick={changeCount.bind(null, 50)}
          >
            <div>50</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 10}
            onClick={changeCount.bind(null, 10)}
          >
            <div>10</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 5}
            onClick={changeCount.bind(null, 5)}
          >
            <div>5</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={gameX === 1}
            onClick={changeCount.bind(null, 1)}
          >
            <div>1</div>
          </ButtonXChange>
        </ButtonsXWrapper>

        <S.ButtonValueInput>
          <div>
            <input
              value={gameX}
              onChange={changeCountInput}
              type="number"
              max={100}
              min={1}
              placeholder={""}
              step={1}
            />
          </div>
        </S.ButtonValueInput>

        <S.BottomInfo>
          <Reward
            name={"diceGame"}
            count={`Бесплатных игр: ${user.diceGame.isFree}`}
            w={16}
            button={true}
          />
        </S.BottomInfo>
      </Popup>

      <Popup isOpen={isModalOpen} w={317} h={217} back={frame3}>
        {allPrizes.length <= 0 && (
          <Close onClick={isModalOpen && !isFetching ? endGame : null} />
        )}

        {allPrizes.length > 0 ? (
          <>
            <b>Награда за комбинации</b>
            <S.Rewards>
              {allPrizes.map((el, i) => {
                return (
                  <RewardWrapper key={i} light={false} w={65}>
                    <div>
                      <Reward
                        name={el.property}
                        count={el.count * (user.diceGame?.gameX || 1)}
                        soul={el.number}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                );
              })}
            </S.Rewards>
            <Button
              width={100}
              onClick={isModalOpen && !isFetching ? endGame : null}
            >
              <div>Забрать</div>
            </Button>
          </>
        ) : (
          <>
            <b>Не удалось собрать комбинацию</b>
            <Button
              width={100}
              onClick={isModalOpen && !isFetching ? endGame : null}
            >
              <div>Закрыть</div>
            </Button>
          </>
        )}
      </Popup>
    </S.DiceGameWrapper>
  );
}

export default React.memo(DiceGame);
