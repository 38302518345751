import * as S from "./UserRoom.styles";
import React, { useEffect, useState } from "react";

import { CentredWrapper, Close } from "../../App.styles";

import vkImg from "../../img/menuIcons/Vk.png";
import candyImg from "../../img/menuIcons/Candy.png";
import collectionsImg from "../../img/menuIcons/Collections.png";
import { candies, collectionsArray } from "../../info/data";
import Popup from "../../components/Popup/Popup";
import frame5 from "../../img/Frame5.png";
import frame1 from "../../img/Frame1.png";
import Reward from "../../components/Reward/Reward";
import { MenuRight, MenuElemRight } from "../Home/Home.styles";
import bossesImg from "../../img/tops/lounge/BossesHover.png";
import classroomsImg from "../../img/tops/lounge/ClassroomsHover.png";
import arenaImg from "../../img/tops/lounge/ArenaHover.png";
import gamesImg from "../../img/tops/lounge/GamesHover.png";
import place1 from "../../img/Place1.png";
import place2 from "../../img/Place2.png";
import place3 from "../../img/Place3.png";
import {
  AddButton,
  CollectionElem,
  Element,
} from "../../components/Collection/Collection.styles";
import { RewardWrapper } from "../../components/DayReward/DayReward.styles";
import { Button } from "../Arena/Arena.styles";
import plusImg from "../../img/collection/Send.png";
import { TopPart } from "../../components/BossInterface/BossInterface.styles";
import imgTop from "../../img/boss/TopPart.png";
import { Back } from "../../components/Garderob/Garderob.styles";
import { createPortal } from "react-dom";
import frame3 from "../../img/Frame3.png";

const needArray = [1, 2, 3, 4, 5];

const UserRoom = ({
  app,
  me,
  ownerRoom,
  setOwnerRoom,
  moveLocation,
  isModalOpen,
  isFetching,
  setIsFetching,
  setModalError,
  lastPlace,
  candyNameSent,
  setCandyNameSent,
  warOrdenTask,
}) => {
  const [collectionsIsOpen, setCollectionsIsOpen] = useState(false);
  const [candyIsOpen, setCandyIsOpen] = useState(false);
  const [haveCollections, setHaveCollections] = useState([]);
  const [tops, setTops] = useState({
    arena: [0, 0, 0],
    classrooms: [0, 0, 0],
    bosses: [0, 0, 0],
    games: [0, 0, 0],
  });
  const [topsSum, setTopsSum] = useState({
    arena: 0,
    classrooms: 0,
    bosses: 0,
    games: 0,
  });
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
    return () => {
      setOwnerRoom(null);
    };
  }, []);

  useEffect(() => {
    if (ownerRoom.collectionsNeed.length > 0) {
      setHaveCollections(
        ownerRoom.collectionsNeed.map((elem) => findCollection(elem)),
      );
    }
  }, [me, ownerRoom]);

  useEffect(() => {
    setTops({
      arena: ownerRoom.weakTopWins.arena.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0),
      ),
      classrooms: ownerRoom.weakTopWins.classrooms.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0),
      ),
      bosses: ownerRoom.weakTopWins.bosses.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0),
      ),
      games: ownerRoom.weakTopWins.games.map((elem) =>
        Object.values(elem).reduce((acc, cur) => acc + cur, 0),
      ),
    });
  }, [ownerRoom]);

  useEffect(() => {
    setTopsSum({
      arena: tops.arena.reduce((acc, cur) => acc + cur, 0),
      classrooms: tops.classrooms.reduce((acc, cur) => acc + cur, 0),
      bosses: tops.bosses.reduce((acc, cur) => acc + cur, 0),
      games: tops.games.reduce((acc, cur) => acc + cur, 0),
    });
  }, [tops]);

  function exitRoom() {
    setOwnerRoom(null);
    moveLocation(lastPlace);
  }

  function openCollections() {
    setCollectionsIsOpen((prev) => !prev);
    setCandyIsOpen(false);
  }

  function openCandy() {
    setCandyIsOpen((prev) => !prev);
    setCollectionsIsOpen(false);
  }

  function findCollection({ elemIndex, collectionIndex }) {
    return me.collections[collectionIndex]?.counts[elemIndex];
  }

  function sendCandy() {
    if (me.gold >= 5) {
      if (!me.candyMove?.[ownerRoom._id] || me.candyMove[ownerRoom._id] < 5) {
        setIsFetching(true);
        const randomIndex = Math.floor(Math.random() * candies.length);
        app
          .service("users")
          .sendCandy({
            userId: ownerRoom._id,
            userEmail: ownerRoom.email,
            meId: me._id,
            meEmail: me.email,
            name: candies[randomIndex],
          })
          .then(() => {
            const tasks = [{ property: "candyMove", count: 1 }];

            warOrdenTask(tasks);
          })
          .catch((e) => {
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      } else {
        setModalError("Вы уже отправили 5 драже за сегодня");
        console.log("Вы уже отправили 5 драже за сегодня");
      }
    } else {
      console.log("Недостаточно золота");
      setModalError("Недостаточно золота");
    }
  }

  function sendCollection({ elemIndex, collectionIndex, number }) {
    if (haveCollections[number] > 0) {
      if (
        !me.collectionsMove?.[ownerRoom._id] ||
        me.collectionsMove[ownerRoom._id] < 5
      ) {
        setIsFetching(true);
        app
          .service("users")
          .sendCollection({
            userId: ownerRoom._id,
            userEmail: ownerRoom.email,
            meId: me._id,
            meEmail: me.email,
            collectionIndex: collectionIndex,
            elemIndex: elemIndex,
          })
          .then(() => {
            const tasks = [{ property: "collectionsMove", count: 1 }];
            warOrdenTask(tasks);
          })
          .catch((e) => {
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      } else {
        setModalError("Вы уже отправили 5 элементов коллекции за сегодня");
        console.log("Вы уже отправили 5 элементов коллекции за сегодня");
      }
    } else {
      setModalError("Недостаточно элементов для отправки");
      console.log("Недостаточно элементов для отправки");
    }
  }

  return (
    <>
      <TopPart>
        <img src={imgTop} alt="интерфейс" />
      </TopPart>

      <CentredWrapper>
        <S.BossesCup>
          {topsSum.bosses > 0 && (
            <>
              <img width={179} height={60} src={bossesImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.bosses[0]}</span>
                  <img src={place1} width={28} height={28} alt="медаль" />
                </div>
                <div>
                  <span>{tops.bosses[1]}</span>
                  <img src={place2} width={28} height={28} alt="медаль" />
                </div>
                <div>
                  <span>{tops.bosses[2]}</span>
                  <img src={place3} width={28} height={28} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.BossesCup>

        <S.ClassroomsCup>
          {topsSum.classrooms > 0 && (
            <>
              <img width={58} height={50} src={classroomsImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.classrooms[0]}</span>
                  <img src={place1} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.classrooms[1]}</span>
                  <img src={place2} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.classrooms[2]}</span>
                  <img src={place3} width={28} height={30} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.ClassroomsCup>

        <S.ArenaCup>
          {topsSum.arena > 0 && (
            <>
              <img width={58} height={60} src={arenaImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.arena[0]}</span>
                  <img src={place1} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.arena[1]}</span>
                  <img src={place2} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.arena[2]}</span>
                  <img src={place3} width={28} height={30} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.ArenaCup>

        <S.GamesCup>
          {topsSum.games > 0 && (
            <>
              <img width={42} height={45} src={gamesImg} alt={"кубок"} />
              <div>
                <div>
                  <span>{tops.games[0]}</span>
                  <img src={place1} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.games[1]}</span>
                  <img src={place2} width={28} height={30} alt="медаль" />
                </div>
                <div>
                  <span>{tops.games[2]}</span>
                  <img src={place3} width={28} height={30} alt="медаль" />
                </div>
              </div>
            </>
          )}
        </S.GamesCup>
      </CentredWrapper>

      {domReady
        ? createPortal(
            <MenuRight>
              <MenuElemRight name={"Страница"}>
                <div
                  onClick={() => {
                    window.open(
                      `https://vk.com/id${ownerRoom.email}`,
                      "mywindow",
                    );
                  }}
                />
                <img width={80} height={80} src={vkImg} alt={"вк"} />
              </MenuElemRight>

              <MenuElemRight name={"Коллекции"}>
                <div onClick={openCollections} />
                <img
                  width={80}
                  height={80}
                  src={collectionsImg}
                  alt={"коллекции"}
                />
              </MenuElemRight>

              <MenuElemRight name={"Драже"}>
                <div onClick={openCandy} />
                <img width={80} height={80} src={candyImg} alt={"драже"} />
              </MenuElemRight>
            </MenuRight>,
            document.querySelector("#menuRight"),
          )
        : null}

      <Back>
        <Button width={130} onClick={exitRoom}>
          <div>Покинуть комнату</div>
        </Button>
      </Back>

      <Popup
        w={606}
        h={182}
        back={frame5}
        isOpen={collectionsIsOpen}
        setIsOpen={setCollectionsIsOpen}
      >
        <Close
          onClick={() => {
            setCollectionsIsOpen(false);
          }}
        />
        <b>Поделиться элементами коллекций</b>
        <S.NeedCollections>
          {needArray.map((_, i) => {
            const collectionElem =
              collectionsArray[ownerRoom.collectionsNeed[i]?.collectionIndex]
                ?.counts[ownerRoom.collectionsNeed[i]?.elemIndex];
            return (
              <Element key={i}>
                <RewardWrapper key={i} light={false} w={65} bottom={15}>
                  {collectionElem && (
                    <CollectionElem>
                      <img
                        src={require(
                          `../../img/collection/${
                            ownerRoom.collectionsNeed[i]?.collectionIndex
                          }/${ownerRoom.collectionsNeed[i]?.elemIndex + 1}.png`,
                        )}
                        width={28}
                        height={28}
                        alt="коллекция"
                      />
                      <span>{haveCollections[i]}</span>
                    </CollectionElem>
                  )}
                </RewardWrapper>
                <AddButton>
                  <Button
                    disabled={!collectionElem || haveCollections[i] <= 0}
                    width={65}
                    onClick={
                      !isFetching &&
                      !isModalOpen &&
                      collectionElem &&
                      haveCollections[i] > 0
                        ? sendCollection.bind(null, {
                            elemIndex: ownerRoom.collectionsNeed[i].elemIndex,
                            collectionIndex:
                              ownerRoom.collectionsNeed[i].collectionIndex,
                            number: i,
                          })
                        : null
                    }
                  >
                    <div>
                      <img src={plusImg} width={12} alt="отправить" />
                    </div>
                  </Button>
                </AddButton>
              </Element>
            );
          })}
        </S.NeedCollections>
      </Popup>

      <Popup
        isOpen={candyIsOpen}
        setIsOpen={setCandyIsOpen}
        w={317}
        h={217}
        back={frame3}
      >
        <Close onClick={openCandy} />
        <b>Отправить Драже Сюрприз со случайным вкусом</b>
        <Button onClick={!isFetching ? sendCandy : null}>
          <Reward name={"gold"} count={`Отправить 5`} w={15} button={true} />
        </Button>
      </Popup>

      <Popup
        isOpen={candyNameSent}
        setIsOpen={setCandyNameSent}
        w={281}
        h={152}
        back={frame1}
      >
        <Close
          onClick={() => {
            setCandyNameSent("");
          }}
        />
        <b>Вы отправили Драже Сюрприз со вкусом "{candyNameSent}"</b>
      </Popup>
    </>
  );
};

export default React.memo(UserRoom);
