import Emitter from "component-emitter";
import Parser from "socket.io-msgpack-parser";
import pako from "pako";

class Encoder {
  /**
   * Encode a packet into a list of strings/buffers
   */
  encode(packet) {
    const enc = new Parser.Encoder();

    const buffer = enc.encode(packet);

    return [buffer[0]];
  }
}

class Decoder extends Emitter {
  /**
   * Receive a chunk (string or buffer) and optionally emit a "decoded" event with the reconstructed packet
   */
  add(chunk) {
    // console.log(JSON.parse(chunk));
    // if (this.isPacketValid(JSON.parse(chunk))) {
    //   this.emit("decoded", JSON.parse(chunk));
    // } else {
    //   throw new Error("invalid format");
    // }

    // const dec = new Parser.Decoder();
    //
    // dec.on("decoded", function (obj) {
    //   if (this.isPacketValid(obj)) {
    //     this.emit("decoded", obj);
    //   } else {
    //     throw new Error("invalid format");
    //   }
    // });
    //
    // dec.add(chunk);

    var th = this;
    var dec = new Parser.Decoder();
    dec.on("decoded", function (obj) {
      if (th.isPacketValid(obj)) {
        th.emit("decoded", obj);
      } else {
        throw new Error("invalid format");
      }
    });

    dec.add(pako.inflate(chunk));
  }

  isPacketValid({ type, data, nsp, id }) {
    const isNamespaceValid = typeof nsp === "string";
    const isAckIdValid = id === undefined || Number.isInteger(id);
    if (!isNamespaceValid || !isAckIdValid) {
      return false;
    }
    switch (type) {
      case 0: // CONNECT
        return data === undefined || typeof data === "object";
      case 1: // DISCONNECT
        return data === undefined;
      case 2: // EVENT
        return Array.isArray(data) && data.length > 0;
      case 3: // ACK
        return Array.isArray(data);
      case 4: // CONNECT_ERROR
        return typeof data === "object";
      default:
        return false;
    }
  }
  /**
   * Clean up internal buffers
   */
  destroy() {}
}
export default { Encoder, Decoder };
