import React, { useState } from "react";

import * as S from "./Card.styles";

const Card = ({ w, h, x, y, src, bottom, right, z = 1 }) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <S.Card
      animate={{ y: 50 }}
      transition={{ type: "spring" }}
      loaded={loaded}
      isBottom={bottom}
      isRight={right}
      width={w}
      height={h}
      src={require(`../../img/cards/${src}`)}
      x={x}
      y={y}
      z={z}
      onLoad={handleLoad}
      alt={"card"}
    />
  );
};

export default React.memo(Card);
