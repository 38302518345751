import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  left: 465px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FriendsBlock = styled.div`
  display: flex;
  gap: 1px;
`;

export const Friend = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Property = styled.span`
  cursor: default;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.25;
  position: absolute;
  color: rgb(247, 180, 113);
  font-size: 10px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  max-width: 55px;
  min-width: 55px;
  background-color: rgba(0, 0, 0, 0.5);
`;
