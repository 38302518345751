import React from "react";

import * as S from "./FriendsPhoto.styles";
import { roundProperty } from "../../helpers";

const FriendsPhoto = ({ friendsArray }) => {
  return (
    <S.Wrapper>
      <S.FriendsBlock>
        {friendsArray &&
          friendsArray.length > 0 &&
          friendsArray.map((user, i) => {
            return (
              <S.Friend key={user._id}>
                <img src={user.photo} alt={"фото"} width={54} height={54} />
                <S.Property>
                  {roundProperty(user.lvl).toLocaleString("ru")}
                </S.Property>
              </S.Friend>
            );
          })}
      </S.FriendsBlock>
    </S.Wrapper>
  );
};

export default React.memo(FriendsPhoto);
