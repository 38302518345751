import styled from "styled-components";

export const Wrapper = styled.div``;

export const Lvl = styled.div`
  font-family: "Bellota-Regular";
  color: #f7b471;
  font-size: 11px;
  font-weight: 600;
  left: 50%;
  top: 17px;
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0;
  & > span {
    text-shadow: 1px 1px #210326;
  }

  & > span:nth-child(1) {
    font-size: 20px;
  }
`;

export const LvlProgress = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  top: 7px;
  left: 2px;
  cursor: help;
  font-family: "Bellota-Regular";
  &:after {
    width: max-content;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    content: "Опыт: ${(props) => props.exp} (осталось)";
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }
  & img {
    clip-path: polygon(
      0% 0%,
      ${(props) => props.value}%0%,
      ${(props) => props.value}% 50%,
      ${(props) => props.value}% 100%,
      0% 100%
    );
    transition: all 0.5s ease-in-out;
  }
  & span {
    font-weight: 600;
    color: #e3cd32;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    font-size: 12px;
    line-height: 13px;
  }
`;

export const HpProgress = styled(LvlProgress)`
  position: absolute;
  display: flex;
  left: 90px;
  top: 42px;
  &:after {
    width: max-content;
    top: 0;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    content: "Здоровье: ${(props) => props.hp}";
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }
  & span {
    color: #e3cd32;
    line-height: 21px;

    text-shadow: 1px 1px #210326;
  }
`;

export const Circle = styled.div`
  cursor: help;
  top: 6px;
  left: 6px;
  width: 72px;
  height: 72px;
  position: absolute;

  &:after {
    width: max-content;
    bottom: 0;
    left: 0;
    transform: translate(0%, 80%) scale(0.85);
    position: absolute;
    content: "Осталось: ${(props) => props.exp} опыта";
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(0%, 100%) scale(1);
  }

  svg {
    position: absolute;
    transform: rotate(-90deg);
    circle {
      transition: 0.1s linear;
    }
  }
  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const Icon = styled.img`
  position: absolute;
  left: 78px;
  top: 34px;
`;
