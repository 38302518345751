import styled, { css } from "styled-components";
import imgMarker2 from "../../img/Marker2Dark.png";
import imgMarker5 from "../../img/Marker5Dark.png";
import buttonConnect from "../../img/ButtonConnect.png";
import imgAvatar from "../../img/FriendsFrame.png";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
  color: #e1b680;
  width: 100%;
  flex-direction: column;
  text-align: center;
`;

export const InfoTop = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  text-shadow: 0 0 #281707;
  font-weight: 600;

  & > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

export const TopsContent = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  border-radius: 3px;
`;

export const Tops = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  gap: 5px;
  padding: 0 5px;
  margin: 5px 0 5px 0;
`;

export const Names = styled.div`
  width: 100%;
  gap: 5px;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 2fr 0.5fr 0.8fr 1fr;
  padding: 10px 15px 10px 10px;
  border-bottom: 2px solid #fc9800;
`;

export const Top = styled.div`
  width: 100%;
  gap: 5px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  line-height: 20px;
  grid-template-columns: 0.5fr 0.5fr 2fr 0.5fr 0.8fr 1fr;
  min-height: 50px;
  max-height: 50px;
  background-image: url("${(props) =>
    props.meTop ? "" : props.isWin ? imgMarker2 : imgMarker5}");
  background-size: contain;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const MeTop = styled.div`
  width: 100%;
  display: flex;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  border-radius: 3px;
  padding: 0 5px;
`;

export const UserName = styled.div`
  display: flex;
  row-gap: 0;
  column-gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > span {
    overflow: hidden;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Avatar = styled.div`
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};
  display: flex;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
`;

export const AvatarTop = styled.span`
  top: ${(props) => props.top && `${props.top}px`};
  left: ${(props) => props.left && `${props.left}px`};
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 3px;
  margin-bottom: 5px;
  width: 76px;
  height: 76px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  & > img {
    border-radius: 10px;
  }

  & > div {
    display: flex;
    position: absolute;
    bottom: 0;
    transform: translateY(40%);
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("${imgAvatar}");
    background-size: contain;
  }
`;

export const Place = styled.div`
  font-size: 27px;
  font-weight: 600;
`;

export const Cup = styled.div`
  position: absolute;
  top: ${(props) => props.top && `${props.top}px`};
  left: ${(props) => props.left && `${props.left}px`};
  width: ${(props) => props.width && `${props.width}px`};
  height: ${(props) => props.height && `${props.height}px`};
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const CupHover = styled.div`
  position: absolute;
  top: ${(props) => props.topHover && `${props.topHover}px`};
  left: ${(props) => props.leftHover && `${props.leftHover}px`};
  width: ${(props) => props.widthHover && `${props.widthHover}px`};
  height: ${(props) => props.heightHover && `${props.heightHover}px`};
  background-image: url("${(props) => props.back}");
  background-size: cover;
  opacity: 0;
  transition: 0.3s linear;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: center;
  text-align: start;
`;

export const TopButton = styled.div`
  position: absolute;
  left: 50%;
  top: 32px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`;

export const Stage = styled.div`
  position: absolute;
  left: 50%;
  bottom: 32px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
`;

export const Update = styled.div`
  position: absolute;
  left: 500px;
  bottom: 72px;
  transform: translateX(-50%);
`;

export const TopRewardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  & > div {
    display: flex;
    gap: 4px;
  }
`;

export const TopRewardWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 15px;
  color: #fcc382;
  margin-bottom: 10px;
`;

export const InfoName = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #fcc382;
`;

export const ButtonsWrapperInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  & > div {
    min-width: 120px;
  }
`;

export const Line = styled.div`
  width: 95%;
  height: 2px;
  background-color: #6c4212;
  margin: 5px 0;
`;

export const InfoText = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
`;

export const ButtonValue = styled.div`
  min-width: 100px;
  width: ${(props) => props.width && `${props.width}px`};
  position: relative;
  transition: filter 0.15s linear;
  border-radius: 36px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  background: linear-gradient(0deg, #3d2713, #3d2713),
    linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
    linear-gradient(
      180deg,
      rgba(255, 217, 159, 0.1) 0%,
      rgba(255, 223, 176, 0) 42.19%
    ),
    #6b3700;

  &::after {
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    z-index: 1;
    width: 12px;
    height: 8px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  & > div {
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 36px;
    padding: 2px 10px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const ButtonLeft = styled.div`
  z-index: 2;
  transform: rotateZ(-90deg);
  display: flex;
  cursor: pointer;
  transition: scale 0.6s linear;
  &:hover {
    transform: rotateZ(-90deg) scale(1.05);
  }
  &:active {
    transform: rotateZ(-90deg) scale(1);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover {
        transform: rotateZ(-90deg);
      }
    `};
`;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotateZ(90deg);
  &:hover {
    transform: scale(1.05) rotateZ(90deg);
  }
  &:active {
    transform: scale(1) rotateZ(90deg);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover {
        transform: rotateZ(90deg);
      }
    `};
`;
