import React, { useEffect, useRef, useState } from "react";
import * as S from "./DayDamagePanel.styles";
import Popup from "../Popup/Popup";
import frame4 from "../../img/Frame4.png";
import { roundProperty } from "../../helpers";
import { Button } from "../../panels/Arena/Arena.styles";
import { Close } from "../../App.styles";

const step = 20;

function DayDamagePanel({ allUsers, moveToUserRoom, me, reloadUsersInfo }) {
  const [open, setOpen] = useState(false);
  const [damage, setDamage] = useState(0);
  const [topUsers, setTopUsers] = useState([]);
  const [stage, setStage] = useState(1);
  const stages = useRef(null);
  const maxLvl = useRef(null);

  useEffect(() => {
    if (allUsers.length) {
      maxLvl.current = allUsers.sort((a, b) => b.lvl - a.lvl)[0].lvl;
      stages.current =
        Math.floor(maxLvl.current % step) === 0
          ? Math.floor(maxLvl.current / step)
          : Math.floor(maxLvl.current / step) + 1;

      setTopUsers(
        allUsers
          .filter(
            (user) =>
              user.lvl < step * stage &&
              user.lvl >= step * (stage - 1) &&
              user.dayDamage > 0,
          )
          .sort((a, b) => b.dayDamage - a.dayDamage)
          .slice(0, 100),
      );
    }
  }, [allUsers, stage]);

  useEffect(() => {
    if (topUsers.length > 0) {
      setDamage(topUsers.reduce((sum, cur) => sum + cur.dayDamage, 0));
    }
  }, [topUsers]);

  function handleClickUser(user) {
    moveToUserRoom(user);
  }

  return (
    <S.Wrapper>
      <Button
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <div>Урон</div>
      </Button>

      <Popup isOpen={open} setIsOpen={setOpen} w={506} h={502} back={frame4}>
        <Close
          onClick={() => {
            setOpen(false);
          }}
        />
        <S.Stages>
          {stages.current &&
            Array(stages.current)
              .fill("")
              .map((_, i) => {
                return (
                  <button
                    key={i}
                    disabled={i + 1 === stage}
                    onClick={() => {
                      setStage(i + 1);
                    }}
                  >
                    {step * i} - {step * (i + 1) - 1} ур.
                  </button>
                );
              })}
          <button onClick={reloadUsersInfo}>Обновить</button>
        </S.Stages>
        <span>Всего: {roundProperty(damage).toLocaleString("ru")}</span>
        <S.Tops>
          {open &&
            topUsers.length > 0 &&
            topUsers.map((user, i) => {
              return (
                <div key={i}>
                  <S.User
                    isMe={user.email === me.email}
                    onClick={
                      user.email !== me.email
                        ? handleClickUser.bind(null, user)
                        : null
                    }
                  >
                    <span> {i + 1}. </span>
                    <span>
                      {user.orden?.tag ? `[${user.orden?.tag}] ` : ""}
                    </span>
                    <span>
                      {user.nickName
                        ? user.nickName
                        : user.name?.firstName + " " + user.name?.lastName}
                    </span>
                    <span>
                      {" "}
                      - {roundProperty(user.dayDamage).toLocaleString(
                        "ru",
                      )}{" "}
                    </span>
                    <span>
                      (
                      <S.UserRef
                        target="_blank"
                        href={`https://vk.com/id${user.email}`}
                      >
                        {user.name?.firstName + " " + user.name?.lastName}
                      </S.UserRef>
                      )
                    </span>
                  </S.User>
                </div>
              );
            })}
        </S.Tops>
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(DayDamagePanel);
