import React from "react";

import * as S from "./Cards.styles";
import Card from "../Card/Card";

const type = ".png";

const suits = {
  Чирва: "Chirva",
  Пики: "Piki",
  Бубны: "Bybni",
  Крести: "Kresti",
};
const values = {
  Туз: "Tyz",
  Король: "Korol",
  Дама: "Dama",
  Валет: "Valet",
  Десятка: "N10",
  Девятка: "N9",
  Восьмёрка: "N8",
  Семёрка: "N7",
  Шестёрка: "N6",
};

const Cards = ({ cards, bottom, right }) => {
  return (
    <S.Cards bottom={bottom} right={right}>
      {cards.length > 0 &&
        cards.map((card, i) => (
          <Card
            key={values[card?.value] + suits[card?.suit]}
            src={values[card?.value] + suits[card?.suit] + type}
            w={100}
            h={145}
            x={30 * i}
            y={0}
            z={i + 1}
            bottom={bottom}
            right={right}
          />
        ))}
    </S.Cards>
  );
};

export default React.memo(Cards);
