import styled, { css, keyframes } from "styled-components";

export const ButtonGet = styled.button`
  & > div {
    & > span {
      width: max-content;
    }
  }
`;

export const Place = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.x && props.x}px;
  top: ${(props) => props.y && props.y}px;

  opacity: 0;
  transition: 0.3s linear;
  cursor: ${(props) =>
    !props.isDone && props.isActive ? "pointer" : "default"};

  &:hover {
    opacity: ${(props) => (!props.isDone && props.isActive ? "1" : "0")};
  }
`;

export const InfoPlace = styled.div`
  position: absolute;
  left: ${(props) => props.x && props.x}px;
  top: ${(props) => props.y && props.y}px;
  width: 38px;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(
    calc(${(props) => props.width && props.width / 2}px - 50%),
    calc(${(props) => props.height && props.height}px - 20px)
  );
  border-radius: 50%;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 50%;
    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    color: #fcc382;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const WaterPlace = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  cursor: ${(props) => (props.isActive ? "pointer" : "default")};

  & > div {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s linear;
  }

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }

  & > img {
    position: absolute;
    transform: translate(-50%, -60%);
  }

  & > img:nth-child(2) {
    clip-path: polygon(
      0 ${(props) => props.percent}%,
      100% ${(props) => props.percent}%,
      100% 100%,
      0% 100%
    );
  }
`;

export const Plant = styled.img`
  position: absolute;
  & + div {
    top: 0;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s ease;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.85);
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 2px;
    z-index: 1;
  }
  &:hover + div {
    visibility: visible;
    opacity: 1;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  & > span {
    font-size: 16px;
    font-family: "Bellota-Regular";
  }
`;

export const PlantsGetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  & > div {
    position: relative;
    width: 150px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 14px;

    & > div:nth-child(1) {
      flex: 1;
      align-items: center;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;

  &:hover {
    opacity: 1;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const InfoPlant = styled.div`
  display: flex;
  align-self: center;
  & > div {
    display: flex;
    text-align: center;
    text-shadow:
      -1px 0 black,
      0 1px black,
      1px 0 black,
      0 -1px black;
    font-weight: 600;
    flex-direction: column;

    justify-content: center;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);

    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
    width: max-content;
    visibility: hidden;
  }
  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
`;
