import styled from "styled-components";
import { MarkerButton } from "../Quests/Quests.styles";
import { ButtonChange } from "../Spell/Spell.styles";
import { ButtonValue } from "../Healls/Healls.styles";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
  color: rgb(247, 180, 113);
`;

export const BetString = styled.div`
  line-height: 23px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  color: #413423;
  font-family: "Inkverse-BWDRx";
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  left: 274px;
  bottom: 75px;
  width: 200px;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  gap: 10px;
  & > button {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

export const DialerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 200px;
  width: 100%;
`;

export const PlayerWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 200px;
  width: 100%;
`;

export const GamesInfo = styled.div`
  font-family: "Inkverse-BWDRx";
  color: #f7b471;
  position: absolute;
  top: 25px;
  left: 252px;
  width: 194px;
  height: 84px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
`;

export const DialerInfo = styled.div`
  position: absolute;
  left: 3px;
  top: 18px;
  font-family: "Inkverse-BWDRx";
  transform: rotate(-17deg);
  color: #413423;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 200px;
  height: 100%;
`;

export const PlayerInfo = styled(DialerInfo)`
  transform: rotate(16deg);
  left: unset;
  right: -12px;
  top: 42px;
`;

export const Name = styled.span`
  font-size: 26px;
  line-height: 1.5;
`;

export const Score = styled.span`
  font-size: 22px;
`;

export const CardName = styled.span`
  font-size: 17px;
  line-height: 120%;
`;

export const GameMenu = styled.div`
  transform: rotate(16deg);
  width: 200px;
  position: absolute;
  right: -38px;
  top: 20px;
  padding: 0 7px;
  display: flex;
  justify-content: end;
  & > div {
    display: flex;
    & > div {
      & img {
        transition: 0.15s linear;
        cursor: pointer;
      }
      & img:hover {
        filter: brightness(1.2);
      }
      & img:active {
        filter: brightness(0.9);
      }
    }
  }
`;

export const PlayerCards = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const DialerCards = styled.div`
  height: 195px;
  width: 250px;
  position: absolute;
  right: -6px;
  top: 12px;
`;

export const MarkerButtonStyled = styled(MarkerButton)`
  font-size: 14px;
  line-height: 15px;
  color: #d0bd9f;
`;

export const ButtonsXWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  gap: 5px;
  align-items: center;
  margin-top: 25px;
`;

export const ButtonXChange = styled(ButtonChange)`
  border-radius: 9px;
  min-width: 40px;
  max-width: 40px;
  &::after {
    display: none;
  }
  & > div {
    border-radius: 9px;
    padding: 4px;
    font-size: 13px;
    line-height: 1.25;
  }
`;

export const ButtonXValue = styled(ButtonValue)`
  border-radius: 9px;
  width: 38px;
  & > div {
    border-radius: 9px;
    padding: 2px;
    font-size: 13px;
    line-height: 1.25;
  }
`;
