import styled from "styled-components";
import imgMarker1 from "../../img/Marker1.png";
import imgMarker2 from "../../img/Marker2.png";
import imgMarker5 from "../../img/Marker5.png";
import imgCheck from "../../img/book/Check.png";
import imgCheckActive from "../../img/book/CheckActive.png";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Bellota-Regular";
  color: #413423;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  font-size: 17px;
  line-height: 15px;
  font-family: "Inkverse-BWDRx";
`;

export const RewardString = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  font-family: "Inkverse-BWDRx";
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  gap: 5px;
  padding-bottom: 10px;
  font-family: "Inkverse-BWDRx";
`;

export const Data = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  font-weight: 600;
`;

export const Text = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
  font-weight: 600;
`;

export const PageNumber = styled.div`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10px;
`;

export const GetButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const MarkerButton = styled.div`
  font-size: ${(props) => (props.size ? props.size : "14")}px;
  min-width: ${(props) => props.minW && `${props.minW}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #e0bc79;
  font-family: "Inkverse-BWDRx";
  font-weight: 400;
  & > div {
    backface-visibility: hidden;
    will-change: transform;

    width: 100%;
    display: flex;
    justify-content: center;
    gap: 4px;
    border-radius: 3px;
    padding: 4px 8px;

    background-image: ${(props) =>
        props.disabled && "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),"}
      url("${imgMarker1}");
    background-size: contain;
    transition: filter 0.2s linear;
    cursor: ${(props) => !props.disabled && "pointer"};
    &:hover {
      filter: ${(props) => !props.disabled && "brightness(1.2)"};
    }
    &:active {
      filter: ${(props) => !props.disabled && "brightness(0.9)"};
    }
  }
`;

export const Quest = styled.div`
  position: relative;
  padding: 4px;
  background-image: ${(props) =>
    props.isDone ? `url("${imgMarker2}")` : `url("${imgMarker5}")`};
  background-size: contain;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #413423;
  font-family: "Inkverse-BWDRx";
  font-size: 13px;
  margin-bottom: 3px;

  & > img {
    transition: filter 0.2s linear;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);

    &:hover {
      filter: ${(props) => !props.disabled && "brightness(1.15)"};
    }
    &:active {
      filter: ${(props) => !props.disabled && "brightness(0.9)"};
    }
    filter: ${(props) => props.disabled && "brightness(1)"};
  }
`;

export const CountsDone = styled.div`
  font-family: "Inkverse-BWDRx";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
  margin-bottom: 8px;
`;

export const Check = styled.div`
  position: relative;
  width: 18px;
  height: 18px;

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    background-image: url("${imgCheck}");
    background-size: cover;
  }

  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    opacity: ${(props) => (props.isActive ? `1` : `0`)};
    background-image: url("${imgCheckActive}");
    background-size: cover;
  }
`;

export const RewardWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;

  & > button {
    display: flex;
    gap: 5px;
    line-height: 15px;
  }
`;
