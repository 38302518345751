import styled from "styled-components";

import imgAvatar from "../../img/FriendsFrame.png";

export const LogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  overflow-y: hidden;
  max-height: 332px;

  & > div {
    text-shadow: 0 0 4px #000000;
    font-weight: 600;
  }

  & > div {
    text-shadow: 0 0 4px #000000;
    font-weight: 600;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const FightWrapper = styled.div`
  width: 100%;
  height: 370px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  & > div > div {
    margin-top: 71px;
    height: 332px;
  }
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3px;

  & > img {
    border-radius: 10px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("${imgAvatar}");
    background-size: contain;
  }
`;
