import React, { useEffect, useState } from "react";

import * as S from "./PotionLab.styles";
import { CentredWrapper, Close } from "../../App.styles";
import {
  roundConsumablesFixed,
  secondsToDhm,
  secondsToHmShort,
} from "../../helpers";
import potionClearImg from "../../img/potionLab/PotionClear.png";
import heallIconImg from "../../img/HealthPotion.png";
import energyIconImg from "../../img/EnergyPotion.png";
import Popup from "../../components/Popup/Popup";
import frame3 from "../../img/Frame3.png";
import Reward from "../../components/Reward/Reward";
import frame1 from "../../img/Frame1.png";
import { ButtonsWrapper } from "../../components/ActiveBoss/ActiveBoss.styles";
import waterNonActive from "../../img/greenHouse/WaterNonActive.png";
import waterActive from "../../img/greenHouse/WaterActive.png";
import imgInfo from "../../img/potionLab/InfoHover.png";
import frame8 from "../../img/Frame8.png";
import { BottomPrice, BottomPrices, Button } from "../Arena/Arena.styles";
import frame6 from "../../img/Frame6.png";
import { MenuRight, MenuElemRight } from "../Home/Home.styles";
import helperIcon from "../../img/menuIcons/Helper.png";
import { createPortal } from "react-dom";
import betFrame from "../../img/BetFrame.png";
import { Header } from "../../components/Ordens/Ordens.styles";
import {
  ButtonsXWrapper,
  ButtonXChange,
} from "../../components/Game21/Game21.styles";
import {
  BottomInfo,
  ButtonValueInput,
} from "../../components/DiceGame/DiceGame.styles";
import timeIcon from "../../img/menuIcons/Time.png";

const places = [
  {
    x: 690,
    y: 302,
    w: 118,
    h: 120,
    src: "9.png",
  },

  {
    x: 515,
    y: 319,
    w: 171,
    h: 154,
    src: "7.png",
  },

  {
    x: 97,
    y: 198,
    w: 70,
    h: 70,
    src: "1.png",
  },

  {
    x: 194,
    y: 197,
    w: 47,
    h: 47,
    src: "3.png",
  },

  {
    x: 310,
    y: 226,
    w: 95,
    h: 98,
    src: "5.png",
  },

  {
    x: 490,
    y: 218,
    w: 86,
    h: 86,
    src: "2.png",
  },
  {
    x: 448,
    y: 143,
    w: 76,
    h: 57,
    src: "6.png",
  },

  {
    x: 544,
    y: 155,
    w: 77,
    h: 48,
    src: "8.png",
  },

  {
    x: 547,
    y: 54,
    w: 64,
    h: 55,
    src: "10.png",
  },
  {
    x: 645,
    y: 51,
    w: 52,
    h: 45,
    src: "4.png",
  },
];

const lvlOnLvlNeed = [100, 250, 800, 2000, 4500, 10000, 22000];

const learnNeed = [0, 0, 0, 10, 20, 40, 80, 160, 320, 640];

const goldKf = [3.3339, 2.6668, 1.6667, 1.33335, 1.18519, 1.06667, 0.969699];

const goldUp = 15;

const potionsInfo = [
  {
    icon: heallIconImg,
    name: "Здоровье",
    count: "50%",
    potionName: "+50%",
    energy: 10,
    category: "hp",
  },
  {
    icon: energyIconImg,
    name: "Энергия",
    count: "50%",
    potionName: "+50%",
    energy: 10,
    category: "energy",
  },
];

function PotionLab({
  userId,
  potions,
  lvl,
  growOnLvl,
  setUser,
  app,
  isFetching,
  setIsFetching,
  changeOptionsLocation,
  setModalError,
  serverTime,
  classroomDone,
  userEnergy,
  gold,
  helpers,
  bridge,
  warOrdenTask,
  warOrdenDayTask,
}) {
  const [helperOpen, setHelperOpen] = useState(false);
  const [timeHelp, setTimeHelp] = useState(null);
  const [helpX, setHelpX] = useState(8);
  const [potionIsOpen, setPotionIsOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [potionNumber, setPotionNumber] = useState(-1);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [domReady, setDomReady] = React.useState(false);
  const [betOpen, setBetOpen] = useState(false);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("potionLab");
  }, []);

  useEffect(() => {
    if ((helpers?.potionLab || 0) >= serverTime) {
      setTimeHelp(
        secondsToDhm(((helpers?.potionLab || 0) - serverTime) / 1000)
      );
    } else {
      setHelpX(1);
      setTimeHelp(null);
    }
  }, [serverTime, helpers]);

  function changeBetOpen() {
    setBetOpen(true);
  }

  function changeCount(value) {
    if (value > 8) {
      if (value >= 1) {
        value = 8;
      } else {
        value = 1;
      }
    }
    setHelpX(value ? Math.abs(+Math.floor(value)) : "");
  }

  function changeCountInput(e) {
    let value = e.target.value;

    if (value > 8) {
      if (value >= 1) {
        value = 8;
      } else {
        value = 1;
      }
    }

    setHelpX(value ? Math.abs(+Math.floor(value)) : "");
  }

  function changeInfoOpen(info) {
    setIsInfoOpen(info);
  }

  function handleUpgradeOpen() {
    setUpgradeOpen((prev) => !prev);
  }

  function changeHelperOpen(open) {
    setHelperOpen(open);
  }

  function buyHelper({ days, price }) {
    setIsFetching(true);

    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          helpersPotionLab: days,
          g: price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  }

  function getPrize(number) {
    setIsFetching(true);
    setIsLoading(true);

    const isLvlUp =
      growOnLvl + 1 * (potions[number].helpX || 1) >= lvlOnLvlNeed[lvl - 1];
    const points = isLvlUp
      ? growOnLvl + 1 * (potions[number].helpX || 1) - lvlOnLvlNeed[lvl - 1]
      : growOnLvl + 1 * (potions[number].helpX || 1);

    app
      .service("users")
      .patch(
        userId,
        {
          [`potionLab.potions.${number}.helpX`]: 1,
          [`potionLab.potions.${number}.activePotion`]: -1,
          [`potionLab.potions.${number}.spoons`]: 0,
          "potionLab.growOnLvl": points,
          $inc: {
            [`inventoryNew.${
              potionsInfo[potions[number].activePotion].category
            }+50%`]:
              (potions[number].spoons + 1) * (potions[number].helpX || 1),
            "potionLab.lvl": isLvlUp ? 1 : 0,
            "dayQuest.potionsGet":
              (potions[number].spoons + 1) * (potions[number].helpX || 1),
            "summerSave.results.potions":
              (potions[number].spoons + 1) * (potions[number].helpX || 1),
          },
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "inventoryNew",
              "potionLab",
              "dayQuest",
              "summerSave",
            ],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setIsLoading(false);

        const tasks = [
          {
            property: `potions${
              potions[number].activePotion === 0 ? "Health" : "Energy"
            }Get`,
            count: (potions[number].spoons + 1) * (potions[number].helpX || 1),
          },
          {
            property: `potionsGet`,
            count: (potions[number].spoons + 1) * (potions[number].helpX || 1),
          },
        ];

        const tasksDay = [
          {
            property: `potionsGet`,
            count: (potions[number].spoons + 1) * (potions[number].helpX || 1),
          },
        ];

        warOrdenTask(tasks);
        warOrdenDayTask(tasksDay);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
        setIsLoading(false);
      });
  }

  function getSpoon(number) {
    setIsFetching(true);
    setIsLoading(true);
    app
      .service("users")
      .patch(
        userId,
        {
          [`potionLab.potions.${number}.spoonTime`]: serverTime + 3600000,
          $inc: {
            [`potionLab.potions.${number}.spoons`]: 1,
          },
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "potionLab"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
        setIsLoading(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
        setIsLoading(false);
      });
  }

  function getPotion({ activePotion, number }) {
    if (userEnergy >= potionsInfo[activePotion].energy * (helpX || 1)) {
      setIsFetching(true);
      setIsLoading(true);
      app
        .service("users")
        .patch(
          userId,
          {
            [`potionLab.potions.${number}.helpX`]: helpX || 1,
            [`potionLab.potions.${number}.spoons`]: timeHelp ? 2 : 0,
            [`potionLab.potions.${number}.activePotion`]: activePotion,
            [`potionLab.potions.${number}.endTime`]:
              serverTime + 10800000 * (helpX || 1),
            [`potionLab.potions.${number}.spoonTime`]: timeHelp
              ? 0
              : serverTime + 3600000,
            $inc: {
              currentEnergy: -potionsInfo[activePotion].energy * (helpX || 1),
              "newQuest.results.potions": helpX || 1,
            },
            field: serverTime,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "currentEnergy",
                "potionLab",
                "newQuest",
              ],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setPotionIsOpen(false);
          setIsFetching(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setPotionIsOpen(false);
          setIsFetching(false);
          setIsLoading(false);
        });
    } else {
      setModalError("Недостаточно энергии");
      console.log("Недостаточно энергии");
    }
  }

  function openPotions(number = -1) {
    setPotionNumber(number);
    setPotionIsOpen((prev) => !prev);
  }

  function gradeLvl() {
    const price = Math.floor(
      goldUp * goldKf[lvl - 1] * (lvlOnLvlNeed[lvl - 1] - growOnLvl)
    );
    if (gold >= price) {
      setIsLoading(true);
      setIsFetching(true);
      app
        .service("users")
        .patch(
          userId,
          {
            "potionLab.lvl": lvl + 1,
            "potionLab.growOnLvl": 0,
            $inc: {
              gold: -price,
            },
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "potionLab", "gold"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
          setIsLoading(false);
          setUpgradeOpen(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsLoading(false);
        });
    } else {
      setModalError("Недостаточно золота");
      console.log("Недостаточно золота");
    }
  }

  return (
    <>
      <CentredWrapper>
        <S.Info
          top={301}
          left={143}
          width={101}
          height={120}
          onClick={changeInfoOpen.bind(null, true)}
        >
          <img src={imgInfo} alt="книга" />
        </S.Info>

        {places.map((elem, i) => {
          return (
            <React.Fragment key={i}>
              {potions[i].activePotion >= 0 ? (
                <S.Place
                  isActive={serverTime > potions[i].endTime}
                  isOpen={lvl + 2 > i && classroomDone >= learnNeed[i]}
                  x={elem.x}
                  y={elem.y}
                  onClick={
                    !isLoading && !isFetching && serverTime > potions[i].endTime
                      ? getPrize.bind(null, i)
                      : null
                  }
                >
                  <img
                    src={require(`../../img/potionLab/hoverActive/${elem.src}`)}
                    alt=""
                    width={elem.w}
                    height={elem.h}
                  />
                </S.Place>
              ) : (
                <S.Place
                  x={elem.x}
                  y={elem.y}
                  isActive={true}
                  isOpen={lvl + 2 > i && classroomDone >= learnNeed[i]}
                  onClick={
                    lvl + 2 > i && classroomDone >= learnNeed[i]
                      ? openPotions.bind(null, i)
                      : null
                  }
                >
                  {!(lvl + 2 > i && classroomDone >= learnNeed[i]) && (
                    <S.InfoPopup>
                      <span>Необходимо:</span>
                      <span>Ур. практики: {i - 1}</span>
                      <span>
                        Уроков: {roundConsumablesFixed(classroomDone)}/
                        {learnNeed[i]}
                      </span>
                    </S.InfoPopup>
                  )}

                  <img
                    src={require(`../../img/potionLab/hover/${elem.src}`)}
                    alt=""
                    width={elem.w}
                    height={elem.h}
                  />
                </S.Place>
              )}

              {lvl + 2 > i && classroomDone >= learnNeed[i] && (
                <S.InfoPlace
                  x={elem.x}
                  y={elem.y}
                  width={elem.w}
                  height={elem.h}
                >
                  <div>
                    {potions[i].activePotion > -1 ? (
                      serverTime > potions[i].endTime ? (
                        <Reward
                          g={1}
                          button={true}
                          category={
                            potionsInfo[potions[i].activePotion]?.category
                          }
                          potionName={
                            potionsInfo[potions[i].activePotion]?.potionName
                          }
                          name={"potion"}
                          count={
                            (potions[i].spoons + 1) * (potions[i].helpX || 1)
                          }
                          w={12}
                        />
                      ) : (
                        <>
                          {secondsToHmShort(
                            Math.floor((potions[i].endTime - serverTime) / 1000)
                          )}
                        </>
                      )
                    ) : (
                      <img
                        src={potionClearImg}
                        width={20}
                        height={20}
                        alt="котелок"
                      />
                    )}

                    {potions[i].activePotion > -1 &&
                      potions[i].spoons < 2 &&
                      serverTime <= potions[i].endTime && (
                        <S.WaterPlace
                          isActive={serverTime >= potions[i].spoonTime}
                          onClick={
                            !isLoading &&
                            !isFetching &&
                            serverTime >= potions[i].spoonTime
                              ? getSpoon.bind(null, i)
                              : null
                          }
                          percent={
                            potions[i].spoonTime - serverTime <= 0
                              ? 100
                              : (
                                  100 -
                                  (potions[i].spoonTime - serverTime) / 36000
                                ).toFixed(2)
                          }
                        >
                          <img
                            src={waterNonActive}
                            width={28}
                            height={28}
                            alt="вода"
                          />
                          <img
                            src={waterActive}
                            width={28}
                            height={28}
                            alt="вода"
                          />
                          <S.InfoWater>
                            {serverTime >= potions[i].spoonTime ? (
                              <span>Добавить воду</span>
                            ) : (
                              <>
                                <span>Добавить воду:</span>
                                <span>
                                  {secondsToHmShort(
                                    Math.floor(
                                      (potions[i].spoonTime - serverTime) / 1000
                                    )
                                  )}
                                </span>
                              </>
                            )}
                          </S.InfoWater>
                        </S.WaterPlace>
                      )}
                  </div>
                </S.InfoPlace>
              )}
            </React.Fragment>
          );
        })}
      </CentredWrapper>

      {domReady
        ? createPortal(
            <MenuRight>
              <MenuElemRight name={"Помощник"}>
                <div onClick={changeHelperOpen.bind(null, true)} />
                <img width={80} height={80} src={helperIcon} alt={"помощник"} />
              </MenuElemRight>
              {timeHelp && (
                <MenuElemRight name={"Время"}>
                  <div onClick={changeBetOpen.bind(null, true)} />
                  <img width={80} height={80} src={timeIcon} alt={"время"} />
                </MenuElemRight>
              )}
            </MenuRight>,
            document.querySelector("#menuRight")
          )
        : null}

      <Popup
        isOpen={helperOpen}
        setIsOpen={setHelperOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeHelperOpen.bind(null, false)} />
        <b>Помощник в Лаборатории</b>
        <span>Помощник сварит до 24 зелий в котле (24 часа)</span>
        <span>Помощник сам добавит воду</span>
        {timeHelp && (
          <>
            <span>Оставшееся время помощника:</span>
            <span>{timeHelp}</span>
          </>
        )}
        <BottomPrice>
          {timeHelp ? (
            <span>Желаете продлить помощника?</span>
          ) : (
            <span>Доступные варианты для помощника:</span>
          )}

          <BottomPrices>
            <div>
              <span>3 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 3, price: 7 })}
              >
                <div>7 голосов</div>
              </Button>
            </div>
            <div>
              <span>7 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 7, price: 10 })}
              >
                <div>10 голосов</div>
              </Button>
            </div>
            <div>
              <span>30 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 30, price: 30 })}
              >
                <div>30 голосов</div>
              </Button>
            </div>
          </BottomPrices>
        </BottomPrice>
      </Popup>

      <Popup
        isOpen={isInfoOpen}
        setIsOpen={setIsInfoOpen}
        w={556}
        h={320}
        back={frame8}
      >
        <Close onClick={changeInfoOpen.bind(null, false)} />

        <b>Практика: Зельеварение {lvl} уровень</b>

        <span>
          В котлы можно добавлять воду каждые 60 минут, тем самым увеличивая
          количество приготавливаемых зелий.
        </span>

        <span>
          С повышением уровня Вы сможете открывать новые котлы, а также
          прогрессировать на уроке Зельеварения.
        </span>

        {lvl <= lvlOnLvlNeed.length ? (
          <>
            <span>
              Приготовлений на уровень: {growOnLvl}/{lvlOnLvlNeed[lvl - 1]}
            </span>

            <Button
              width={140}
              onClick={!isFetching ? handleUpgradeOpen : null}
            >
              <div>Повысить уровень</div>
            </Button>
          </>
        ) : (
          <Button width={140} disabled={true}>
            <div>Максимальный уровень</div>
          </Button>
        )}
      </Popup>

      <Popup
        isOpen={potionIsOpen}
        setIsOpen={setPotionIsOpen}
        w={317}
        h={217}
        back={frame3}
      >
        <Close
          onClick={() => {
            setPotionIsOpen(false);
          }}
        />

        <S.PotionsGetWrapper>
          {potionsInfo.map((elem, i) => {
            return (
              <div key={i}>
                <span>{elem.name}</span>
                <S.IconWrapper>
                  <span>{elem.count}</span>
                  <img width={50} height={50} src={elem.icon} alt="хп" />
                </S.IconWrapper>
                <Button
                  onClick={
                    !isLoading && !isFetching
                      ? getPotion.bind(null, {
                          activePotion: i,
                          number: potionNumber,
                        })
                      : null
                  }
                >
                  <Reward
                    button={true}
                    name={"energy"}
                    count={`Сварить ${elem.energy * (helpX || 1)}`}
                    w={15}
                  />
                </Button>
              </div>
            );
          })}
        </S.PotionsGetWrapper>
      </Popup>
      <Popup
        isOpen={upgradeOpen}
        setIsOpen={setUpgradeOpen}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={handleUpgradeOpen} />
        <b>Желаете повысить уровень зельеварения до {lvl + 1}?</b>
        <ButtonsWrapper>
          <Button
            disabled={isLoading || isFetching}
            onClick={!isLoading && !isFetching ? gradeLvl : null}
          >
            <Reward
              button={true}
              name={"gold"}
              count={`Повысить ${Math.floor(
                goldUp * goldKf[lvl - 1] * (lvlOnLvlNeed[lvl - 1] - growOnLvl)
              )}`}
              w={15}
            />
          </Button>
        </ButtonsWrapper>
      </Popup>

      <Popup
        isOpen={betOpen}
        setIsOpen={setBetOpen}
        w={317}
        h={217}
        back={betFrame}
        justify={"start"}
      >
        <Close
          onClick={() => {
            setBetOpen(false);
          }}
        />

        <Header>Выбор количества</Header>

        <ButtonsXWrapper>
          <ButtonXChange
            disabled={helpX === 8}
            onClick={changeCount.bind(null, 8)}
          >
            <div>8</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={helpX === 4}
            onClick={changeCount.bind(null, 4)}
          >
            <div>4</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={helpX === 3}
            onClick={changeCount.bind(null, 3)}
          >
            <div>3</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={helpX === 2}
            onClick={changeCount.bind(null, 2)}
          >
            <div>2</div>
          </ButtonXChange>
          <ButtonXChange
            disabled={helpX === 1}
            onClick={changeCount.bind(null, 1)}
          >
            <div>1</div>
          </ButtonXChange>
        </ButtonsXWrapper>

        <ButtonValueInput>
          <div>
            <input
              value={helpX || 1}
              onChange={changeCountInput}
              type="number"
              max={8}
              min={1}
              placeholder={""}
              step={1}
            />
          </div>
        </ButtonValueInput>

        <BottomInfo>Потребуется времени: {(helpX || 1) * 3}ч.</BottomInfo>
      </Popup>
    </>
  );
}

export default React.memo(PotionLab);
