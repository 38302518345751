import React from "react";

import * as S from "./LocationInfo.styles";
import arkeinImg from "../../img/bosses/Arkein.jpg";
import proklytElfImg from "../../img/bosses/ProklytElf.jpg";
import dementorImg from "../../img/bosses/Dementor.jpg";
import dvehgoloviImg from "../../img/bosses/Dvehgolovi.jpg";
import grindiloyImg from "../../img/bosses/Grindiloy.jpg";
import korespondentImg from "../../img/bosses/Korespondent.jpg";
import zyfyImg from "../../img/bosses/Zyfy.jpg";
import shytImg from "../../img/bosses/Shyt.jpg";
import trollImg from "../../img/bosses/Troll.jpg";
import zlatoystImg from "../../img/bosses/Zlatoyst.jpg";

function getName(name) {
  switch (name) {
    case "home":
      return "Гостиная";
    case "bank":
      return "Банк";
    case "userRoom":
      return "Гостиная";
    case "greenHouse":
      return "Теплица";
    case "potionLab":
      return "Лаборатория";
    case "gameRoom":
      return "Игровая комната";
    case "topsRoom":
      return "Зал славы";
    case "classRoom":
      return "Учебные классы";
    case "grassRoom":
      return "Растениеводство";
    case "potionRoom":
      return "Зельеварение";

    case "garderob":
      return "Гардероб";

    case "eventBoss":
      return "Ивентовый Босс";

    case "soloBoss":
      return "Соло-Боссы";

    case "raidBoss":
      return "Рейдовый Босс";

    case "Турнир":
      return "Турнир";

    case "bosses":
      return "Бестиарий";

    case "bosses2":
      return "Бестиарий";

    case "Хвост":
      return "Хвост";

    case "Зуфу":
      return "Зуфу";

    case "Корреспондент":
      return "Корреспондент";

    case "Гриндиллоу":
      return "Гриндиллоу";

    case "Демментор":
      return "Демментор";

    case "Двухголовый":
      return "Двухголовый";

    case "Проклятый эльф":
      return "Проклятый эльф";

    case "Аркейн":
      return "Аркейн";

    case "Шут":
      return "Шут";
    case "Тролль":
      return "Тролль";
    case "Банши":
      return "Банши";
    case "Сточные крысы":
      return "Сточные крысы";
    case "Владыка холода":
      return "Владыка холода";
    case "Порождения холода":
      return "Порождения холода";
    case "Турнир Дуэлей":
      return "Турнир Дуэлей";
    case "arena":
      return "Дуэльная комната";
    case "privateRoom":
      return "Личная комната";
    case "castleEntry":
      return "Вход в замок";
    case "dungeonEntry":
      return "Вход в подземелье";
    case "Пикси":
      return "Пикси";
    case "Полтергейст":
      return "Полтергейст";
    case "Призрак Плаксы":
      return "Призрак Плаксы";
    case "Жуткий Завхоз":
      return "Жуткий Завхоз";
    case "Кошмар":
      return "Кошмар";
    case "Зловещий Призрак":
      return "Зловещий Призрак";
    case "Обскур":
      return "Обскур";
    case "Лже-маг":
      return "Лже-маг";
    case "Задиры":
      return "Задиры";
    case "Златоуст":
      return "Златоуст";
    case "Призрак Рыцаря":
      return "Призрак Рыцаря";
    case "Демон канализации":
      return "Демон канализации";

    case "Инспектор":
      return "Инспектор";
    case "Двуликий":
      return "Двуликий";
    case "Сумрак":
      return "Сумрак";
    case "Биомасса":
      return "Биомасса";

    case `Игра в "21"`:
      return `Игра в "21"`;

    case "Игра в кости":
      return "Игра в кости";

    case `Турнир "кости"`:
      return `Турнир "кости"`;

    case `Турнир "21"`:
      return `Турнир "21"`;

    default:
      return "Локация";
  }
}

function LocationInfo({ name, ownerRoom }) {
  return (
    <S.Wrapper ownerRoom={ownerRoom}>
      <span>
        {ownerRoom
          ? ownerRoom.nickName
            ? ownerRoom.nickName
            : `${ownerRoom.name?.firstName} ${ownerRoom.name?.lastName}`
          : getName(name)}
      </span>
    </S.Wrapper>
  );
}

export default React.memo(LocationInfo);
