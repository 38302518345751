import React, { useEffect, useState } from "react";

import * as S from "./SummerSaveQuests.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import Reward from "../Reward/Reward";
import {
  newSummerSavePrizes,
  newSummerSaveClothes,
  summerSaveQuestsArray,
  allClothes,
  grindData,
} from "../../info/data";
import { Button } from "../ActiveBoss/ActiveBoss.styles";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import { Close } from "../../App.styles";

function SummerSaveQuests({
  isOpen,
  user,
  time,
  setModalError,
  setIsFetching,
  isFetching,
  setUser,
  app,
  bridge,
  dayActive,
  activePanel,
  setActivePanel,
}) {
  const [allDone, setAllDone] = useState(false);
  const [endOpen, setEndOpen] = useState(false);

  useEffect(() => {
    setAllDone(
      summerSaveQuestsArray[dayActive].findIndex(
        (elem) => (user.summerSave.results?.[elem.property] || 0) < elem.count,
      ) === -1,
    );
  }, [user]);

  function changeEndOpen(bool) {
    setEndOpen(bool);
  }

  function moveToBoss() {
    if (activePanel !== "eventBoss") {
      setActivePanel("eventBoss");
      app
        .service("users")
        .patch(
          user._id,
          {
            eventBossNumber: 0,
            common: true,
          },
          {
            query: {
              $select: ["_id", "email", "eventBossNumber"],
            },
          },
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    } else {
      setModalError("Вы уже находитесь в этой локации");
    }
  }

  function vkPay(params) {
    setIsFetching(true);
    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          [params.property]: params.count,
          g: params.price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
          changeEndOpen(true);
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  }

  function getReward() {
    const reward = newSummerSaveClothes[dayActive];
    const clothesName = reward.category + reward.name;

    const buyClothes = [...user.clothes.isBuy, clothesName];

    const newCharacteristic = { ...user.characteristic };

    for (const key in newCharacteristic) {
      newCharacteristic[key].armor = 0;
    }

    const clothes = Object.entries(allClothes).filter((elem) =>
      buyClothes.includes(elem[0]),
    );

    clothes.forEach((elem) => {
      Object.entries(elem[1].characteristic).forEach((el) => {
        newCharacteristic[el[0]].armor += el[1];
      });
    });

    const scales = {};
    const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
    user.damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const hp = Math.floor(
      Object.values(newCharacteristic.health).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * 5 * scales.health;
        }
        return acc + cur * 5;
      }, 0),
    );

    setIsFetching(true);

    app
      .service("users")
      .patch(
        user._id,
        {
          "health.max": hp,
          "clothes.isBuy": buyClothes,
          characteristic: newCharacteristic,
          [`summerSave.quests.${dayActive}`]: true,
          common: true,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "health",
              "clothes",
              "characteristic",
              "summerSave",
            ],
          },
        },
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  return (
    <S.Wrapper isOpen={isOpen}>
      <S.Time>До завершения: {time}</S.Time>
      <S.Header>
        Летние наборы (доступно: {10 - user.summerSave.countPay})
      </S.Header>

      <S.NewBox>
        <div>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"gold"}
                count={newSummerSavePrizes[0].gold}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"silver"}
                count={newSummerSavePrizes[0].silver}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"mana"}
                count={newSummerSavePrizes[0].mana}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>

          <S.ButtonBottom>
            <Button
              minW={140}
              disabled={10 - user.summerSave.countPay <= 0}
              onClick={
                !isFetching && 10 - user.summerSave.countPay > 0
                  ? vkPay.bind(null, {
                      property: "summerSave",
                      count: 0,
                      price: newSummerSavePrizes[0].price,
                    })
                  : null
              }
            >
              <div>{newSummerSavePrizes[0].price} гол.</div>
            </Button>
          </S.ButtonBottom>
        </div>
        <div>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"gold"}
                count={newSummerSavePrizes[1].gold}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"silver"}
                count={newSummerSavePrizes[1].silver}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"mana"}
                count={newSummerSavePrizes[1].mana}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>

          <S.ButtonBottom>
            <Button
              minW={140}
              disabled={10 - user.summerSave.countPay <= 0}
              onClick={
                !isFetching && 10 - user.summerSave.countPay > 0
                  ? vkPay.bind(null, {
                      property: "summerSave",
                      count: 1,
                      price: newSummerSavePrizes[1].price,
                    })
                  : null
              }
            >
              <div>{newSummerSavePrizes[1].price} гол.</div>
            </Button>
          </S.ButtonBottom>
        </div>
        <div>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"gold"}
                count={newSummerSavePrizes[2].gold}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"silver"}
                count={newSummerSavePrizes[2].silver}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"mana"}
                count={newSummerSavePrizes[2].mana}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <S.ButtonBottom>
            <Button
              minW={140}
              disabled={10 - user.summerSave.countPay <= 0}
              onClick={
                !isFetching && 10 - user.summerSave.countPay > 0
                  ? vkPay.bind(null, {
                      property: "summerSave",
                      count: 2,
                      price: newSummerSavePrizes[2].price,
                    })
                  : null
              }
            >
              <div>{newSummerSavePrizes[2].price} гол.</div>
            </Button>
          </S.ButtonBottom>
        </div>
      </S.NewBox>

      <S.Header>Летние задания день {dayActive + 1}</S.Header>
      <S.Time>
        Каждый день новые задания и часть гардероба. Пропущенный день -
        упущенная возможность
      </S.Time>

      <S.DayPrizes>
        {newSummerSaveClothes.map((elem, i) => {
          return (
            <div key={i}>
              <RewardWrapper
                isDone={user.summerSave.quests[i]}
                light={false}
                w={85}
                isLate={!user.summerSave.quests[i] && dayActive > i}
              >
                <div>
                  {elem.category === "broom" || elem.category === "wand" ? (
                    <img
                      width={55}
                      height={55}
                      src={require(
                        `../../img/clothes/${elem.category}${
                          elem.lvl >= 1 ? `${elem.lvl + 1}` : ""
                        }/Event/${elem.name}.png`,
                      )}
                      alt="вещь"
                    />
                  ) : (
                    <img
                      width={55}
                      height={55}
                      src={require(
                        `../../img/clothes/${elem.category}${
                          elem.lvl >= 1 ? `${elem.lvl + 1}` : ""
                        }/${user.sex}/Event/${elem.name}.png`,
                      )}
                      alt="вещь"
                    />
                  )}
                </div>
              </RewardWrapper>
              <S.DayPrizeText
                isDone={user.summerSave.quests[i]}
                isLate={!user.summerSave.quests[i] && dayActive > i}
              >
                {user.summerSave.quests[i]
                  ? "Получено"
                  : !user.summerSave.quests[i] && dayActive > i
                    ? "Упущено"
                    : `${i + 1} день`}
              </S.DayPrizeText>
            </div>
          );
        })}
      </S.DayPrizes>

      <S.QuestsWrapper>
        {summerSaveQuestsArray[dayActive].map((elem, i) => {
          return (
            <S.Quest
              isDone={
                (user.summerSave.results?.[elem.property] || 0) >= elem.count
              }
              last={summerSaveQuestsArray[dayActive].length === i + 1}
              key={i}
            >
              <span>
                {i + 1}. {elem.string}. Прогресс:{" "}
                {(user.summerSave.results?.[elem.property] || 0) > elem.count
                  ? elem.count
                  : user.summerSave.results?.[elem.property] || 0}
                /{elem.count}
              </span>

              {i === 0 && (
                <Button
                  minW={100}
                  disabled={
                    (user.summerSave.results?.[elem.property] || 0) >=
                      elem.count || user.summerSave.quests[dayActive]
                  }
                  onClick={
                    !isFetching &&
                    !(
                      (user.summerSave.results?.[elem.property] || 0) >=
                      elem.count
                    ) &&
                    !user.summerSave.quests[dayActive]
                      ? moveToBoss
                      : null
                  }
                >
                  <div>Перейти</div>
                </Button>
              )}
            </S.Quest>
          );
        })}
        <S.ButtonBottom>
          <Button
            minW={140}
            disabled={!allDone || user.summerSave.quests[dayActive]}
            onClick={
              !isFetching && allDone && !user.summerSave.quests[dayActive]
                ? getReward
                : null
            }
          >
            <div>
              {user.summerSave.quests[dayActive] ? "Выполнено" : "Получить"}
            </div>
          </Button>
        </S.ButtonBottom>
      </S.QuestsWrapper>

      <Popup
        isOpen={endOpen}
        setIsOpen={setEndOpen}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={changeEndOpen.bind(null, false)} />
        <b>Оплата прошла успешно</b>
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(SummerSaveQuests);
