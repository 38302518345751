import React, { useEffect, useState } from "react";
import * as S from "./Sound..styles";
import soundImg from "../../img/miniIcons/SoundOn.png";
import noSoundImg from "../../img/miniIcons/SoundOff.png";

function Sound({
  mute,
  isSound,
  setIsFetching,
  setUser,
  userId,
  serverTime,
  setModalError,
  app,
}) {
  const [isMute, setIsMute] = useState(!isSound);

  function changeSound() {
    setIsMute((prev) => {
      saveMute(prev);
      return !prev;
    });
  }

  function saveMute(isMute) {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        userId,
        {
          isSound: isMute,
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "isSound"],
          },
        },
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  useEffect(() => {
    mute(isMute);
  }, [isMute]);

  return (
    <S.Wrapper onClick={changeSound}>
      <img src={soundImg} alt="звук" width={15} height={15} />
      {isMute && <img src={noSoundImg} alt="звук" width={15} height={15} />}
    </S.Wrapper>
  );
}

export default React.memo(Sound);
