import styled from "styled-components";
import imgMarker2 from "../../img/Marker2Dark.png";
import imgMarker5 from "../../img/Marker5Dark.png";

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const Place = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.x && props.x}px;
  top: ${(props) => props.y && props.y}px;
  clip-path: ${(props) => props.clip};
  opacity: 0;
  transition: 0.3s linear;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const RulesContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 20px 30px;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 3px;
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  color: #ffead1;
  height: 435px;
  width: 700px;
`;

export const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  height: 140px;
`;

export const PrizesTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const Game21Prize = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
`;

export const ImgArrowLeft = styled.img`
  margin-top: 8px;
`;

export const ImgArrowRight = styled(ImgArrowLeft)`
  transform: scaleX(-1);
`;

export const Game21PrizeBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  width: 100%;
  margin-top: 15px;
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 0.7fr 1fr;
  gap: 10px;

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
`;

export const Header = styled.span`
  color: #ffcf82;
  font-size: 18px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 3px;
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  font-size: 12px;
  font-weight: 600;
  height: ${(props) => props.height && `${props.height}px`};
  gap: ${(props) => (props.gap ? `${props.gap}px` : "6px")};
`;

export const ColumnLeft = styled(Column)`
  align-items: center;
  min-height: 380px;
  max-height: 380px;
  font-size: 13px;
  line-height: 15px;
`;

export const ColumnRight = styled(Column)`
  gap: 0;
  padding: 0;
  min-height: 400px;
  max-height: 400px;
`;

export const ColumnRightFull = styled(Column)`
  gap: 0;
  padding: 0;
  min-height: 464px;
  max-height: 464px;
`;

export const ColumnBottom = styled(Column)`
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const PriceCount = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  line-height: 15px;
`;

export const BottomRight = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const Names = styled.div`
  width: 100%;
  gap: 5px;
  display: grid;
  grid-template-columns: 0.7fr 0.7fr 1.8fr 0.7fr 1fr;
  padding: 10px 15px 10px 10px;
  border-bottom: 2px solid #fc9800;
`;

export const Top = styled.div`
  width: 100%;
  gap: 5px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  line-height: 20px;
  grid-template-columns: 0.7fr 0.7fr 1.8fr 0.7fr 1fr;

  min-height: 50px;
  max-height: 50px;

  background-image: url("${(props) =>
    props.meTop ? "" : props.isMe ? imgMarker2 : imgMarker5}");
  background-size: contain;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PrizesWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const Table1 = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 3px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #fc9800;
  margin: 10px 0;
  table-layout: fixed;

  & tr > td {
    vertical-align: center;
    width: 100%;
    max-width: 25%;
    border: 1px solid #fc9800;

    margin: 0;
    padding: 3px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 6px;
    }
  }
`;

export const Table2 = styled(Table1)`
  & tr > td {
    max-width: 70%;
  }
  & tr > td:nth-child(2) {
    max-width: 30%;
  }
`;
