import React, { useEffect, useRef, useState } from "react";
import * as S from "./Healls.styles";
import scrollButtonImg from "../../img/ScrollButton.png";
import heallImg from "../../img/Heall.png";
import { inventoryItems } from "../../info/data";

function Healls({
  inventory,
  useItem,
  isFetching,
  bossesInfo,
  setBossesInfo,
  currentHeal,
}) {
  const [inventoryArray, setInventoryArray] = useState([]);
  const [activeHpItem, setActiveHpItem] = useState();
  const [count, setCount] = useState(bossesInfo?.countHeall || 1);

  const leftMenuNumber = useRef(bossesInfo?.lastHeall || 0);

  useEffect(() => {
    const array = [];

    inventoryItems.map((elem) => {
      const item = inventory[`${elem.category}${elem.name}`];
      if (item > 0) {
        array.push({ ...elem, count: item });
      }
    });

    setInventoryArray([...array].filter((elem) => elem.category === "hp"));
  }, [inventory]);

  useEffect(() => {
    if (inventoryArray.length) {
      if (leftMenuNumber.current > inventoryArray.length - 1) {
        leftMenuNumber.current = inventoryArray.length - 1;
        setBossesInfo((prev) => ({
          ...prev,
          lastHeall: inventoryArray.length - 1,
        }));
      }

      setActiveHpItem(inventoryArray[leftMenuNumber.current]);
    } else {
      setActiveHpItem(undefined);
    }
  }, [inventoryArray]);

  useEffect(() => {
    if (activeHpItem) {
      if (count > activeHpItem.count) {
        if (activeHpItem.count >= 1) {
          setCount(activeHpItem.count);
        } else {
          setCount(1);
        }
      } else if (count < 1) {
        setCount(1);
      }
    }
  }, [count, activeHpItem]);

  useEffect(() => {
    setBossesInfo((prev) => ({
      ...prev,
      countHeall: count,
    }));
  }, [count]);

  function changeCount(isPlus) {
    if (activeHpItem) {
      if (isPlus) {
        setCount((prev) => {
          if (prev + 1 > activeHpItem.count) {
            if (activeHpItem.count >= 1) {
              return activeHpItem.count;
            } else {
              return 1;
            }
          }
          return prev + 1;
        });
      } else {
        setCount((prev) => {
          if (prev - 1 < 1) {
            return 1;
          }
          return prev - 1;
        });
      }
    }
  }

  function menuButtonClick(direction) {
    if (direction === "top") {
      setBossesInfo((prev) => ({
        ...prev,
        lastHeall:
          leftMenuNumber.current - 1 >= 0 ? leftMenuNumber.current - 1 : 0,
      }));
      leftMenuNumber.current =
        leftMenuNumber.current - 1 >= 0 ? leftMenuNumber.current - 1 : 0;
      setActiveHpItem(inventoryArray[leftMenuNumber.current]);
    }
    if (direction === "bottom") {
      setBossesInfo((prev) => ({
        ...prev,
        lastHeall:
          leftMenuNumber.current + 1 <= inventoryArray.length - 1
            ? leftMenuNumber.current + 1
            : inventoryArray.length - 1,
      }));
      leftMenuNumber.current =
        leftMenuNumber.current + 1 <= inventoryArray.length - 1
          ? leftMenuNumber.current + 1
          : inventoryArray.length - 1;
      setActiveHpItem(inventoryArray[leftMenuNumber.current]);
    }
  }

  function useHealItem() {
    useItem(activeHpItem, count, null);
  }

  return (
    activeHpItem && (
      <S.Wrapper>
        <S.ImgHeall
          ref={currentHeal}
          onClick={!isFetching ? useHealItem : null}
        >
          <S.InfoHeallCount
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div>
              <span>Количество:</span>
              <span>{activeHpItem.count}</span>
            </div>
          </S.InfoHeallCount>
          <img width={46} height={46} src={heallImg} alt={"хилка"} />
          <S.InfoHeall>{activeHpItem.name}</S.InfoHeall>
        </S.ImgHeall>
        <S.ButtonLeft
          onClick={menuButtonClick.bind(null, "top")}
          limit={leftMenuNumber.current === 0}
        >
          <img width={24} height={24} src={scrollButtonImg} alt="вперёд" />
        </S.ButtonLeft>
        <S.ButtonRight
          onClick={menuButtonClick.bind(null, "bottom")}
          limit={
            inventoryArray.length > 0 &&
            leftMenuNumber.current === inventoryArray.length - 1
          }
        >
          <img width={24} height={24} src={scrollButtonImg} alt="назад" />
        </S.ButtonRight>

        <S.ButtonChangeWrapper>
          <S.ButtonChange islast={true} onClick={changeCount.bind(null, true)}>
            <div>+1</div>
          </S.ButtonChange>
          <S.ButtonValue islast={false}>
            <div>{count}</div>
          </S.ButtonValue>
          <S.ButtonChange
            islast={false}
            onClick={changeCount.bind(null, false)}
          >
            <div>-1</div>
          </S.ButtonChange>
        </S.ButtonChangeWrapper>
      </S.Wrapper>
    )
  );
}

export default React.memo(Healls);
