import styled, { css } from "styled-components";
import btnImg from "../../img/miniIcons/Button.png";

export const Wrapper = styled.div`
  position: absolute;
  left: 465px;
  bottom: 8px;
  display: flex;
  align-items: center;
`;

export const FriendsBlock = styled.div`
  position: absolute;
  display: flex;
  gap: 1px;
`;

export const FriendsFrames = styled.div`
  display: flex;
  gap: 1px;
`;

export const Friend = styled.div`
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};
`;

export const Number = styled.span`
  cursor: default;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1;
  position: absolute;
  color: rgb(247, 180, 113);
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 45px;
  text-align: center;
  max-width: 45px;
`;

export const Options = styled.div`
  top: 0;
  right: 4px;
  position: absolute;
  cursor: pointer;
  & > svg {
    width: 12px !important;
    height: 12px !important;
    color: #f7b471;
  }
  & > div {
    transition: all 0.15s linear;
  }

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
`;

export const MenuOptions = styled.div`
  position: absolute;
  right: 12px;
  top: -2px;
  display: flex;
  flex-direction: column;
  justify-items: end;
  opacity: 0;
  visibility: hidden;
  padding: 2px 4px;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 3px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  z-index: 2;
  & span,
  & a {
    text-decoration: none;
    font-size: 12px;
    padding: 3px;
    line-height: 1;
    color: rgb(247, 180, 113);
    transition: all 0.15s linear;
    backface-visibility: hidden;
    will-change: transform;
  }
  & span:hover,
  & a:hover {
    filter: brightness(1.25);
  }
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  position: absolute;
  left: -2px;
  transform: translateX(-100%);
`;

export const ArrowsWrapperRight = styled(ArrowsWrapper)`
  left: unset;
  right: -2px;
  transform: translateX(100%);
`;

export const PrevButton = styled.div`
  background-image: url("${btnImg}");
  background-size: cover;
  width: 27px;
  height: 27px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  will-change: transform;

  & img {
    will-change: transform;
    transition: filter 0.3s linear;
    transform: rotateY(180deg);
  }

  &:hover img {
    transform: scale(1.05) rotateY(180deg);
  }
  &:active img {
    transform: scale(1) rotateY(180deg);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover img {
        transform: rotateY(180deg);
      }
    `};
`;

export const PrevButtonAny = styled(PrevButton)``;

export const NextButton = styled(PrevButton)`
  & img {
    transition: filter 0.3s linear;
    transform: unset;
  }

  &:hover img {
    transform: scale(1.05);
  }
  &:active img {
    transform: scale(1);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover img {
        transform: unset;
      }
    `};
`;

export const NextButtonAny = styled(NextButton)``;
