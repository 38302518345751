import React from "react";
import btnImg1 from "../../img/miniIcons/Arrow1.png";
import btnImg2 from "../../img/miniIcons/Arrow2.png";

import * as S from "./Friends.styles";
import { roundProperty } from "../../helpers";
import friendFrame from "../../img/FriendsFrame.png";

const Friends = ({
  isFaculty,
  me,
  disableMove,
  isFetching,
  isModalOpen,
  friendsArray,
  sortedArray,
  moveToUserRoom,
  setFriendsArray,
  sortedProperty,
  getCharacterSum,
  leftNumber,
}) => {
  function handleScroll(e) {
    if (e.deltaY < 0) {
      handlePrevClick(false);
    } else {
      handleNextClick(false);
    }
  }

  function handleNextClick(multi) {
    leftNumber.current += multi ? 5 : 1;
    if (leftNumber.current > sortedArray.length - 5) {
      if (sortedArray.length > 5) {
        leftNumber.current = sortedArray.length - 5;
      } else {
        leftNumber.current = 0;
      }
    }
    const currentArray = sortedArray.filter(
      (elem, index) => index >= leftNumber.current,
    );
    if (currentArray.length > 5) {
      currentArray.length = 5;
    }
    setFriendsArray(currentArray);
  }

  function handlePrevClick(multi) {
    leftNumber.current -= multi ? 5 : 1;
    if (leftNumber.current < 0) {
      leftNumber.current = 0;
    }
    const currentArray = sortedArray.filter(
      (elem, index) => index >= leftNumber.current,
    );
    if (currentArray.length > 5) {
      currentArray.length = 5;
    }
    setFriendsArray(currentArray);
  }

  return (
    <S.Wrapper onWheel={handleScroll} onScroll={(e) => e.preventDefault()}>
      <S.FriendsFrames>
        {[0, 1, 2, 3, 4].map((user, i) => {
          return (
            <img
              key={i}
              src={friendFrame}
              alt={"рамка"}
              width={60}
              height={60}
            />
          );
        })}
      </S.FriendsFrames>

      <S.FriendsBlock>
        {isFaculty &&
          friendsArray.length > 0 &&
          friendsArray.map((user, i) => {
            return (
              <S.Friend
                key={user.email}
                onClick={
                  user.email !== me.email &&
                  !isFetching &&
                  !isModalOpen &&
                  !disableMove
                    ? moveToUserRoom.bind(null, user)
                    : null
                }
                isMe={user.email === me.email}
              ></S.Friend>
            );
          })}
      </S.FriendsBlock>

      <S.ArrowsWrapper>
        <S.PrevButton
          limit={leftNumber.current === 0 || !isFaculty}
          onClick={
            leftNumber.current === 0 || !isFaculty
              ? null
              : handlePrevClick.bind(null, false)
          }
        >
          <img width={15} height={15} src={btnImg1} alt="" />
        </S.PrevButton>
        <S.PrevButtonAny
          limit={leftNumber.current === 0 || !isFaculty}
          onClick={
            leftNumber.current === 0 || !isFaculty
              ? null
              : handlePrevClick.bind(null, true)
          }
        >
          <img width={15} height={15} src={btnImg2} alt="" />
        </S.PrevButtonAny>
      </S.ArrowsWrapper>
      <S.ArrowsWrapperRight>
        <S.NextButton
          limit={leftNumber.current >= sortedArray.length - 5 || !isFaculty}
          onClick={
            leftNumber.current >= sortedArray.length - 5 || !isFaculty
              ? null
              : handleNextClick.bind(null, false)
          }
        >
          <img width={15} height={15} src={btnImg1} alt="" />
        </S.NextButton>
        <S.NextButtonAny
          limit={leftNumber.current >= sortedArray.length - 5 || !isFaculty}
          onClick={
            leftNumber.current >= sortedArray.length - 5 || !isFaculty
              ? null
              : handleNextClick.bind(null, true)
          }
        >
          <img width={15} height={15} src={btnImg2} alt="" />
        </S.NextButtonAny>
      </S.ArrowsWrapperRight>
    </S.Wrapper>
  );
};

export default React.memo(Friends);
