import styled from "styled-components";

export const Wrapper = styled.div`
  width: 190px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 17px;
  left: 50%;
  transform: translateX(-50%);
  & span {
    font-family: "Bellota-Regular";
    color: #f7b471;
    font-size: ${(props) => (props.ownerRoom ? "16px" : "18px")};
    line-height: 22px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-shadow: 1px 1px #210326;
  }
`;
