import React from "react";

import * as S from "./ArenaInfo.styles";
import SumCharacter from "../../components/SumCharacter/SumCharacter";
import {
  Avatar,
  Button,
  ButtonValue,
  Lvl,
  Name,
} from "../../panels/Arena/Arena.styles";
import { CharactersInfo, Opponent } from "../ArenaFight/ArenaFight.styles";
import { Attack } from "./ArenaInfo.styles";

function ArenaInfo({
  user,
  isFetching,
  isModalOpen,
  getCharacterSum,
  isLoading,
  setIsHistoryOpen,
}) {
  return (
    <S.InfoWrapper>
      <div>
        <S.leftInfo>
          <div>Вызовы на дуэль</div>

          <S.TableInfo>
            <div>
              <S.TopName>Всего дуэлей</S.TopName>
              <ButtonValue>
                <div>{user.arena.countFights.toLocaleString("ru")}</div>
              </ButtonValue>
            </div>
          </S.TableInfo>

          <S.TableInfo>
            <div>
              <S.TopName>Победы</S.TopName>
              <ButtonValue>
                <div>{user.arena.countWins.toLocaleString("ru")}</div>
              </ButtonValue>
            </div>
          </S.TableInfo>

          <S.TableInfo>
            <div>
              <S.TopName>Процент побед</S.TopName>
              <ButtonValue>
                <div>
                  {user.arena.countWins > 0
                    ? parseFloat(
                        (
                          (user.arena.countWins / user.arena.countFights) *
                          100
                        ).toFixed(1),
                      )
                    : 0}
                  %
                </div>
              </ButtonValue>
            </div>
          </S.TableInfo>
        </S.leftInfo>
      </div>
      <div>
        <Opponent faculty={user.faculty}>
          <div>
            <Lvl>
              <span>{user.lvl} уровень</span>
            </Lvl>

            <Avatar>
              <img src={user.photo} width={100} height={100} alt={"аватар"} />
            </Avatar>

            <Name>
              <span>{user.orden?.tag ? `[${user.orden?.tag}] ` : ""}</span>
              <span>
                {user.nickName
                  ? user.nickName
                  : `${user.name?.firstName} ${user.name?.lastName}`}
              </span>
            </Name>

            <CharactersInfo>
              <ButtonValue width={110}>
                <div>
                  <SumCharacter
                    isRound={true}
                    getCharacterSum={getCharacterSum}
                  />
                </div>
              </ButtonValue>
            </CharactersInfo>

            <Attack>
              <Button
                width={110}
                disabled={isLoading || isFetching || isModalOpen}
                onClick={() => {
                  setIsHistoryOpen(true);
                }}
              >
                <div>История</div>
              </Button>
            </Attack>
          </div>
        </Opponent>
      </div>
      <div>
        <S.RightInfo>
          <div>Принятые дуэли</div>
          <S.TableInfo>
            <div>
              <S.TopName>Всего дуэлей</S.TopName>
              <ButtonValue>
                <div>{user.arena.countDef.toLocaleString("ru")}</div>
              </ButtonValue>
            </div>
          </S.TableInfo>

          <S.TableInfo>
            <div>
              <S.TopName>Победы</S.TopName>
              <ButtonValue>
                <div>{user.arena.countDefWins.toLocaleString("ru")}</div>
              </ButtonValue>
            </div>
          </S.TableInfo>

          <S.TableInfo>
            <div>
              <S.TopName>Процент побед</S.TopName>
              <ButtonValue>
                <div>
                  {user.arena.countDefWins > 0
                    ? parseFloat(
                        (
                          (user.arena.countDefWins / user.arena.countDef) *
                          100
                        ).toFixed(1),
                      )
                    : 0}
                  %
                </div>
              </ButtonValue>
            </div>
          </S.TableInfo>
        </S.RightInfo>
      </div>
    </S.InfoWrapper>
  );
}

export default React.memo(ArenaInfo);
