import styled from "styled-components";
import frameReward1 from "../../img/FrameReward1.png";
import frameReward2 from "../../img/FrameReward2.png";
import frameReward3 from "../../img/FrameReward3.png";
import imgMarker2 from "../../img/Marker2Small.png";
import imgMarker5 from "../../img/Marker5Small.png";
import imgMarker6 from "../../img/Marker6.png";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Content = styled.div`
  z-index: 1;
  position: relative;
  width: 689px;
  height: 468px;
  background-image: url("${(props) => props.back}");
  background-size: cover;
  font-family: "Bellota-Regular";
  & > div {
    top: 120px;
    left: 120px;
    width: 460px;
    height: 230px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;

export const RewardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => `${props.w}px`};
  height: ${(props) => `${props.w}px`};
  margin-bottom: ${(props) => `${props.bottom}px`};
  background-image: url("${(props) =>
    props.isDone ? frameReward3 : props.light ? frameReward1 : frameReward2}");
  background-size: cover;
  font-family: "Bellota-Regular";
  padding-top: 5px;
  filter: ${(props) => props.isLate && "brightness(0.7)"};
`;

export const DayPrizesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;

  & > div {
    border: 1px solid #281707;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding-bottom: 10px;
  }
`;

export const Day = styled.div`
  margin-top: -1px;
  width: 100%;
  background-image: ${(props) =>
    props.isActive
      ? `url("${imgMarker6}")`
      : props.isDone
        ? `url("${imgMarker2}")`
        : `url("${imgMarker5}")`};
  background-size: cover;

  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  z-index: -1;
`;
