import styled from "styled-components";
import imgMarker3 from "../../img/Marker3Dark.png";
import imgMarker2 from "../../img/Marker2Dark.png";
import imgMarker5 from "../../img/Marker5Dark.png";

export const Wrapper = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 420px;
  max-height: 420px;
  width: 100%;
  gap: 5px;
  color: #f7b471;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  border-radius: 3px;
  padding: 5px 0;
`;

export const Result = styled.div`
  color: ${(props) => (props.isWin ? "#E0FF87" : "#FF9999")};
`;
//
// export const Fight = styled.div`
//   width: 100%;
//   gap: 10px;
//   padding: 5px;
//   display: grid;
//   align-items: center;
//   grid-template-columns: repeat(5, minmax(0, 1fr));
//   line-height: 20px;
//
//   background-image: url("${(props) =>
//     props.isWin ? imgMarker2 : imgMarker3}");
//   background-size: contain;
//
//   justify-items: center;
//   & div {
//     overflow: hidden;
//     width: 100%;
//     justify-content: center;
//   }
// `;

export const Fight = styled.div`
  width: 100%;
  gap: 5px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  line-height: 20px;
  grid-template-columns: 1fr 1fr 0.6fr 0.6fr 0.6fr;
  min-height: 50px;
  max-height: 50px;
  background-image: url("${(props) =>
    props.isWin ? imgMarker2 : imgMarker3}");
  background-size: contain;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Fights = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  gap: 5px;
  padding: 0px 5px;
`;

export const Names = styled.div`
  width: 100%;
  gap: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.6fr 0.6fr 0.6fr;
  padding: 5px 15px 10px 10px;
  border-bottom: 2px solid #fc9800;
`;

export const Enemy = styled.div`
  display: flex;
  row-gap: 0;
  column-gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > span {
    overflow: hidden;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
