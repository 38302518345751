import React, { useEffect, useState } from "react";
import * as S from "./DamageShadow.styles";

function DamageShadow({ damageInfo }) {
  const [arrayDamage, setArrayDamage] = useState([]);

  useEffect(() => {
    if (damageInfo.length) {
      setArrayDamage([...damageInfo].reverse().slice(0, 6).reverse());
    }
  }, [damageInfo]);

  return arrayDamage.length
    ? arrayDamage.map(({ damage, damageBoss }, i) => {
        return (
          <S.Logger
            key={arrayDamage.length - i}
            opacity={
              arrayDamage.length - i <= 1
                ? 1
                : arrayDamage.length - i <= 2
                  ? 0.6
                  : 0.2
            }
          >
            <div>
              <span>
                Вам нанесли{" "}
                {damageBoss.isKrit
                  ? "критический урон " + damageBoss.value.toLocaleString("ru")
                  : damageBoss.value.toLocaleString("ru") + " урона"}
              </span>
              <span>
                Вы{" "}
                {damage.evasion
                  ? "промахнулись и нанесли " +
                    damage.value.toLocaleString("ru")
                  : damage.isKrit
                    ? "нанесли критический урон " +
                      damage.value.toLocaleString("ru")
                    : "нанесли " + damage.value.toLocaleString("ru") + " урона"}
              </span>
            </div>
          </S.Logger>
        );
      })
    : null;
}

export default React.memo(DamageShadow);
