import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Bellota-Regular";
  display: flex;
  justify-content: center;
  position: absolute;
  top: 17px;
  width: 200px;
  left: 72px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #f7b471;
    text-shadow: 1px 1px #210326;
  }
`;

export const ButtonsWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;

  & > button {
    display: flex;
    gap: 5px;
    line-height: 15px;
  }
`;
