import styled from "styled-components";

export const Wrapper = styled.div``;

export const EnergyProgress = styled.div`
  position: absolute;
  top: 42px;
  right: 90px;
  display: flex;
  font-family: "Bellota-Regular";
  width: fit-content;
  cursor: help;
  &:after {
    width: max-content;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    content: "Энергия: ${(props) => props.energy}";
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }
  & img {
    clip-path: polygon(
      0% 0%,
      ${(props) => props.value}%0%,
      ${(props) => props.value}% 50%,
      ${(props) => props.value}% 100%,
      0% 100%
    );
    transition: all 0.5s ease-in-out;
  }
  & span {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    font-size: 12px;
    line-height: 21px;
    font-weight: 600;
    color: #e3cd32;
    text-shadow: 1px 1px #210326;
  }
`;

export const Icon = styled.img`
  position: absolute;
  right: 78px;
  top: 36px;
`;

export const EnergyTime = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  position: absolute;
  left: 820px;
  top: 42px;
  font-family: "Bellota-Regular";
  color: #f7b471;
  font-size: 14px;
  line-height: 21px;
  text-shadow: 1px 1px #210326;
`;
