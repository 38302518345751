import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  gap: 5px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Info = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #fdbb54;
`;

export const ElementsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 1px #281707 inset;
  border: 2px solid #e98101;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 15px;
`;

export const Element = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 13px;
`;

export const Name = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  color: #fdbb54;
  padding-bottom: 15px;
`;

export const AddButton = styled.div`
  position: absolute;
  bottom: -5px;
  transform: translateY(50%);
  & > div > div {
    padding: 4px 10px;
  }
`;

export const CollectionElem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  font-size: 13px;
  line-height: 1.5;
  color: #fdbb54;
  word-spacing: -2px;

  & > span {
    min-height: 3px;
  }
`;
