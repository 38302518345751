import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./Arena.styles";
import Popup from "../../components/Popup/Popup";
import {
  getMultipleRandom,
  roundPropertyLow,
  secondsToDhm,
  secondsToHm,
} from "../../helpers";
import ArenaHistory from "../../components/ArenaHistory/ArenaHistory";
import { CentredWrapper, Close } from "../../App.styles";
import { grindData, tournamentPrizes } from "../../info/data";
import { TopPart } from "../../components/BossInterface/BossInterface.styles";
import { MenuRight, MenuElemRight } from "../Home/Home.styles";
import helperIcon from "../../img/menuIcons/Helper.png";
import frame6 from "../../img/Frame6.png";
import { createPortal } from "react-dom";
import imgTop from "../../img/boss/TopPart.png";
import {
  Avatar,
  MeTop,
  Place,
  TopButton,
  Tops,
  UserName,
} from "../Tops/Tops.styles";
import ArenaFight from "../../components/ArenaFight/ArenaFight";
import ArenaInfo from "../../components/ArenaInfo/ArenaInfo";
import ArenaMenu from "../../components/ArenaMenu/ArenaMenu";
import frame9 from "../../img/Frame9.png";
import Reward from "../../components/Reward/Reward";
import { Button } from "../../components/ActiveBoss/ActiveBoss.styles";
import tournamentIcon from "../../img/menuIcons/ArenaTournament.png";
import imgPlace1 from "../../img/Place1.png";
import imgPlace2 from "../../img/Place2.png";
import imgPlace3 from "../../img/Place3.png";
import {
  ColumnBottom,
  ColumnLeft,
  ColumnRight,
  ColumnRightFull,
  Content,
  Header,
  Names,
  PriceCount,
  PrizesTop,
  Top,
} from "../GameRoom/GameRoom.styles";
import ArenaTournament from "../../components/ArenaTournament/ArenaTournament";
import frame3 from "../../img/Frame3.png";
import ArenaCharacteristics from "../../components/ArenaCharacteristics/ArenaCharacteristics";
import { Name } from "./Arena.styles";
import NickName from "../../components/NickName/NickName";

const iconTournament = tournamentIcon;

const places = [imgPlace1, imgPlace2, imgPlace3];

const goldCount = [0, 100, 500];

function Arena({
  user,
  allUsers,
  app,
  setUser,
  setIsFetching,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  serverTime,
  setDisableMove,
  changeOptionsLocation,
  setModalError,
  getCharacterSum,
  moveToUserRoom,
  bridge,
  admin,
  setAdmin,
  setAdditionalPanel,
  warOrdenTask,
  warOrdenDayTask,
  ordenBonus,
}) {
  const [tournamentTimeString, setTournamentTimeString] = React.useState("");
  const [registerTimeString, setRegisterTimeString] = React.useState("");
  const [activeTournament, setActiveTournament] = React.useState(false);
  const [activeRegister, setActiveRegister] = React.useState(false);
  const [tournamentOpen, setTournamentOpen] = React.useState(false);
  const [characOpen, setCharacOpen] = React.useState(false);
  const [endTournamentOpen, setEndTournamentOpen] = React.useState(false);
  const [tournamentUsers, setTournamentUsers] = useState([]);
  const [meTop, setMeTop] = useState({ place: -1 });
  const [topUsers, setTopUsers] = useState([]);

  const [maxHourFight, setMaxHourFight] = useState(
    user.arena.maxHourFight *
      ((user.helpers?.arena || 0) >= serverTime ? 24 : 1)
  );
  const [helperOpen, setHelperOpen] = useState(false);
  const [timeHelp, setTimeHelp] = useState(null);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chooseUsers, setChooseUsers] = useState([]);
  const [usersInfo, setUsersInfo] = useState([]);
  const [timeToNext, setTimeToNext] = useState("00ч:00м");
  const intervalRef = useRef(null);

  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("arena");

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setMaxHourFight(
      user.arena.maxHourFight *
        ((user.helpers?.arena || 0) >= serverTime ? 24 : 1)
    );

    const mm = (59 - new Date(serverTime).getMinutes()) * 60;
    const ss = 60 - new Date(serverTime).getSeconds();

    if ((user.helpers?.arena || 0) >= serverTime) {
      const d1 = new Date(serverTime);
      const offsetOld = d1.getTimezoneOffset();
      const offsetMoscow = -180;
      const d2 = new Date(
        serverTime + offsetOld * 60 * 1000 - offsetMoscow * 60 * 1000
      );

      const d3 = new Date(
        d2.getFullYear(),
        d2.getMonth(),
        d2.getDate(),
        23,
        59,
        59
      );

      setTimeToNext(secondsToHm((d3.getTime() - d2.getTime()) / 1000));

      setTimeHelp(
        secondsToDhm(((user.helpers?.arena || 0) - serverTime) / 1000)
      );
    } else {
      setTimeToNext(secondsToHm(mm + ss));
      setTimeHelp(null);
    }

    if (
      serverTime <
        admin.arenaTournament.startTime +
          admin.arenaTournament.time * 3600000 &&
      serverTime > admin.arenaTournament.startTime
    ) {
      setActiveTournament(true);
      setActiveRegister(false);
      setTournamentTimeString(
        secondsToHm(
          (admin.arenaTournament.startTime +
            admin.arenaTournament.time * 3600000 -
            serverTime) /
            1000
        )
      );
    } else if (
      serverTime < admin.arenaTournament.startTime &&
      serverTime > admin.arenaTournament.registerTime
    ) {
      setActiveRegister(true);
      setRegisterTimeString(
        secondsToHm((admin.arenaTournament.startTime - serverTime) / 1000)
      );
    }
  }, [serverTime, user]);

  useEffect(() => {
    const topsInfo = tournamentUsers
      .filter((elem) => elem.maxPoints)
      .sort((a, b) => {
        if (b.maxPoints === a.maxPoints) {
          if (b.rate === a.rate) {
            return a.count - b.count;
          }
          return a.rate - b.rate;
        }

        return b.maxPoints - a.maxPoints;
      });

    setTopUsers(topsInfo);

    const index = topsInfo.findIndex((elem, i) => user.email == elem.email);
    if (index > -1) {
      setMeTop({
        place: index,
      });
    } else {
      setMeTop({
        place: -1,
      });
    }
  }, [tournamentUsers]);

  useEffect(() => {
    if (admin) {
      const arenaUsers = [];

      Object.entries({
        ...admin.arenaTournament.users,
      }).forEach((elem) => {
        const userLocal = allUsers.find((el) => el.email == elem[0]);

        if (userLocal) {
          arenaUsers.push({
            ...elem[1],
            email: userLocal?.email,
            photo: userLocal?.photo,
            name: userLocal?.name,
            nickName: userLocal?.nickName,
            orden: userLocal?.orden,
          });
        }
      });

      setTournamentUsers(arenaUsers);
    }
  }, [admin, allUsers]);

  function changeHelperOpen(open) {
    setHelperOpen(open);
  }

  function changeTournamentOpen() {
    setTournamentOpen((prevState) => !prevState);
  }

  useEffect(() => {
    if (tournamentOpen) {
      updateTop();
    }
  }, [tournamentOpen]);

  function updateTop() {
    if (activeTournament) {
      setIsFetching(true);
      app
        .service("admin")
        .get(user.admin, {
          query: {
            $select: ["arenaTournament"],
          },
        })
        .then((adminData) => {
          setAdmin((prev) => ({ ...prev, ...adminData }));
          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    }
  }

  function buyHelper({ days, price }) {
    setIsFetching(true);
    setIsLoading(true);

    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          helpersArena: days,
          g: price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
        }
        setIsLoading(false);
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsFetching(false);
      });
  }

  const swapOpponents = useCallback(() => {
    setChooseUsers(getMultipleRandom(usersInfo, 3));
  }, [usersInfo]);

  const setPlayerInfo = useCallback((obj) => {
    const scales = {};
    const characteristic = obj.characteristic;
    const grindValue = grindData["book"].bonus[obj.grind["book"] - 1] / 100;
    obj.damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const player = {
      hp: Math.floor(
        Object.values(characteristic.health).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * 5 * scales.health;
          }
          return acc + cur * 5;
        }, 0)
      ),
    };
    Object.entries(characteristic).forEach((obj) => {
      player[obj[0]] = Math.floor(
        Object.values(obj[1]).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * scales[obj[0]];
          }
          return acc + cur;
        }, 0)
      );
    });
    return player;
  }, []);

  function changeCharac() {
    setCharacOpen((prev) => !prev);
  }

  function startTournament() {
    if (!activeTournament) {
      setModalError("Турнир закончился");
      console.log("Турнир закончился");
    } else {
      if (user.arenaTournament.count >= 3) {
        setModalError("Лимит на участие в турнире");
        console.log("Лимит на участие в турнире");
      } else {
        if (user.gold >= goldCount[user.arenaTournament.count]) {
          const opponentsAll = tournamentUsers.filter(
            (elem) => elem.email !== user.email
          );

          const opponents = getMultipleRandom(opponentsAll, 40);

          const opponentsEmail = opponents.map((el) => el.email);

          if (opponents.length >= 4) {
            const me = tournamentUsers.find((elem) => elem.email == user.email);
            const enemy = opponents[0];

            setIsFetching(true);
            app
              .service("users")
              .patch(
                user._id,
                {
                  "arenaTournament.opponents": opponentsEmail,
                  "arenaTournament.essence": 1000000000,
                  "arenaTournament.characteristics": {
                    health: 100,
                    force: 100,
                    power: 100,
                    protection: 100,
                    accuracy: 100,
                    evasion: 100,
                    speed: 100,
                  },
                  "arenaTournament.gamesDone": 0,
                  "arenaTournament.points": 0,
                  "arenaTournament.isStartTournament": true,
                  "arenaTournament.isOver": false,
                  "arenaTournament.me": {
                    ...me,
                    fullHp: me.health,
                  },
                  "arenaTournament.enemy": {
                    ...enemy,
                    fullHp: enemy.health,
                  },
                  $inc: {
                    "arenaTournament.count": 1,
                    gold: -goldCount?.[user.arenaTournament.count],
                  },
                  field: serverTime,
                },
                {
                  query: {
                    $select: ["_id", "email", "arenaTournament", "gold"],
                  },
                }
              )
              .then((data) => {
                setTournamentOpen(false);
                setUser((prev) => ({ ...prev, ...data }));
                setIsFetching(false);
              })
              .catch((e) => {
                setModalError(e);
                console.log(e);
                setIsFetching(false);
              });
          } else {
            setModalError("Недостаточно участников");
            console.log("Недостаточно участников");
          }
        } else {
          setModalError("Недостаточно золота");
          console.log("Недостаточно золота");
        }
      }
    }
  }

  function endTournament() {
    setIsFetching(true);
    app
      .service("admin")
      .patch(
        user.admin,
        {
          [`arenaTournament.users.${user.email}.maxPoints`]:
            user.arenaTournament.maxPoints,
          [`arenaTournament.users.${user.email}.count`]:
            user.arenaTournament.count,
        },
        {
          query: {
            $select: ["arenaTournament"],
          },
        }
      )
      .then((adminData) => {
        setAdmin((prev) => ({ ...prev, ...adminData }));
        setEndTournamentOpen(false);
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  return (
    <React.Fragment>
      <TopPart>
        <img src={imgTop} alt="интерфейс" />
      </TopPart>

      <CentredWrapper>
        <S.ArenaWrapper>
          {user.arena.isOver && isHistoryOpen ? (
            <>
              <ArenaHistory
                moveToUserRoom={moveToUserRoom}
                allUsers={allUsers}
                id={user._id}
                history={user.arenaHistory}
                setIsOpen={setIsHistoryOpen}
              />

              <TopButton>
                <S.Button
                  width={130}
                  onClick={() => {
                    setIsHistoryOpen(false);
                  }}
                >
                  <div>Личная карточка</div>
                </S.Button>
              </TopButton>
            </>
          ) : user.arena.isOver && isInfoOpen ? (
            <>
              <ArenaInfo
                user={user}
                isFetching={isFetching}
                isModalOpen={isModalOpen}
                getCharacterSum={getCharacterSum}
                isLoading={isLoading}
                setIsInfoOpen={setIsInfoOpen}
                chooseUsers={chooseUsers}
                setIsHistoryOpen={setIsHistoryOpen}
              />
              <TopButton>
                <S.Button
                  width={130}
                  onClick={() => {
                    setIsInfoOpen(false);
                  }}
                >
                  <div>Назад к дуэлям</div>
                </S.Button>
              </TopButton>
            </>
          ) : user.arena.isOver &&
            user.arenaTournament.isOver &&
            !isHistoryOpen &&
            !isInfoOpen ? (
            <ArenaMenu
              app={app}
              user={user}
              setUser={setUser}
              serverTime={serverTime}
              isFetching={isFetching}
              isModalOpen={isModalOpen}
              getCharacterSum={getCharacterSum}
              isLoading={isLoading}
              chooseUsers={chooseUsers}
              timeHelp={timeHelp}
              maxHourFight={maxHourFight}
              setIsInfoOpen={setIsInfoOpen}
              timeToNext={timeToNext}
              setPlayerInfo={setPlayerInfo}
              setDisableMove={setDisableMove}
              setIsLoading={setIsLoading}
              setIsFetching={setIsFetching}
              setModalError={setModalError}
              allUsers={allUsers}
              setChooseUsers={setChooseUsers}
              swapOpponents={swapOpponents}
              usersInfo={usersInfo}
              setUsersInfo={setUsersInfo}
              moveToUserRoom={moveToUserRoom}
            />
          ) : !user.arenaTournament.isOver ? (
            <ArenaTournament
              user={user}
              app={app}
              setUser={setUser}
              isFetching={isFetching}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              serverTime={serverTime}
              setModalError={setModalError}
              isLoading={isLoading}
              setDisableMove={setDisableMove}
              setIsFetching={setIsFetching}
              setIsLoading={setIsLoading}
              tournamentUsers={tournamentUsers}
              setEndTournamentOpen={setEndTournamentOpen}
              setAdditionalPanel={setAdditionalPanel}
              setAdmin={setAdmin}
            />
          ) : (
            <ArenaFight
              user={user}
              app={app}
              setUser={setUser}
              isFetching={isFetching}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              serverTime={serverTime}
              setModalError={setModalError}
              isLoading={isLoading}
              setDisableMove={setDisableMove}
              setIsFetching={setIsFetching}
              setIsLoading={setIsLoading}
              swapOpponents={swapOpponents}
              warOrdenTask={warOrdenTask}
              warOrdenDayTask={warOrdenDayTask}
              ordenBonus={ordenBonus}
            />
          )}
        </S.ArenaWrapper>
      </CentredWrapper>
      {domReady
        ? createPortal(
            user.arena.isOver && (
              <MenuRight>
                <MenuElemRight name={"Резервация"}>
                  <div onClick={changeHelperOpen.bind(null, true)} />
                  <img
                    width={80}
                    height={80}
                    src={helperIcon}
                    alt={"резервация"}
                  />
                </MenuElemRight>
                {((activeTournament && user.arenaTournament.isRegister) ||
                  activeRegister) && (
                  <MenuElemRight name={"Турнир"}>
                    <div onClick={changeTournamentOpen.bind(null, true)} />
                    <img
                      width={80}
                      height={80}
                      src={iconTournament}
                      alt={"турнир"}
                    />
                  </MenuElemRight>
                )}
              </MenuRight>
            ),
            document.querySelector("#menuRight")
          )
        : null}

      <Popup
        isOpen={tournamentOpen}
        setIsOpen={setTournamentOpen}
        w={768}
        h={557}
        back={frame9}
      >
        <Close
          onClick={() => {
            setTournamentOpen(false);
          }}
        />
        {(activeTournament || activeRegister) && tournamentOpen && (
          <Content>
            <div>
              <Header>Турнир Дуэлей</Header>
              <ColumnLeft>
                {activeRegister ? (
                  <>
                    <span>До начала: {registerTimeString}</span>
                    <span>
                      Для участия в турнире необходимо заблаговременно подать
                      заявку в течении двух дней до его начала
                    </span>
                    <span>
                      Для подачи заявки необходимо распределить характеристики
                      за ограниченное количество эссенций
                    </span>
                    <span>
                      Во время турнира между попытками перераспределить
                      характеристики не предоставляется возможным
                    </span>
                  </>
                ) : (
                  <>
                    <span>До конца: {tournamentTimeString}</span>
                    <span>
                      Цель турнира набрать наибольшее количество очков. В
                      турнире можно принять участие не более трёх раз
                    </span>

                    <span>
                      В ходе турнира необходимо сразиться со случайными 40
                      противниками
                    </span>

                    <span>
                      Очки суммируются за все проведенные дуэли и зависят от
                      характеристик противника
                    </span>

                    <span>
                      Приоритет при равных очках отдаётся игроку с меньшим
                      количеством попыток или наобильшими характеристиками
                    </span>
                  </>
                )}
              </ColumnLeft>

              <ColumnBottom height={74}>
                {activeRegister ? (
                  user.arenaTournament.isRegister ? (
                    <span>Вы уже записаны</span>
                  ) : (
                    <>
                      <PriceCount>
                        <span>Принять участие в турнире</span>
                      </PriceCount>
                      <Button
                        minW={130}
                        onClick={!isFetching ? changeCharac : null}
                      >
                        <div>Записаться</div>
                      </Button>
                    </>
                  )
                ) : user.arenaTournament.isStartTournament ? (
                  <span>Вы уже участвуете</span>
                ) : user.arenaTournament.count >= 3 ? (
                  <span>Лимит на участие</span>
                ) : (
                  <>
                    <PriceCount>
                      <span>Попытка {user.arenaTournament.count + 1}:</span>
                      {!user.arenaTournament.count ? (
                        <span>Бесплатно</span>
                      ) : (
                        <Reward
                          name={"gold"}
                          count={`взнос ${
                            goldCount?.[user.arenaTournament.count]
                          }`}
                          w={13}
                          button={true}
                        />
                      )}
                    </PriceCount>
                    <Button
                      minW={130}
                      onClick={!isFetching ? startTournament : null}
                    >
                      <div>Начать</div>
                    </Button>
                  </>
                )}
              </ColumnBottom>
            </div>
            <div>
              {activeRegister ? (
                <>
                  <Header>Участники</Header>

                  <ColumnRightFull>
                    <Names>
                      <span>N</span>
                      <span>Игрок</span>
                      <span>Ник</span>
                      <span>Уровень</span>
                      <span>Хар-ки</span>
                    </Names>

                    <Tops>
                      {tournamentUsers.length > 0 &&
                        tournamentUsers.map((localUser, i) => {
                          return (
                            <Top
                              isMe={localUser.email === user.email}
                              key={localUser.email}
                            >
                              <Place>{i + 1}</Place>
                              <Avatar
                                isMe={localUser.email === user.email}
                                onClick={
                                  localUser.email !== user.email
                                    ? moveToUserRoom.bind(null, localUser)
                                    : null
                                }
                              >
                                <img
                                  src={localUser.photo}
                                  width={35}
                                  height={35}
                                  alt="аватар"
                                />
                              </Avatar>
                              <UserName>
                                <span>
                                  {localUser.orden?.tag
                                    ? `[${localUser.orden?.tag}] `
                                    : ""}
                                </span>
                                <span>
                                  {localUser.nickName
                                    ? localUser.nickName
                                    : `${localUser.name?.firstName} ${localUser.name?.lastName}`}
                                </span>
                              </UserName>
                              <span>{localUser.lvl}</span>

                              <span>
                                {roundPropertyLow(
                                  localUser.rate
                                ).toLocaleString("ru")}
                              </span>
                            </Top>
                          );
                        })}
                    </Tops>
                  </ColumnRightFull>
                </>
              ) : (
                <>
                  <Header>Рейтинг</Header>
                  <ColumnRight>
                    <Names>
                      <span>Место</span>
                      <span>Игрок</span>
                      <span>Ник</span>
                      <span>Очки</span>
                      <span>Награда</span>
                    </Names>

                    <Tops>
                      {topUsers.length > 0 &&
                        topUsers.map((localUser, i) => {
                          return (
                            <Top
                              isMe={localUser.email === user.email}
                              isWin={i < 3}
                              key={localUser.email}
                            >
                              <Place>
                                {i < 3 ? (
                                  <img
                                    src={places[i]}
                                    width={38}
                                    height={38}
                                    alt="место"
                                  />
                                ) : (
                                  i + 1
                                )}
                              </Place>
                              <Avatar
                                isMe={localUser.email === user.email}
                                onClick={
                                  localUser.email !== user.email
                                    ? moveToUserRoom.bind(null, localUser)
                                    : null
                                }
                              >
                                <img
                                  src={localUser.photo}
                                  width={35}
                                  height={35}
                                  alt="аватар"
                                />
                              </Avatar>
                              <UserName>
                                <span>
                                  {localUser.orden?.tag
                                    ? `[${localUser.orden?.tag}] `
                                    : ""}
                                </span>
                                <span>
                                  {localUser.nickName
                                    ? localUser.nickName
                                    : `${localUser.name?.firstName} ${localUser.name?.lastName}`}
                                </span>
                              </UserName>
                              <span>
                                {roundPropertyLow(
                                  localUser.maxPoints
                                ).toLocaleString("ru")}
                              </span>

                              <PrizesTop>
                                {/*{i === 0 && (*/}
                                {/*    <Reward*/}
                                {/*        count={"1"}*/}
                                {/*        name={"rareClothes"}*/}
                                {/*        button={true}*/}
                                {/*        w={15}*/}
                                {/*        g={4}*/}
                                {/*    />*/}
                                {/*)}*/}

                                {/*{i === 1 && (*/}
                                {/*    <Reward*/}
                                {/*        count={"2"}*/}
                                {/*        name={"commonClothes"}*/}
                                {/*        button={true}*/}
                                {/*        w={15}*/}
                                {/*        g={4}*/}
                                {/*    />*/}
                                {/*)}*/}

                                {/*{i === 2 && (*/}
                                {/*    <Reward*/}
                                {/*        count={"1"}*/}
                                {/*        name={"commonClothes"}*/}
                                {/*        button={true}*/}
                                {/*        w={15}*/}
                                {/*        g={4}*/}
                                {/*    />*/}
                                {/*)}*/}

                                {i < 10 ? (
                                  <Reward
                                    count={tournamentPrizes[i].gold}
                                    name={"gold"}
                                    button={true}
                                    w={15}
                                  />
                                ) : (
                                  "—"
                                )}
                              </PrizesTop>
                            </Top>
                          );
                        })}
                    </Tops>
                  </ColumnRight>
                  <MeTop>
                    <Top meTop={true}>
                      <Place>
                        {meTop.place > -1 ? (
                          meTop.place < 3 ? (
                            <img
                              src={places[meTop.place]}
                              width={38}
                              height={38}
                              alt="место"
                            />
                          ) : meTop.place >= 100 ? (
                            "100+"
                          ) : (
                            meTop.place + 1
                          )
                        ) : (
                          "—"
                        )}
                      </Place>
                      <Avatar isMe={true}>
                        <img
                          src={user.photo}
                          width={35}
                          height={35}
                          alt="аватар"
                        />
                      </Avatar>
                      <UserName>
                        <span>
                          {user.orden?.tag ? `[${user.orden?.tag}] ` : ""}
                        </span>
                        <span>
                          {user.nickName
                            ? user.nickName
                            : `${user.name?.firstName} ${user.name?.lastName}`}
                        </span>
                      </UserName>
                      <span>
                        {roundPropertyLow(
                          user.arenaTournament.maxPoints
                        ).toLocaleString("ru")}
                      </span>

                      <PrizesTop>
                        {/*{meTop.place === 0 && (*/}
                        {/*    <Reward*/}
                        {/*        count={"1"}*/}
                        {/*        name={"rareClothes"}*/}
                        {/*        button={true}*/}
                        {/*        w={15}*/}
                        {/*        g={4}*/}
                        {/*    />*/}
                        {/*)}*/}

                        {/*{meTop.place === 1 && (*/}
                        {/*    <Reward*/}
                        {/*        count={"2"}*/}
                        {/*        name={"commonClothes"}*/}
                        {/*        button={true}*/}
                        {/*        w={15}*/}
                        {/*        g={4}*/}
                        {/*    />*/}
                        {/*)}*/}

                        {/*{meTop.place === 2 && (*/}
                        {/*    <Reward*/}
                        {/*        count={"2"}*/}
                        {/*        name={"commonClothes"}*/}
                        {/*        button={true}*/}
                        {/*        w={15}*/}
                        {/*        g={4}*/}
                        {/*    />*/}
                        {/*)}*/}

                        {meTop.place >= 0 && meTop.place < 10 ? (
                          <Reward
                            count={tournamentPrizes[meTop.place].gold}
                            name={"gold"}
                            button={true}
                            w={15}
                          />
                        ) : (
                          "—"
                        )}
                      </PrizesTop>
                    </Top>
                  </MeTop>
                </>
              )}
            </div>
          </Content>
        )}
      </Popup>

      <Popup
        isOpen={characOpen}
        setIsOpen={changeCharac}
        w={768}
        h={557}
        back={frame9}
      >
        <Close onClick={changeCharac} />
        {characOpen && (
          <ArenaCharacteristics
            activeRegister={activeRegister}
            isOpen={characOpen}
            setIsOpen={setCharacOpen}
            user={user}
            serverTime={serverTime}
            setModalError={setModalError}
            setIsFetching={setIsFetching}
            isFetching={isFetching}
            setUser={setUser}
            setAdmin={setAdmin}
            app={app}
          />
        )}
      </Popup>

      <Popup isOpen={endTournamentOpen} w={317} h={217} back={frame3}>
        <b>Результаты</b>
        <span>Набрано очков: {user.arenaTournament.points}</span>
        <span>
          Лучший результат:{" "}
          {user.arenaTournament.points > user.arenaTournament.maxPoints
            ? user.arenaTournament.points
            : user.arenaTournament.maxPoints}
        </span>
        <Button width={100} onClick={!isFetching ? endTournament : null}>
          <div>Завершить</div>
        </Button>
      </Popup>

      <Popup
        isOpen={helperOpen}
        setIsOpen={setHelperOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeHelperOpen.bind(null, false)} />
        <b>Резервация Дуэльной комнаты</b>
        <span>Доступны сразу все лимиты (240)</span>
        <span>Лимиты сбрасываются в конце игрового дня</span>
        {timeHelp && (
          <>
            <span>Оставшееся время резервации:</span>
            <span>{timeHelp}</span>
          </>
        )}
        <S.BottomPrice>
          {timeHelp ? (
            <span>Желаете продлить резервацию?</span>
          ) : (
            <span>Доступные варианты для резервации:</span>
          )}
          <S.BottomPrices>
            <div>
              <span>3 суток</span>
              <S.Button
                width={100}
                onClick={buyHelper.bind(null, { days: 3, price: 10 })}
              >
                <div>10 голосов</div>
              </S.Button>
            </div>
            <div>
              <span>7 суток</span>
              <S.Button
                width={100}
                onClick={buyHelper.bind(null, { days: 7, price: 15 })}
              >
                <div>15 голосов</div>
              </S.Button>
            </div>
            <div>
              <span>30 суток</span>
              <S.Button
                width={100}
                onClick={buyHelper.bind(null, { days: 30, price: 50 })}
              >
                <div>50 голосов</div>
              </S.Button>
            </div>
          </S.BottomPrices>
        </S.BottomPrice>
      </Popup>
    </React.Fragment>
  );
}

export default React.memo(Arena);
