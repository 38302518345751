import React, { useEffect, useState } from "react";
import { roundProperty } from "../../helpers";

function SumCharacters({ users, ordenMembers }) {
  const [characters, setCharacters] = useState(0);

  useEffect(() => {
    if (users.length > 0 && ordenMembers.length > 0) {
      const members = users.filter((elem) =>
        ordenMembers.map((elem) => elem.email).includes(elem.email),
      );
      setCharacters(
        Math.floor(members.reduce((acc, user) => acc + user.allCharac, 0)),
      );
    } else {
      setCharacters(0);
    }
  }, [users, ordenMembers]);

  return <>{roundProperty(characters).toLocaleString("ru")}</>;
}

export default React.memo(SumCharacters);
