import styled, { css, keyframes } from "styled-components";

const show = (bottom) => keyframes`
  from {
    transform: translateY( ${bottom ? 70 : -70}px) scale(1.1) rotateX(${
      bottom ? -40 : 40
    }deg) ;
  }

  to {
    transform: translateY(0px) scale(1) rotateX(0deg);;
  }
`;

export const Card = styled.img`
  filter: drop-shadow(1px 2px 3px black);
  position: absolute;
  animation: ${(props) => show(props.isBottom)} 0.5s ease-out;
  top: ${(props) => props.x || 0}px;
  left: ${(props) => props.y || 0}px;
  z-index: ${(props) => props.z};
  display: ${(props) => (props.loaded ? "block" : "none")};
  ${(props) =>
    props.isBottom &&
    css`
      left: ${(props) => props.x || 0}px;
      top: ${(props) => -props.y - 145 || 0}px;
    `}
  ${(props) =>
    props.isRight &&
    css`
      right: 0;
      left: ${(props) => -props.x - 100 || 0}px;
      top: ${(props) => props.y || 0}px;
    `};
`;
