import styled from "styled-components";

import infoLeft from "../../img/arena/InfoLeft.png";
import infoRight from "../../img/arena/InfoRight.png";
import imgMarker2 from "../../img/Marker2Small.png";
import imgMarker5 from "../../img/Marker5Small.png";

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 370px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  & > div:nth-child(2) > div {
    margin-top: 31px;
    height: 332px;
  }
`;

export const leftInfo = styled.div`
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  padding: 80px 30px 30px 30px;
  width: 100%;
  height: 367px;
  color: #281707;

  background-image: url("${infoLeft}");
  background-size: contain;
  z-index: 1;
`;

export const RightInfo = styled(leftInfo)`
  background-image: url("${infoRight}");
`;

export const TableInfo = styled.div`
  width: 100%;
  border: 1px solid #281707;
  border-radius: 3px;
  padding-bottom: 6px;
  z-index: 1;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
  }
`;

export const TopName = styled.div`
  margin-top: -1px;
  width: 100%;
  padding: 4px;
  background-image: ${(props) =>
    props.isDone ? `url("${imgMarker2}")` : `url("${imgMarker5}")`};
  background-size: contain;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  z-index: -1;
`;

export const Attack = styled.div`
  position: absolute;
  bottom: 25px;
`;

export const CharactersInfo = styled.div`
  position: absolute;
  bottom: 95px;
`;
