import styled from "styled-components";
import imgMarker2 from "../../img/Marker2Dark.png";
import imgMarker5 from "../../img/Marker5Dark.png";

export const ChatPopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f7b471;
  background-color: rgba(0, 0, 0, 0.6);
  transition: ${(props) => (props.isMove ? "none" : "all 0.3s ease")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? (props.z ? props.z : "1") : "-1")};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => (props.w ? props.w : 250)}px;
  height: ${(props) => (props.h ? `${props.h}px` : "auto")};
  background-image: url(${(props) => (props.back ? props.back : "")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.justify && props.justify};
    gap: 8px;
    text-align: center;
    align-items: center;
    height: 100%;
    font-family: "Bellota-Regular";
    font-size: 14px;

    & > b {
      font-size: 15px;
    }
  }
`;

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  position: absolute;
  top: 1px;
  left: 9px;
  width: calc(100% - 15px);
  height: calc(100% - 36px);
  gap: 2px;
  text-align: start;
`;

export const Message = styled.div`
  backface-visibility: hidden;
  will-change: transform;
  margin-right: 2px;
  color: #f8e5ca;
  font-family: "Bellota-Regular";
  font-size: 11px;
  line-height: 14px;
  & > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export const TagChat = styled.span`
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};
  color: ${(props) => (props.isMe ? "#2dc22d" : "#f7b471")};
  font-weight: 600;
`;

export const Time = styled.span`
  color: #f7b471;
  font-weight: 400;
`;

export const Arrow = styled.span`
  & > svg {
    height: 7px;
  }
  & path {
    stroke: ${(props) => (props.isMe ? "#2dc22d" : "#f7b471")};
  }
`;

export const Delete = styled.span`
  cursor: pointer;
  & > svg {
    height: 7px;
  }
`;

export const Tag = styled.div`
  display: flex;
  min-width: 80px;
  max-width: 80px;
  height: 20px;
  align-items: center;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffcf82;
    font-size: 11px;
    line-height: 14px;
    font-weight: 600;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 5px;
  left: 68px;
`;

export const OpenChat = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 8px;
  left: 3px;
  width: 27px;
  height: 27px;

  & img {
    backface-visibility: hidden;
    will-change: transform;
    transition: 0.2s ease;
  }
  &:hover img {
    filter: brightness(1.2);
  }
  &:active img {
    filter: brightness(0.9);
  }
`;

export const InputWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 11px;
  left: 35px;
  height: 20px;
  & input {
    font-size: 11px;
    line-height: 14px;
    width: ${(props) => (props.isTag ? "205px" : "285px")};
    height: 20px;
    border: none !important;
    background-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
    font-family: "Bellota-Regular";
    color: #f8e5ca !important;
    -moz-appearance: textfield !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    ::placeholder {
      color: rgba(248, 229, 202, 0.7) !important;
      text-transform: none;
    }
  }
`;

export const CloseTag = styled.div`
  margin: 0 2px;
  cursor: pointer;
  display: flex;
  & > svg {
    width: 8px;
  }
`;

export const TagLine = styled.div`
  margin: 0 2px;
  display: flex;
  & > svg {
    height: 14px;
  }
`;

export const Send = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  backface-visibility: hidden;
  will-change: transform;
  & img {
    transition: 0.2s ease;
  }
  &:hover img {
    filter: brightness(1.2);
  }
  &:active img {
    filter: brightness(0.9);
  }
`;

export const FullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 100%;
  padding: 5px;
  position: relative;
`;

export const FullHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.65fr;
  gap: 10px;
  padding-bottom: 5px;
`;

export const FullContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.65fr;
  gap: 10px;
  position: relative;
  & > div {
    padding: 5px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 3px;
    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    color: #ffcf82;
    height: 460px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    min-width: 100%;
    max-width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const ButtonsTop = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

export const OnlineString = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
`;

export const MessagesWrapperFull = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  width: 100%;
  height: 410px;
  gap: 5px;
  text-align: start;
`;

export const MessageFull = styled.div`
  backface-visibility: hidden;
  will-change: transform;
  margin-right: 5px;
  color: #f8e5ca;
  font-family: "Bellota-Regular";
  font-size: 13px;
  line-height: 15px;
  & > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export const Users = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  height: 445px;
  gap: 5px;
  padding-right: 5px;
`;

export const User = styled.div`
  width: 100%;
  gap: 5px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  line-height: 20px;
  grid-template-columns: 0.2fr 1fr;
  min-height: 50px;
  max-height: 50px;

  background-image: url("${(props) => (props.isMe ? imgMarker2 : imgMarker5)}");
  background-size: cover;
  backface-visibility: hidden;
  will-change: transform;
  transition: 0.2s ease;
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};

  &:hover {
    filter: brightness(1.2);
  }
  &:active {
    filter: brightness(0.9);
  }

  & div {
    overflow: hidden;
    width: 100%;
    justify-content: center;
  }
`;

export const Avatar = styled.span`
  display: flex;
  background: rgba(17, 12, 9, 0.7);
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
`;

export const InputWrapperFull = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fc9800;
  & input {
    font-size: 12px;
    line-height: 15px;
    width: ${(props) => (props.isTag ? "calc(100% - 80px)" : "100%")};
    height: 20px;
    border: none !important;
    background-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
    font-family: "Bellota-Regular";
    color: #f8e5ca !important;
    -moz-appearance: textfield !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    ::placeholder {
      color: rgba(248, 229, 202, 0.7) !important;
      text-transform: none;
    }
  }
`;

export const InfoPopup = styled.div`
  position: absolute;
  left: 234px;
  top: ${(props) =>
    props.top ? (props.top > 420 ? "420px" : `${props.top}px`) : "0px"};
  transform: translate(50%, 0);
  display: flex;
  flex-direction: column;
  background-color: #18110a;
  color: #fcc382;
  backface-visibility: visible;
  will-change: transform;
  transition:
    opacity 0.1s linear,
    visibility 0.1s linear;
  z-index: ${(props) => (props.visible ? "1" : "-1")};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  font-size: 11px;
  text-align: center;
  min-width: 150px;
  max-width: 150px;
`;

export const Info = styled.div`
  margin-top: 1px;
  outline: 1px solid #e9be79;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    border-bottom: 1px solid rgba(233, 190, 121, 0.7);
    cursor: pointer;
    width: 100%;
    padding: 3px 8px;
    font-size: 11px;
    line-height: 13px;
    backface-visibility: visible;
    will-change: transform;
    transition: 0.2s ease;
    &:hover {
      filter: brightness(1.2);
    }
    &:active {
      filter: brightness(0.9);
    }
  }

  & > div:first-child {
    font-weight: 600;
    cursor: default;
    padding: 8px;
    filter: unset;
  }

  & > div:last-child {
    border: unset;
  }
`;

export const Rules = styled.div`
  padding: 5px;
  overflow-y: scroll;
  text-align: start;
`;
