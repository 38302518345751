import styled from "styled-components";

import btnImg from "../../img/miniIcons/Button.png";

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 72px;
  left: 26px;
  width: 27px;
  height: 27px;
  background-image: url("${btnImg}");
  background-size: cover;
  backface-visibility: hidden;
  will-change: transform;
  cursor: pointer;
  & img {
    backface-visibility: hidden;
    will-change: transform;
    transition: 0.2s ease;
    position: absolute;
  }
  &:hover img {
    filter: brightness(1.2);
  }
  &:active img {
    filter: brightness(0.9);
  }
`;
