import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const RightPart = styled.div`
  position: absolute;
  right: 8px;
  bottom: 91px;
  width: 247px;
  height: 55px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const LeftPart = styled.div`
  display: flex;
  position: absolute;
  left: 15px;
  bottom: 70px;
`;

export const CenterPart = styled.div`
  position: absolute;
  left: 50%;
  bottom: 109px;
  width: 350px;
  height: 31px;
  transform: translateX(-50%);
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const TopPart = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  width: 200px;
  height: 98px;
  transform: translateX(-50%);
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const LogPart = styled.div`
  position: absolute;
  right: 15px;
  bottom: -174px;
  transition: 0.6s linear;
  transform: ${(props) =>
    props.isActive ? "translateY(-274px);" : "translateY(0);"};
`;
