import styled from "styled-components";
import circle from "../../img/garderob/Circle.png";
import circleBack from "../../img/garderob/CircleBack.png";
import buttonConnect from "../../img/ButtonConnect.png";

export const Wrapper = styled.div``;

export const Back = styled.div`
  position: absolute;
  left: 50%;
  top: 82px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`;

export const Lvls = styled.div`
  position: absolute;
  left: 55px;
  top: 80px;
  width: 295px;
  display: flex;
  justify-content: center;
  gap: 5px;
`;

export const DropClothes = styled.div`
  position: absolute;
  left: 240px;
  bottom: 125px;
`;

export const ClothesWrapper = styled.div`
  position: absolute;
  left: 55px;
  top: 115px;
  width: 298px;
  height: 412px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 10px;
  align-content: flex-start;
`;

export const Clothes = styled.div`
  position: relative;
  width: 90px;
  height: 95px;
  cursor: ${(props) => !props.isClose && !props.cantBuy && "pointer"};

  backface-visibility: hidden;
  will-change: transform;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
  }

  & > img:nth-child(1) {
    transition: 0.2s linear;
    z-index: -1;
    filter: ${(props) =>
      props.active ? "brightness(1.5)" : props.isClose && "brightness(0.5)"};
  }

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    & > img {
      filter: ${(props) => props.isClose && "brightness(0.5)"};
    }
  }

  color: ${(props) =>
    props.isGet
      ? "#D2FF53"
      : props.cantBuy || props.isClose
        ? "#FF4040"
        : "#fcc382"};
`;

export const TextBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  text-align: center;
`;

export const CategoryIcon = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
`;

export const Categories = styled.div`
  position: absolute;
  left: 363px;
  top: 330px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Category = styled.div`
  min-width: ${(props) => props.width && `${props.width}px`};
  cursor: ${(props) => !props.disabled && "pointer"};
  position: relative;
  transition: filter 0.15s linear;
  backface-visibility: hidden;
  will-change: transform;
  &::after {
    width: 16px;
    height: 12px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 0px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    filter: ${(props) => !props.disabled && "brightness(1.2)"};
  }
  &:active {
    filter: ${(props) => !props.disabled && "brightness(0.9)"};
  }
  filter: ${(props) => props.isDone && "brightness(0.7)"};
  border-radius: 12px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(0deg, rgba(255, 223, 175, 0.2), rgba(255, 223, 175, 0.2)), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : props.disabled
        ? `linear-gradient(0deg, #301E0D, #301E0D), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
        : `linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
linear-gradient(
180deg,
rgba(255, 217, 159, 0.1) 0%,
rgba(255, 223, 176, 0) 42.19%
),
#6b3700`};
  & > div {
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 12px;
    padding: 2px 6px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const Lvl = styled.div`
  min-width: ${(props) => props.width && `${props.width}px`};
  cursor: ${(props) => !props.disabled && "pointer"};
  position: relative;
  transition: filter 0.15s linear;
  backface-visibility: hidden;
  will-change: transform;
  &:hover {
    filter: ${(props) => !props.disabled && "brightness(1.2)"};
  }
  &:active {
    filter: ${(props) => !props.disabled && "brightness(0.9)"};
  }
  filter: ${(props) => props.isDone && "brightness(0.7)"};
  border-radius: 12px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(0deg, rgba(255, 223, 175, 0.2), rgba(255, 223, 175, 0.2)), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : props.disabled
        ? `linear-gradient(0deg, #301E0D, #301E0D), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
        : `linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
linear-gradient(
180deg,
rgba(255, 217, 159, 0.1) 0%,
rgba(255, 223, 176, 0) 42.19%
),
#6b3700`};
  & > div {
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 12px;
    padding: 5px 7px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const Filters = styled.div`
  position: absolute;
  left: 55px;
  bottom: 125px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3px;
  color: #fcc382;
  font-size: 11px;

  & > div {
    display: flex;
    gap: 4px;
    justify-content: start;
    align-items: center;
    cursor: pointer;
  }
`;

export const Filter = styled.div`
  position: relative;
  width: 36px;
  height: 18px;
  background-image: url("${circleBack}");
  background-size: cover;
  cursor: pointer;
  & > div {
    transition: 0.1s linear;
    width: 14px;
    height: 14px;
    left: 0;
    top: 50%;
    opacity: ${(props) => (props.isActive ? "1" : "0.7")};
    transform: translate(${(props) => (props.isActive ? "20px" : "2px")}, -50%);
    position: absolute;
    background-image: url("${circle}");
    background-size: cover;
  }
`;

export const InfoPopup = styled.div`
  position: absolute;
  left: 198px;
  top: ${(props) => (props.isTop ? "150px" : "360px")};
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: #18110a;
  color: #fcc382;
  transition:
    opacity 0.1s linear,
    visibility 0.1s linear;
  z-index: ${(props) => (props.visible ? "1" : "-1")};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  font-size: 11px;
  text-align: center;
  min-width: 150px;
  max-width: 150px;
`;

export const Charac = styled.div`
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  outline: 1px solid #e9be79;
`;

export const Info = styled.div`
  margin-top: 1px;
  padding: 6px 8px;
  outline: 1px solid #e9be79;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
`;
