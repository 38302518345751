import React from "react";
import LoaderImg from "../../img/Loader.gif";

import * as S from "./Loader.styles";

function Loader({ isFetching }) {
  return (
    <S.Wrapper isOpen={isFetching}>
      <img width={110} height={68} src={LoaderImg} alt="загрузка" />
    </S.Wrapper>
  );
}

export default React.memo(Loader);
