import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 872px;
  height: 679px;
  gap: 10px;
  font-family: "Bellota-Regular";
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;
//
// export const RightWrapper = styled.div`
//   position: absolute;
//   width: 186px;
//   height: 418px;
//   left: 18px;
//   top: 19px;
// `;
//
// export const LocationsWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 5px;
//   height: 100%;
//   overflow-y: scroll;
//   padding: 0 7px 0 2px;
// `;
//
// export const Floor = styled.div`
//   text-align: center;
//   min-width: 100%;
//   max-width: 100%;
//   color: #c6a76b;
//   font-weight: 600;
//   font-size: 15px;
// `;
//
// export const Location = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 2px;
//   text-align: center;
//   min-width: 100%;
//   max-width: 100%;
//   padding: 5px 10px;
//   background-color: ${(props) => (props.important ? "#c12f1d" : "#ae956e")};
//   transition: filter 0.1s linear;
//   cursor: pointer;
//   backface-visibility: hidden;
//   will-change: transform;
//   &:hover {
//     filter: brightness(1.2);
//   }
//
//   & > span:nth-child(1) {
//     font-size: 13px;
//     color: #0f0b08;
//     font-weight: 600;
//   }
//
//   & > span:nth-child(2) {
//     font-size: 12px;
//     color: #0f0b08;
//     font-weight: 500;
//     font-style: italic;
//   }
// `;

export const Close = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  top: 20px;
  right: 25px;
  border-radius: 50%;
  width: 36px;
  height: 36px;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.15s linear;
  }

  & > img:last-child {
    opacity: 0;
  }

  &:hover img:last-child {
    opacity: 1;
  }

  &:active img:last-child {
    opacity: 0.4;
  }
`;

export const CloseNote = styled(Close)`
  top: 15px;
  right: 35px;
`;

export const Info = styled(Close)`
  top: 20px;
  right: unset;
  left: 25px;

  &:hover + div > img {
    opacity: 1;
  }
`;

export const LocationImg = styled.img`
  backface-visibility: hidden;
  will-change: transform;
  position: absolute;
  width: ${(props) => `${props.w}px`};
  height: ${(props) => `${props.h}px`};
  top: ${(props) => `${props.y}px`};
  left: ${(props) => `${props.x}px`};
  transition: opacity 0.15s linear;
  opacity: 0;
  cursor: pointer;
  clip-path: ${(props) => (props.clip ? `${props.clip}` : "unset")};
  &:hover {
    opacity: 1;
  }
`;

export const NameNote = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  line-height: 40px;
  font-family: "Inkverse-BWDRx";
  color: #281707;
`;

export const LocationsNote = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  gap: 10px;
`;

export const LocationNote = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => (props.type === "news" ? "417px" : "300px")};
  height: 233px;
  cursor: ${(props) => (props.location ? "pointer" : "default")};

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.15s linear;
  }

  & > img:last-child {
    opacity: 0;
  }

  &:hover img:last-child {
    opacity: 1;
  }

  &:active img:last-child {
    opacity: 0.7;
  }

  & > img:first-child {
    opacity: 1 !important;
  }
`;

export const ArrowPage = styled.div`
  display: flex;
  position: absolute;
  top: 168px;
  right: 45px;
  transform: translateX(-50%);
  width: 56px;
  height: 56px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.15s linear;
  }

  & > img:first-child {
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  }
  & > img:last-child {
    opacity: ${(props) => (props.disabled ? 0.3 : 0)};
  }

  &:hover img:last-child {
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  }

  &:active img:last-child {
    opacity: ${(props) => (props.disabled ? 0.3 : 0.7)};
  }
`;

export const ArrowPagePrev = styled(ArrowPage)`
  right: unset;
  left: 45px;
  transform: translateX(50%) scaleX(-1);
`;
