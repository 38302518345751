import styled, { keyframes } from "styled-components";

import cube from "../../img/DiceTexture.jpg";
import point from "../../img/DicePoint.png";

const show1 = (x, y) => keyframes`
  from {
  left:  ${x > 0 ? x + 150 : x < 0 ? x - 150 - 26 : x - 26}px;
  top:  ${y > 0 ? y + 150 : y < 0 ? y - 150 - 26 : y - 26}px;
  transform: scale(1.1) rotateX(${
    y > 0 ? -360 : y < 0 ? 360 : 0
  }deg) rotateZ(0deg) rotateY(${x > 0 ? 360 : x < 0 ? -360 : 0}deg);
  }

  to {
  left:  ${x - 26}px;
  top:  ${y - 26}px;
  transform: scale(1) rotateX(0deg) rotateZ(0deg) rotateY(0deg);
  }
`;

const show2 = (x, y) => keyframes`
  from {
  left:  ${x > 0 ? x + 150 : x < 0 ? x - 150 - 26 : x - 26}px;
  top:  ${y > 0 ? y + 150 : y < 0 ? y - 150 - 26 : y - 26}px;
  transform: scale(1.1) rotateX(${
    y > 0 ? -520 : y < 0 ? 180 : -180
  }deg) rotateZ(0deg) rotateY(${x > 0 ? -360 : x < 0 ? 360 : 0}deg);
  }

  to {
  left:  ${x - 26}px;
  top:  ${y - 26}px;
  transform:scale(1) rotateX(-180deg) rotateZ(0deg) rotateY(0deg);
  }
`;

const show3 = (x, y) => keyframes`
  from {
  left:  ${x > 0 ? x + 150 : x < 0 ? x - 150 - 26 : x - 26}px;
  top:  ${y > 0 ? y + 150 : y < 0 ? y - 150 - 26 : y - 26}px;
   transform:scale(1.1) rotateY(90deg) rotateZ(${
     y > 0 ? -360 : y < 0 ? 360 : 0
   }deg) rotateY(${x > 0 ? 360 : x < 0 ? -360 : 0}deg);
  }

  to {
  left:  ${x - 26}px;
  top:  ${y - 26}px;
  transform:scale(1) rotateY(90deg) rotateZ(0deg) rotateY(0deg);
  }
`;

const show4 = (x, y) => keyframes`
  from {
  left:  ${x > 0 ? x + 150 : x < 0 ? x - 150 - 26 : x - 26}px;
  top:  ${y > 0 ? y + 150 : y < 0 ? y - 150 - 26 : y - 26}px;
   transform:scale(1.1) rotateX(${
     y > 0 ? -450 : y < 0 ? 270 : -90
   }deg) rotateZ(${x > 0 ? 360 : x < 0 ? -360 : 0}deg) rotateY(0deg);
  }

  to {
  left:  ${x - 26}px;
  top:  ${y - 26}px;
  transform:scale(1) rotateX(-90deg) rotateZ(0deg) rotateY(0deg);
  }
`;

const show5 = (x, y) => keyframes`
  from {
  left:  ${x > 0 ? x + 150 : x < 0 ? x - 150 - 26 : x - 26}px;
  top:  ${y > 0 ? y + 150 : y < 0 ? y - 150 - 26 : y - 26}px;
   transform:scale(1.1) rotateX(${
     y > 0 ? -270 : y < 0 ? 450 : 90
   }deg) rotateZ(${x > 0 ? -360 : x < 0 ? 360 : 0}deg) rotateY(0deg);
  }

  to {
  left:  ${x - 26}px;
  top:  ${y - 26}px;
  transform:scale(1) rotateX(90deg) rotateZ(0deg) rotateY(0deg);
  }
`;

const show6 = (x, y) => keyframes`
  from {
  left:  ${x > 0 ? x + 150 : x < 0 ? x - 150 - 26 : x - 26}px;
  top:  ${y > 0 ? y + 150 : y < 0 ? y - 150 - 26 : y - 26}px;
   transform: scale(1.1) rotateY(-90deg) rotateZ(${
     y > 0 ? 360 : y < 0 ? -360 : 0
   }deg) rotateY(${x > 0 ? 360 : x < 0 ? -360 : 0}deg);
  }

  to {
  left:  ${x - 26}px;
  top:  ${y - 26}px;
  transform:scale(1) rotateY(-90deg) rotateZ(0deg) rotateY(0deg);
  }
`;

export const Dice = styled.div`
  position: absolute;
  width: 52px;
  height: 52px;
  left: ${(props) => props.x - 26}px;
  top: ${(props) => props.y - 26}px;
  transform-style: preserve-3d;

  .dot {
    position: absolute;
    background: url(${point}) 0 0 no-repeat;
    background-size: cover;
    width: 12px;
    height: 12px;
    margin: -6px 3px 3px -6px;
  }

  &.hide {
    visibility: hidden;
  }

  &.show-1 {
    animation: ${(props) => show1(props.x, props.y)} 0.8s ease;
    transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg);
    visibility: visible;
  }

  &.show-2 {
    animation: ${(props) => show2(props.x, props.y)} 0.8s ease;
    transform: rotateX(-180deg) rotateY(0deg) rotateZ(0deg);
    visibility: visible;
  }

  &.show-3 {
    animation: ${(props) => show3(props.x, props.y)} 0.8s ease;
    transform: rotateY(90deg) rotateZ(0deg) rotateX(0deg);
    visibility: visible;
  }

  &.show-4 {
    animation: ${(props) => show4(props.x, props.y)} 0.8s ease;
    transform: rotateX(-90deg) rotateZ(0deg) rotateY(0deg);
    visibility: visible;
  }

  &.show-5 {
    animation: ${(props) => show5(props.x, props.y)} 0.8s ease;
    transform: rotateX(90deg) rotateZ(0deg) rotateY(0deg);
    visibility: visible;
  }

  &.show-6 {
    animation: ${(props) => show6(props.x, props.y)} 0.8s ease;
    transform: rotateY(-90deg) rotateZ(0deg) rotateX(0deg);
    visibility: visible;
  }

  .side:nth-child(1) {
    transform: translateZ(25px);
  }

  .side:nth-child(6) {
    transform: rotateY(90deg) translateZ(25px);
  }

  .side:nth-child(3) {
    transform: rotateY(-90deg) translateZ(25px);
  }

  .side:nth-child(4) {
    transform: rotateX(90deg) translateZ(25px);
  }

  .side:nth-child(5) {
    transform: rotateX(-90deg) translateZ(25px);
  }

  .side:nth-child(2) {
    transform: rotateY(-180deg) translateZ(25px);
  }

  .two-1,
  .three-1,
  .four-1,
  .five-1,
  .six-1 {
    top: 25%;
    left: 25%;
  }

  .four-3,
  .five-3,
  .six-4 {
    top: 25%;
    left: 75%;
  }

  .one-1,
  .three-2,
  .five-5 {
    top: 50%;
    left: 50%;
  }

  .four-2,
  .five-2,
  .six-3 {
    top: 75%;
    left: 25%;
  }

  .two-2,
  .three-3,
  .four-4,
  .five-4,
  .six-6 {
    top: 75%;
    left: 75%;
  }

  .six-2 {
    top: 50%;
    left: 25%;
  }

  .six-5 {
    top: 50%;
    left: 75%;
  }
`;

export const Side = styled.div`
  position: absolute;
  background: url(${cube}) 0 0 no-repeat;
  background-size: cover;
  filter: ${(props) =>
    !props.gameStarted
      ? "brightness(0.5)"
      : props.active
        ? "brightness(1)"
        : "brightness(0.5)"};
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 2em;
  transition: box-shadow 0.1s ease;
  cursor: ${(props) =>
    props.active && props.gameStarted && !props.rolling
      ? "pointer"
      : "default"};
  &:hover {
    box-shadow: ${(props) =>
      props.active && props.gameStarted && !props.rolling
        ? "0px 0px 4px 2px #b3b3b3"
        : "none"};
  }
`;
