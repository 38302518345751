import * as S from "./Popup.styles";

import React, { useEffect } from "react";
import { createPortal } from "react-dom";

function Popup({
  isOpen,
  w,
  h,
  z,
  back,
  setIsOpen = null,
  isMove = false,
  children,
  justify = "center",
  clearContent = true,
  padding,
}) {
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  function closePopup(e) {
    if (e.target.classList.contains("popupWrapper")) {
      if (setIsOpen) {
        setIsOpen(false);
      }
    }
  }

  return domReady
    ? createPortal(
        <S.Wrapper
          className={"popupWrapper"}
          isOpen={isOpen}
          isMove={isMove}
          back={back}
          z={z}
          onClick={closePopup}
        >
          {(isOpen || !clearContent) && (
            <S.Content
              padding={padding}
              w={w}
              h={h}
              back={back}
              justify={justify}
            >
              <div>{children}</div>
            </S.Content>
          )}
        </S.Wrapper>,
        document.querySelector("#modals"),
      )
    : null;
}

export default React.memo(Popup);
