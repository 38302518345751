import React, { useEffect, useState } from "react";
import bridge from "@vkontakte/vk-bridge";

import * as S from "./RefUsers.styles";
import inviteImg from "../../img/miniIcons/Add.png";
import Popup from "../Popup/Popup";
import frame9 from "../../img/Frame9.png";
import { Close } from "../../App.styles";
import Reward from "../Reward/Reward";
import { Button } from "../../panels/Arena/Arena.styles";

import { RewardWrapper } from "../DayReward/DayReward.styles";
import { ButtonBottom } from "../SummerSaveQuests/SummerSaveQuests.styles";

function RefUsers({
  email,
  _id,
  refCount,
  refString,
  isFetching,
  allUsers,
  moveToUserRoom,
  app,
  setIsFetching,
}) {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [count, setCount] = useState(Object.entries(refCount.users).length);
  const [addUsers, setAddUsers] = useState([]);
  const [goldCount, setGoldCount] = useState(0);

  useEffect(() => {
    const array = Object.entries(refCount.users);
    const addUsers = [];
    let goldCan = 0;
    array.map((elem) => {
      const user = allUsers.find((user) => user.email == elem[0]);
      if (user) {
        addUsers.push({
          ...user,
          goldCount:
            (user.lvl >= 20 ? Math.floor((user.lvl - 10) / 10) * 100 : 0) + 25,
        });
      }
      goldCan += elem[1].lvlUp * 100 + (!elem[1].isGet ? 25 : 0);
    });
    setGoldCount(goldCan);
    setAddUsers(addUsers);
    setCount(array.length);
  }, [refCount]);

  function openPopup() {
    setIsInfoOpen(true);
  }

  function closePopup() {
    setIsInfoOpen(false);
  }

  function handleClickUser(user) {
    moveToUserRoom(user);
  }

  function getGold() {
    setIsFetching(true);
    app.service("users").addRefCount({
      meEmail: email,
      meId: _id,
      type: "get",
    });
  }

  function share() {
    bridge
      .send("VKWebAppShare", {
        link: refString,
      })
      .then((data) => {
        if (data.result) {
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function copy() {
    const textArea = document.createElement("textarea");
    textArea.value = refString;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
    } catch (err) {}

    document.body.removeChild(textArea);
  }

  return (
    <S.Wrapper>
      <S.ImgWrapper onClick={openPopup}>
        <img width={15} height={15} src={inviteImg} alt="плюс" />
      </S.ImgWrapper>
      <Popup
        isOpen={isInfoOpen}
        setIsOpen={closePopup}
        w={768}
        h={557}
        back={frame9}
      >
        {isInfoOpen && (
          <>
            <Close onClick={closePopup} />
            <S.Content>
              <div>
                <S.Header>Бонус за друзей в игре</S.Header>
                <S.ColumnLeft>
                  <div>
                    Приглашайте друзей присоединиться к игре по вашей ссылке и
                    получайте дополнительный бонус за его активность:
                  </div>
                  <S.Rewards>
                    <div>
                      <span>Первый вход</span>
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            name={"gold"}
                            count={25}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    </div>
                    <div>
                      <span>Получение 20 уровня</span>
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            name={"gold"}
                            count={100}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    </div>
                    <div>
                      <span>Каждые следующие 10 уровней</span>
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            name={"gold"}
                            count={100}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    </div>
                  </S.Rewards>
                  <div>
                    Для того, чтобы друг считался приглашенным вами, ему
                    необходимо впервые зайти в игру по личной ссылке ниже.
                  </div>
                </S.ColumnLeft>

                <S.ColumnBottom height={50}>
                  <S.StringRef onClick={copy}>{refString}</S.StringRef>
                  <ButtonBottom>
                    <Button
                      width={110}
                      onClick={isInfoOpen && !isFetching ? share : null}
                    >
                      <div>Поделиться</div>
                    </Button>
                    <Button width={110} onClick={copy}>
                      <div>Копировать</div>
                    </Button>
                  </ButtonBottom>
                </S.ColumnBottom>
              </div>
              <div>
                <S.Header>Приглашенные друзья ({count})</S.Header>
                <S.ColumnRight>
                  <S.Names>
                    <span></span>
                    <span>Игрок</span>
                    <span>Ник</span>
                    <span>Ур.</span>
                    <span>Награда</span>
                  </S.Names>

                  <S.Users>
                    {addUsers.length > 0 &&
                      addUsers.map((refUser, i) => {
                        return (
                          <S.User isActive={refUser.lvl >= 20} key={i}>
                            <S.Place>{i + 1}</S.Place>
                            <S.Avatar
                              onClick={handleClickUser.bind(null, refUser)}
                            >
                              <img
                                src={refUser.photo}
                                width={35}
                                height={35}
                                alt="аватар"
                              />
                            </S.Avatar>
                            <div>
                              <span>
                                {refUser.orden?.tag
                                  ? `[${refUser.orden?.tag}] `
                                  : ""}
                              </span>
                              <span>
                                {refUser.nickName
                                  ? refUser.nickName
                                  : `${refUser.name?.firstName} ${refUser.name?.lastName}`}
                              </span>
                            </div>
                            <span>{refUser.lvl}</span>
                            <span>
                              <Reward
                                button={true}
                                count={refUser.goldCount || 0}
                                name={"gold"}
                                w={15}
                              />
                            </span>
                          </S.User>
                        );
                      })}
                  </S.Users>
                </S.ColumnRight>
                <S.ColumnRight2>
                  <S.History>
                    {Array.from(refCount.history)
                      .reverse()
                      .map((elem, i) => {
                        const user = allUsers.find(
                          (user) => user.email == elem.email,
                        );
                        return (
                          user && (
                            <div key={i}>
                              <span>
                                {user.orden?.tag ? `[${user.orden?.tag}] ` : ""}
                                {user.nickName
                                  ? user.nickName
                                  : `${user.name?.firstName} ${user.name?.lastName}`}
                              </span>
                              <span>
                                {elem.add && "входит в игру по ссылке"}
                              </span>
                              <span>
                                {elem.lvl && `получил ${elem.lvl} уровень`}
                              </span>

                              <Reward
                                button={true}
                                count={`+${elem.lvl ? 100 : elem.add ? 25 : 0}`}
                                name={"gold"}
                                w={15}
                              />
                            </div>
                          )
                        );
                      })}
                  </S.History>
                </S.ColumnRight2>
                <S.BottomRight>
                  <S.Column gap={2} height={50}>
                    <Reward
                      round={true}
                      button={true}
                      count={refCount.gold}
                      name={"gold"}
                      w={15}
                    />
                    <S.StringBottom>Получено за всё время</S.StringBottom>
                  </S.Column>
                  <S.ColumnBottom height={50}>
                    <Reward
                      round={true}
                      button={true}
                      count={goldCount}
                      name={"gold"}
                      w={15}
                    />
                    <ButtonBottom>
                      <Button
                        width={120}
                        disabled={goldCount <= 0 || isFetching}
                        onClick={!isFetching && goldCount > 0 ? getGold : null}
                      >
                        <div>Получить</div>
                      </Button>
                    </ButtonBottom>
                  </S.ColumnBottom>
                </S.BottomRight>
              </div>
            </S.Content>
          </>
        )}
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(RefUsers);
