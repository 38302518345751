import React, { useEffect, useState } from "react";
import * as S from "./LvlInfo.styles";

import exp from "../../img/ExpProgress.png";
import hp from "../../img/HealthBar.png";
import { roundPropertyLow } from "../../helpers";

const dashArray = Math.PI * 2 * 26;

function LvlInfo({ currentExpOnLvl, onLvlExpNeed, lvl, health }) {
  const [stroke, setStroke] = useState(
    dashArray * ((100 - (currentExpOnLvl / onLvlExpNeed) * 100) / 100),
  );

  useEffect(() => {
    setStroke(
      dashArray * ((100 - (currentExpOnLvl / onLvlExpNeed) * 100) / 100),
    );
  }, [currentExpOnLvl, onLvlExpNeed]);

  return (
    <S.Wrapper>
      <S.Circle
        exp={roundPropertyLow(onLvlExpNeed - currentExpOnLvl).toLocaleString(
          "ru",
        )}
      >
        <svg width="72" height="72" viewBox="0 0 72 72">
          <circle
            className={"back"}
            r="26"
            cx="36"
            cy="36"
            fill="transparent"
            stroke={"#271401"}
            strokeWidth="12px"
          />

          <linearGradient id="gradient">
            <stop stopColor={"#2a7b03"} />
            <stop offset=".5" stopColor={"#3da804"} />
            <stop offset="1" stopColor={"#ccef05"} />
          </linearGradient>

          <circle
            className={"forward"}
            r="26"
            cx="36"
            cy="36"
            fill="transparent"
            stroke={"url(#gradient)"}
            strokeWidth="12px"
            strokeDasharray={`${dashArray}px`}
            strokeDashoffset={`${stroke}px`}
          />
        </svg>
        <img src={exp} alt="опыт" />
        <S.Lvl>
          <span>{lvl}</span>
          <span>ур</span>
        </S.Lvl>
      </S.Circle>

      <S.HpProgress
        value={(health.current / health.max) * 100}
        hp={roundPropertyLow(health.current).toLocaleString("ru")}
      >
        <img src={hp} alt="шкала" width={195} height={20} />
        <span>
          {roundPropertyLow(health.current).toLocaleString("ru")}/
          {roundPropertyLow(health.max).toLocaleString("ru")}
        </span>
      </S.HpProgress>
    </S.Wrapper>
  );
}

export default React.memo(LvlInfo);
