import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 100%;
  padding: 5px 15px;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const NewBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  & > div {
    display: flex;
    gap: 5px;
    justify-content: center;
    position: relative;
    padding: 5px 5px 20px 5px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 3px;
    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    color: #ffcf82;
    margin-bottom: 20px;
  }
`;

export const DayPrizes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const DayPrizeText = styled.span`
  font-weight: 600;
  color: ${(props) =>
    props.isDone ? "#D0CB4A" : props.isLate ? "#FF5A5A" : "#ffcf82"};
`;

export const Header = styled.span`
  color: #ffcf82;
  font-size: 16px;
`;

export const Time = styled.span`
  color: #ffcf82;
  opacity: 0.7;
`;

export const ButtonBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const Block = styled.img`
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
`;

export const QuestsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0px 0 22px 0;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 3px;
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  color: #ffcf82;
  margin-top: 5px;
`;

export const Quest = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fc9800;
  padding: 6px 10px;
  font-size: 14px;
  color: ${(props) => (props.isDone ? "#D0CB4A" : "#ffcf82")};
`;
