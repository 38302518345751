import styled from "styled-components";

export const Menu = styled.div`
  transition: 0.6s linear;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  position: absolute;
  align-items: center;
  top: 229px;

  right: ${(props) => (props.bossLog ? "314px" : "72px")};

  gap: 4px;
  transform: translate(50%, -50%);
`;

export const MenuElem = styled.div`
  transition-delay: 0.5s;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  cursor: pointer;
  position: relative;
  display: flex;
  &:after {
    top: 15px;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 13px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
    width: max-content;
    content: "${(props) => (props.name ? `${props.name}` : "")}";
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }

  & > img {
    transition: all 0.1s linear;
    filter: ${(props) => props.active && "brightness(0.7)"};
  }
`;

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  & span > b {
    font-size: 20px;
  }
`;

export const QuestsReward = styled.div`
  display: flex;
  gap: 40px;

  & > div {
    width: 75px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
`;

export const QuestsButtons = styled.div`
  display: flex;
  gap: 40px;

  & > div {
    width: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > button {
      width: 100%;
    }
  }
`;

export const Stepper = styled.div`
  display: flex;
  gap: 40px;
`;

export const Step = styled.div`
  width: 75px;
  height: 75px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isGet ? "#1ecf1e" : props.active ? "#f7b471" : "white"};
  border: 2px solid
    ${(props) => (props.isGet ? "#1ecf1e" : props.active ? "#f7b471" : "white")};

  border-radius: 50%;

  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 100%;
    transform: translate(1px, -50%);
    width: 40px;
    height: 2px;
    background-color: ${(props) =>
      props.isGet ? "green" : props.active ? "#f7b471" : "white"};
  }

  &:last-child:after {
    display: none;
  }
`;

export const LightText = styled.div`
  margin-top: ${(props) => (props.margin ? `${props.margin}px` : 0)};
  opacity: 0.8;
`;

export const BoldText = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const Bar = styled.div`
  top: 5px;
  left: 4px;
  position: absolute;
  transition: width 0.3s linear;
  width: ${(props) => `calc(242px * ${props.progress})`};
  height: 5px;
  border-radius: 3px;
  background-color: #e5b06d;
`;

export const Grid2 = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;

  & > div {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
