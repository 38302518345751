import React, { useCallback, useEffect, useState } from "react";

import * as S from "./MenuBottom.styles";
import SearchUser from "../SearchUser/SearchUser";
import { MiniButton } from "../../panels/Arena/Arena.styles";

function MenuBottom({ changeFilters }) {
  const [selectedUsersOption, setSelectedUsersOption] = useState("all");
  const [selectedPropertyOption, setSelectedPropertyOption] = useState("lvl");
  const [search, setSearch] = useState("");

  useEffect(() => {
    changeFriendsFilter();
  }, [selectedUsersOption]);

  const changeFriendsFilter = useCallback(() => {
    changeFilters({
      userFilter: selectedUsersOption,
      propertyFilter: selectedPropertyOption,
      userFilterName: search,
    });
  }, [search, selectedUsersOption]);

  function handleChangeUsersOption(value) {
    setSelectedUsersOption(value);
  }

  return (
    <>
      <SearchUser
        changeFilters={changeFilters}
        selectedUsersOption={selectedUsersOption}
        selectedPropertyOption={selectedPropertyOption}
        search={search}
        setSearch={setSearch}
      />
      <S.Wrapper>
        <MiniButton
          width={60}
          disabled={selectedUsersOption === "all"}
          onClick={handleChangeUsersOption.bind(null, "all")}
        >
          <div>Все</div>
        </MiniButton>
        <MiniButton
          width={60}
          disabled={selectedUsersOption === "friends"}
          onClick={handleChangeUsersOption.bind(null, "friends")}
        >
          <div>Друзья</div>
        </MiniButton>
      </S.Wrapper>
    </>
  );
}

export default React.memo(MenuBottom);
