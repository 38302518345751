import React from "react";

import * as S from "./ArenaHistory.styles";
import { roundConsumablesFixed } from "../../helpers";
import Reward from "../Reward/Reward";

function ArenaHistory({ allUsers, setIsOpen, history, id, moveToUserRoom }) {
  function handleClickUser(user) {
    moveToUserRoom(user);
    setIsOpen(false);
  }

  return (
    <S.Wrapper>
      <S.Names>
        <span>Нападавший</span>
        <span>Противник</span>
        <span>Результат</span>
        <span>Награда</span>
        <span>Время</span>
      </S.Names>
      <S.Fights>
        {history.length > 0 &&
          [...history].reverse().map((el, i) => {
            const me = allUsers.find((elem) => elem._id === el.me.id);
            const enemy = allUsers.find((elem) => elem._id === el.enemy.id);

            return (
              me &&
              enemy && (
                <S.Fight
                  key={history.length - i}
                  isWin={
                    (me._id === id && el.isWin) ||
                    (enemy._id === id && !el.isWin)
                  }
                >
                  {me._id === id ? (
                    <>
                      <div>Вы</div>
                      <S.Enemy onClick={handleClickUser.bind(null, enemy)}>
                        <span>
                          {enemy.orden?.tag ? `[${enemy.orden?.tag}] ` : ""}
                        </span>
                        <span>
                          {enemy.nickName
                            ? enemy.nickName
                            : `${enemy.name?.firstName} ${enemy.name?.lastName}`}
                        </span>
                      </S.Enemy>
                      <S.Result isWin={el.isWin}>
                        {el.isWin ? "Победа" : "Поражение"}
                      </S.Result>
                      <Reward
                        button={true}
                        name={"essence"}
                        count={roundConsumablesFixed(el.essence)}
                        w={15}
                      />
                      <div> {new Date(el.time).toLocaleString()}</div>
                    </>
                  ) : (
                    <>
                      <S.Enemy onClick={handleClickUser.bind(null, me)}>
                        <span>
                          {me.orden?.tag ? `[${me.orden?.tag}] ` : ""}
                        </span>
                        <span>
                          {me.nickName
                            ? me.nickName
                            : `${me.name?.firstName} ${me.name?.lastName}`}
                        </span>
                      </S.Enemy>
                      <div>Вы</div>
                      <S.Result isWin={!el.isWin}>
                        {el.isWin ? "Поражение" : "Победа"}
                      </S.Result>
                      <Reward
                        button={true}
                        name={"essence"}
                        count={roundConsumablesFixed(el.essence)}
                        w={15}
                      />
                      <div> {new Date(el.time).toLocaleString()}</div>
                    </>
                  )}
                </S.Fight>
              )
            );
          })}
      </S.Fights>
    </S.Wrapper>
  );
}

export default React.memo(ArenaHistory);
