import React from "react";
import * as S from "./SearchUser.styles";
import searchImg from "../../img/miniIcons/Search.png";
import backImg from "../../img/SearhBack.png";

function SearchUser({
  changeFilters,
  selectedUsersOption,
  selectedPropertyOption,
  search,
  setSearch,
}) {
  function handleChange(e) {
    setSearch(e.target.value);
  }

  function searchUser() {
    changeFilters({
      userFilter: selectedUsersOption,
      propertyFilter: selectedPropertyOption,
      userFilterName: search,
    });
  }

  function searchUserEnter(event) {
    if (event.key === "Enter") {
      searchUser();
    }
  }

  return (
    <S.Wrapper>
      <img src={backImg} alt="поиск" width={333} height={30} />
      <div>
        <input
          value={search}
          onChange={handleChange}
          onKeyDown={searchUserEnter}
          type="text"
          maxLength={25}
          placeholder={"Псевдоним / Имя Фамилия "}
        />
        <div>
          <img
            onClick={searchUser}
            width={15}
            height={15}
            src={searchImg}
            alt="поиск"
          />
        </div>
      </div>
    </S.Wrapper>
  );
}

export default React.memo(SearchUser);
