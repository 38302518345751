import styled from "styled-components";

export const Logger = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 5px;
  opacity: ${(props) => props.opacity};
  text-align: center;
  backface-visibility: hidden;
  will-change: transform;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Bellota-Regular";
    text-shadow: 0 0 4px #000000;
    font-weight: 600;
    & > span:nth-child(1) {
      color: #ff3232;
    }
    & > span:nth-child(2) {
      color: #fcc382;
    }
  }
`;
