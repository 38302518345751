import React, { useEffect, useState } from "react";

import * as S from "./NewUserQuests.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import Reward from "../Reward/Reward";
import {
  newQuestEndPrizes,
  newQuestPrizes,
  newQuestArray,
} from "../../info/data";
import { Button } from "../ActiveBoss/ActiveBoss.styles";
import allLines from "../../img/newQuest/All.png";
import lastLines from "../../img/newQuest/Last.png";
import block from "../../img/newQuest/Block.png";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import { Close } from "../../App.styles";

function NewUserQuests({
  isOpen,
  user,
  time,
  setModalError,
  setIsFetching,
  isFetching,
  setUser,
  app,
  moveLocation,
  setOpen,
  setActiveMenuModal,
  bridge,
}) {
  const [allDone, setAllDone] = useState(false);
  const [questNumber, setQuestNumber] = useState(0);
  const [endOpen, setEndOpen] = useState(false);

  useEffect(() => {
    setAllDone(!user.newQuest.quests.includes(false));
  }, [user]);

  function changeQuest(number) {
    setQuestNumber(number);
  }

  function changeEndOpen(bool) {
    setEndOpen(bool);
  }

  function move(place) {
    if (place !== "book") {
      moveLocation(place);
      setOpen(false);
    } else {
      setActiveMenuModal(place);
      setOpen(false);
    }
  }

  function vkPay(params) {
    setIsFetching(true);
    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          [params.property]: params.count,
          g: params.price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
          changeEndOpen(true);
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  }

  function getReward(number) {
    const reward = newQuestArray[number].reward;
    setIsFetching(true);

    if (reward.property === "potion") {
      app
        .service("users")
        .patch(
          user._id,
          {
            $inc: {
              [`inventoryNew.${reward.category}${reward.potionName}`]:
                reward.property === "potion" ? reward.count : 0,
            },
            [`newQuest.quests.${number}`]: true,
            common: true,
          },
          {
            query: {
              $select: ["_id", "email", "inventoryNew", "newQuest"],
            },
          },
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    } else {
      app
        .service("users")
        .patch(
          user._id,
          {
            $inc: {
              essence: reward.property === "essence" ? reward.count : 0,
              mana: reward.property === "mana" ? reward.count : 0,
              gold: reward.property === "gold" ? reward.count : 0,
              silver: reward.property === "silver" ? reward.count : 0,
              "spells.points": reward.property === "magic" ? reward.count : 0,
              "spells.pointsAll":
                reward.property === "magic" ? reward.count : 0,
              "diceGame.isFree":
                reward.property === "diceGame" ? reward.count : 0,
              "game21.isFree": reward.property === "game21" ? reward.count : 0,
            },
            [`newQuest.quests.${number}`]: true,
            common: true,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "gold",
                "silver",
                "essence",
                "mana",
                "diceGame",
                "game21",
                "spells",
                "newQuest",
              ],
            },
          },
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setModalError(e);
          console.log(e);
        });
    }
  }

  function getEndReward() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          $inc: {
            gold: newQuestEndPrizes[0]?.count || 0,
            silver: newQuestEndPrizes[1]?.count || 0,
            "spells.points": newQuestEndPrizes[2]?.count || 0,
            "spells.pointsAll": newQuestEndPrizes[2]?.count || 0,
            [`bosses.${newQuestEndPrizes[3]?.number || 0}.souls`]:
              newQuestEndPrizes[3]?.count || 0,
            "diceGame.isFree": newQuestEndPrizes[4]?.count || 0,
            "game21.isFree": newQuestEndPrizes[5]?.count || 0,
          },
          "newQuest.isDone": true,
          common: true,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "gold",
              "silver",
              "bosses",
              "diceGame",
              "game21",
              "spells",
              "newQuest",
            ],
          },
        },
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  return (
    <S.Wrapper isOpen={isOpen}>
      <S.Time>До завершения: {time}</S.Time>
      <S.Header>
        Особые наборы новичка (доступно: {10 - user.newQuest.countPay})
      </S.Header>

      <S.NewBox>
        <div>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"gold"}
                count={newQuestPrizes[0].gold}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"silver"}
                count={newQuestPrizes[0].silver}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"essence"}
                count={newQuestPrizes[0].essence}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>

          <S.ButtonBottom>
            <Button
              minW={140}
              disabled={10 - user.newQuest.countPay <= 0}
              onClick={
                !isFetching && 10 - user.newQuest.countPay > 0
                  ? vkPay.bind(null, {
                      property: "newPack",
                      count: 0,
                      price: newQuestPrizes[0].price,
                    })
                  : null
              }
            >
              <div>{newQuestPrizes[0].price} гол.</div>
            </Button>
          </S.ButtonBottom>
        </div>
        <div>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"gold"}
                count={newQuestPrizes[1].gold}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"silver"}
                count={newQuestPrizes[1].silver}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"essence"}
                count={newQuestPrizes[1].essence}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>

          <S.ButtonBottom>
            <Button
              minW={140}
              disabled={10 - user.newQuest.countPay <= 0}
              onClick={
                !isFetching && 10 - user.newQuest.countPay > 0
                  ? vkPay.bind(null, {
                      property: "newPack",
                      count: 1,
                      price: newQuestPrizes[1].price,
                    })
                  : null
              }
            >
              <div>{newQuestPrizes[1].price} гол.</div>
            </Button>
          </S.ButtonBottom>
        </div>
        <div>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"gold"}
                count={newQuestPrizes[2].gold}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"silver"}
                count={newQuestPrizes[2].silver}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"essence"}
                count={newQuestPrizes[2].essence}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          <S.ButtonBottom>
            <Button
              minW={140}
              disabled={10 - user.newQuest.countPay <= 0}
              onClick={
                !isFetching && 10 - user.newQuest.countPay > 0
                  ? vkPay.bind(null, {
                      property: "newPack",
                      count: 2,
                      price: newQuestPrizes[2].price,
                    })
                  : null
              }
            >
              <div>{newQuestPrizes[2].price} гол.</div>
            </Button>
          </S.ButtonBottom>
        </div>
      </S.NewBox>

      <S.Header>Задания для новичка</S.Header>
      <S.Time>
        Выполни все задания и получи главную награду! Успей закончить, пока не
        вышло время
      </S.Time>
      <S.RewardQuests>
        {newQuestEndPrizes.map((elem, i) => {
          return (
            <RewardWrapper key={i} light={false} w={65}>
              <div>
                <Reward
                  name={elem.property}
                  soul={elem.number}
                  count={elem.count}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          );
        })}
        <S.ButtonBottom>
          <Button
            minW={140}
            disabled={!allDone || user.newQuest.isDone}
            onClick={
              !isFetching && allDone && !user.newQuest.isDone
                ? getEndReward
                : null
            }
          >
            <div>{user.newQuest.isDone ? "Получено" : "Получить"}</div>
          </Button>
        </S.ButtonBottom>
      </S.RewardQuests>

      <S.LinesProgress>
        <img src={allLines} width={585} height={46} alt="линии" />
        <div>
          {user.newQuest.quests.map((elem, i) => {
            return (
              elem === true && (
                <img
                  key={i}
                  src={require(`../../img/newQuest/${i + 1}.png`)}
                  width={585}
                  height={46}
                  alt="линии"
                />
              )
            );
          })}

          {allDone && (
            <img src={lastLines} width={585} height={46} alt="линии" />
          )}

          {!allDone && (
            <S.Block src={block} width={20} height={20} alt="линии" />
          )}
        </div>
      </S.LinesProgress>

      <S.QuestsWrapper>
        <S.MenuButtons>
          {newQuestArray.map((elem, i) => {
            return (
              <Button
                isActive={
                  !user.newQuest.quests[i] &&
                  (user.newQuest.results[newQuestArray[i].property] || 0) >=
                    newQuestArray[i].count
                }
                isDone={user.newQuest.quests[i] === true}
                disabled={questNumber === i}
                key={i}
                minW={57}
                onClick={changeQuest.bind(null, i)}
              >
                <div>{i + 1}</div>
              </Button>
            );
          })}
        </S.MenuButtons>

        <S.HeaderQuest>Задание {questNumber + 1}</S.HeaderQuest>
        <span>
          {newQuestArray[questNumber].string} Прогресс:{" "}
          {user.newQuest.results[newQuestArray[questNumber].property] >
          newQuestArray[questNumber].count
            ? newQuestArray[questNumber].count
            : user.newQuest.results[newQuestArray[questNumber].property] || 0}
          /{newQuestArray[questNumber].count}
        </span>
        <Reward
          string={"Награда:"}
          name={newQuestArray[questNumber].reward.property}
          count={newQuestArray[questNumber].reward.count}
          potionName={newQuestArray[questNumber].reward.potionName}
          category={newQuestArray[questNumber].reward.category}
          w={15}
          showCount={true}
          font={13}
          color={"#fdbb54"}
        />

        <S.ButtonBottom>
          {user.newQuest.quests[questNumber] === true ? (
            <Button minW={140} disabled={true}>
              <div>Выполнено</div>
            </Button>
          ) : (user.newQuest.results?.[newQuestArray[questNumber].property] ||
              0) >= newQuestArray[questNumber].count ? (
            <Button
              minW={140}
              onClick={
                !isFetching && !user.newQuest.quests[questNumber]
                  ? getReward.bind(null, questNumber)
                  : null
              }
            >
              <div>Забрать</div>
            </Button>
          ) : (
            <Button
              minW={140}
              onClick={move.bind(null, newQuestArray[questNumber].place)}
            >
              <div>Перейти</div>
            </Button>
          )}
        </S.ButtonBottom>
      </S.QuestsWrapper>

      <Popup
        isOpen={endOpen}
        setIsOpen={setEndOpen}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={changeEndOpen.bind(null, false)} />
        <b>Оплата прошла успешно</b>
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(NewUserQuests);
