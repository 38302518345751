import React, { useState } from "react";
import * as S from "./Guide.styles";
import { Button } from "../../panels/Arena/Arena.styles";

import img1 from "../../img/guide/1.png";
import img2 from "../../img/guide/2.png";
import img3 from "../../img/guide/3.png";
import img4 from "../../img/guide/4.png";
import img5 from "../../img/guide/5.png";
import img6 from "../../img/guide/6.png";
import img7 from "../../img/guide/7.png";
import img8 from "../../img/guide/8.png";
import img9 from "../../img/guide/9.png";
import img10 from "../../img/guide/10.png";
import img11 from "../../img/guide/11.png";
import img12 from "../../img/guide/12.png";
import img13 from "../../img/guide/13.png";
import img14 from "../../img/guide/14.png";
import img15 from "../../img/guide/15.png";
import img16 from "../../img/guide/16.png";

const stages = 16;

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
];

function Guide({
  userId,
  isOpen,
  setLeftMenu,
  leftMenuArray,
  app,
  setUser,
  setIsFetching,
  setModalError,
  serverTime,
  isFetching,
}) {
  const [stepNumber, setStepNumber] = useState(0);

  function handleNextClick() {
    if (stepNumber < stages - 1) {
      if (stepNumber === 0) {
        setLeftMenu([...leftMenuArray.slice(1, 5)]);
      }
      setStepNumber((prevState) => prevState + 1);
    }
  }

  function endGuide() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        userId,
        {
          isGuide: true,
          registerTime: serverTime,
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "isGuide", "registerTime"],
          },
        },
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        setIsFetching(false);
        console.log(e);
      });
  }

  return (
    <S.Wrapper isOpen={isOpen}>
      {isOpen && (
        <>
          <S.Screen
            active={stepNumber > 0 && stepNumber < stages - 1}
            onClick={
              stepNumber > 0 && stepNumber < stages ? handleNextClick : null
            }
            src={images[stepNumber]}
          />

          {stepNumber === 0 && (
            <S.ButtonsWrapper>
              <Button width={140} onClick={!isFetching ? endGuide : null}>
                <div>Пропустить обучение</div>
              </Button>
              <Button width={140} onClick={handleNextClick}>
                <div>Начать оубчение</div>
              </Button>
            </S.ButtonsWrapper>
          )}

          {stepNumber === stages - 1 && (
            <S.ButtonsWrapper>
              <Button width={140} onClick={!isFetching ? endGuide : null}>
                <div>Закончить оубчение</div>
              </Button>
            </S.ButtonsWrapper>
          )}
        </>
      )}
    </S.Wrapper>
  );
}

export default React.memo(Guide);
