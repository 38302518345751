import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  right: 24px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
