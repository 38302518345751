import styled, { css, keyframes } from "styled-components";
import { Button as ButtonBoss } from "../../components/ActiveBoss/ActiveBoss.styles";

import buttonConnect from "../../img/ButtonConnect.png";

export const RewardsPopup = styled.div`
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 10px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const Item = styled.div`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  transition: 0.3s linear;
  cursor: ${(props) => (!props.isDone ? "pointer" : "default")};

  &:hover {
    opacity: ${(props) => (!props.isDone ? "1" : "0")};
  }
`;

export const Popup = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  position: absolute;
  width: 140px;
  height: 45px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  color: #fcc382;
  font-family: "Bellota-Regular";

  background: rgba(17, 12, 9, 0.7);
  border-radius: 10px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    padding: 2px 4px;
    position: absolute;
    top: 0;
    display: flex;
    gap: 3px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & > div:nth-child(1) {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const PopupActive = styled.div`
  position: absolute;
  width: 140px;
  height: 120px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  color: #fcc382;
  font-family: "Bellota-Regular";

  background: rgba(17, 12, 9, 0.7);
  border-radius: 10px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    text-align: center;
    padding: 2px 4px 20px 4px;
    position: absolute;
    top: 0;
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & > div:nth-child(1) {
      font-size: 12px;
      line-height: 12px;
    }
    & > div:nth-child(2) {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const Button = styled(ButtonBoss)``;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -12px;
`;

export const ButtonChange = styled.div`
  min-width: 30px;
  max-width: 30px;
  cursor: ${(props) => !props.disabled && "pointer"};
  position: relative;

  &::after {
    visibility: ${(props) => props.islast && "hidden"};
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: -1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  border-radius: 7px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(0deg, rgba(255, 223, 175, 0.2), rgba(255, 223, 175, 0.2)), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : props.disabled
        ? `linear-gradient(0deg, #301E0D, #301E0D), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
        : `linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
linear-gradient(
180deg,
rgba(255, 217, 159, 0.1) 0%,
rgba(255, 223, 176, 0) 42.19%
),
#6b3700`};

  & > div {
    backface-visibility: hidden;
    will-change: transform;
    transition: filter 0.15s linear;
    filter: ${(props) => props.isDone && "brightness(0.7)"};
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 7px;
    padding: 1px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 11px;
    line-height: 14px;
  }

  &:hover > div {
    filter: ${(props) => !props.disabled && "brightness(1.2)"};
  }
  &:active > div {
    filter: ${(props) => !props.disabled && "brightness(0.9)"};
  }
`;

export const ButtonChangeWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  gap: 2px;
`;
