import React, { useEffect, useState } from "react";

function Damage({ damageInfo, allUsers }) {
  const [arrayDamage, setArrayDamage] = useState([]);

  useEffect(() => {
    if (damageInfo.length) {
      setArrayDamage([...damageInfo].reverse().slice(0, 20));
    }
  }, [damageInfo]);

  return arrayDamage.length
    ? arrayDamage.map(({ damage, email }, i) => {
        const user = allUsers.find((user) => user.email === email);
        return (
          user && (
            <div key={arrayDamage.length - i}>
              <img
                width={22}
                height={22}
                src={user.photo}
                alt={"аватар"}
                style={{ borderRadius: "50%" }}
              />

              <div>
                <span>{user.orden?.tag ? `[${user.orden?.tag}] ` : ""}</span>
                <span>
                  {damage.evasion
                    ? user.nickName
                      ? user.nickName +
                        " промахивается и наносит " +
                        damage.value.toLocaleString("ru")
                      : user.name?.firstName +
                        " " +
                        user.name?.lastName +
                        " промахивается и наносит " +
                        damage.value.toLocaleString("ru")
                    : damage.isKrit
                      ? user.nickName
                        ? user.nickName +
                          " наносит крит " +
                          damage.value.toLocaleString("ru")
                        : user.name?.firstName +
                          " " +
                          user.name?.lastName +
                          " наносит крит " +
                          damage.value.toLocaleString("ru")
                      : user.nickName
                        ? user.nickName +
                          " наносит " +
                          damage.value.toLocaleString("ru")
                        : user.name?.firstName +
                          " " +
                          user.name?.lastName +
                          " наносит " +
                          damage.value.toLocaleString("ru")}
                </span>
              </div>
            </div>
          )
        );
      })
    : null;
}

export default React.memo(Damage);
