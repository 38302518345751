import React, { useCallback, useEffect, useState } from "react";

import ActiveBoss from "../../components/ActiveBoss/ActiveBoss";
import { roundLvlExp, roundProperty, secondsToHm } from "../../helpers";
import { CentredWrapper, Close } from "../../App.styles";
import {
  allClothes,
  bosses2Array,
  bossesArray,
  bossesStages,
  bossesTalents,
  eventBossArray,
  grindData,
  soloBossArray,
} from "../../info/data";
import Popup from "../../components/Popup/Popup";
import Reward from "../../components/Reward/Reward";
import imgArrow from "../../img/ArrowBosses.png";
import imgTongue from "../../img/Tongue.png";

import hvostImg from "../../img/bossesPictures/Hvost.png";
import arkeinImg from "../../img/bossesPictures/Arkein.png";
import dementorImg from "../../img/bossesPictures/Dementor.png";
import dvehgoloviImg from "../../img/bossesPictures/Dvehgolovi.png";
import grindiloyImg from "../../img/bossesPictures/Grindiloy.png";
import proklytElfImg from "../../img/bossesPictures/ProklytElf.png";
import zyfyImg from "../../img/bossesPictures/Zyfy.png";
import korespondentImg from "../../img/bossesPictures/Korespondent.png";

import { Button } from "../Arena/Arena.styles";
import { RewardWrapper } from "../../components/DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";
import BossInterface from "../../components/BossInterface/BossInterface";
import {
  ArrowBack,
  ArrowNext,
  Block,
  BookPages,
  ButtonsWrapper,
  Columns3,
  Columns4,
  Content,
  Limits,
  Line,
  MarkerButtonStyled,
  Picture,
  Rewards,
  Stages,
  Text,
  TextBlock,
  Tongue,
} from "../Bosses/Bosses.styles";

const types = {
  entity: "Существо",
  man: "Человек",
  ghost: "Призрак",
};

const photos = [
  korespondentImg,
  proklytElfImg,
  zyfyImg,
  dvehgoloviImg,
  arkeinImg,
  grindiloyImg,
  hvostImg,
  dementorImg,
];

function Bosses2({
  user,
  app,
  friends,
  setUser,
  serverTime,
  setIsFetching,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  setIsLvlUpOpen,
  changeOptionsLocation,
  setModalError,
  bossesLimitScale,
  allUsers,
  useItem,
  setBossLog,
  bossLog,
  bossesInfo,
  setBossesInfo,
  setIsAltarOpen,
  warOrdenTask,
  ordenBonus,
}) {
  const [bossesLimit, setBossesLimit] = useState(0);
  const [bossStage, setBossStage] = useState(bossesInfo?.lastBossStage || 0);
  const [pageNumber, setPageNumber] = useState(bossesInfo?.lastBoss || 0);
  const [bossRandomReward, setBossRandomReward] = useState(null);
  const [timeToNext, setTimeToNext] = useState("0");
  const [clearLimitOpen, setClearLimitOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bossExpScale, setBossExpScale] = useState(
    user.damageTalents.simpleTalents[
      bossesTalents.bossExpScale[bosses2Array[pageNumber][bossStage].classType]
    ]
  );
  const [bossSilverScale, setBossSilverScale] = useState(
    user.damageTalents.simpleTalents[
      bossesTalents.bossSilverScale[
        bosses2Array[pageNumber][bossStage].classType
      ]
    ]
  );

  useEffect(() => {
    changeOptionsLocation("bosses2");
  }, []);

  useEffect(() => {
    setBossesInfo((prev) => ({
      ...prev,
      lastBoss: pageNumber,
      lastBossStage: bossStage,
    }));
  }, [bossStage, pageNumber]);

  useEffect(() => {
    setBossExpScale(
      user.damageTalents.simpleTalents[
        bossesTalents.bossExpScale[
          bosses2Array[pageNumber][bossStage].classType
        ]
      ]
    );

    setBossSilverScale(
      user.damageTalents.simpleTalents[
        bossesTalents.bossSilverScale[
          bosses2Array[pageNumber][bossStage].classType
        ]
      ]
    );
  }, [user, bossStage, pageNumber]);

  useEffect(() => {
    const mm = (59 - new Date(serverTime).getMinutes()) * 60;
    const ss = 60 - new Date(serverTime).getSeconds();

    setTimeToNext(secondsToHm(mm + ss));

    if (
      serverTime <
        bossesLimitScale.startTime + bossesLimitScale.time * 3600000 &&
      serverTime > bossesLimitScale.startTime
    ) {
      setBossesLimit(bossesLimitScale.count);
    } else {
      setBossesLimit(0);
    }
  }, [bossesLimitScale, serverTime]);

  function changePageNumber(isNext) {
    if (isNext) {
      setPageNumber((prev) => {
        if (prev + 1 < bosses2Array.length) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    } else {
      setPageNumber((prev) => {
        if (prev - 1 >= 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }
  }

  function randomBossClothes(bossNumber, stage, isSolo) {
    const randomChance = Math.random().toFixed(2);

    const clothes = Object.entries(allClothes)
      .filter(
        (elem) =>
          elem[1]?.bossRandom &&
          elem[1]?.bossRandom.number === bossNumber &&
          elem[1]?.bossRandom.stage === stage &&
          elem[1]?.bossRandom.isSolo === isSolo &&
          user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
      )
      .sort((a, b) => a[1].bossRandom.chance - b[1].bossRandom.chance);

    let prize = null;

    clothes.map((elem) => {
      if (randomChance <= elem[1].bossRandom.chance) {
        prize = elem;
      }
    });

    if (prize) {
      const openClothes = [...user.clothes.isOpen, prize[0]];
      setLoading(true);
      setIsFetching(false);
      app
        .service("users")
        .patch(
          user._id,
          {
            "clothes.isOpen": openClothes,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "clothes"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setBossRandomReward(prize[1]);
          setIsFetching(false);
          setLoading(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setLoading(false);
          setModalError(e);
          console.log(e);
        });
    }
  }

  function changeBossReward(reward) {
    setBossRandomReward(reward);
  }

  function handleAltarOpen() {
    setIsAltarOpen(2);
  }

  function handleClearLimits() {
    setClearLimitOpen((prevState) => !prevState);
  }

  function clearLimits() {
    if ((user.boss2LimitClear || 0) < 1) {
      if (user.gold >= 500) {
        setLoading(true);
        setIsFetching(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              "bosses2.$[].countAttacks.done": 0,
              $inc: {
                boss2LimitClear: 1,
                gold: -500,
                "dayQuest.bossesLimit": 1,
              },
              field: serverTime,
            },
            {
              query: {
                $select: [
                  "_id",
                  "email",
                  "boss2LimitClear",
                  "bosses2",
                  "gold",
                  "dayQuest",
                ],
              },
            }
          )
          .then((data) => {
            setLoading(false);
            setClearLimitOpen(false);
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsFetching(false);
            setLoading(false);
          });
      } else {
        setModalError("Недостаточно золота");
        console.log("Недостаточно золота");
      }
    } else {
      setModalError("Лимит сбросов исчерпан");
      console.log("Лимит сбросов исчерпан");
    }
  }

  const attackBoss = useCallback(
    (isSolo) => {
      if (
        (!user.raidBoss.isEnd && user.raidBoss.isAttack) ||
        user.activeBoss.number > -1
      ) {
        if (user.activeBoss.number > -1) {
          setModalError(
            `Вы уже находитесь в бою с "${
              user.activeBoss.isShyt
                ? "Шутом"
                : user.activeBoss.type === "location"
                ? soloBossArray[user.activeBoss.number][0].name
                : user.activeBoss.type === "eventBoss"
                ? eventBossArray[user.activeBoss.number].name
                : user.activeBoss.type === "dungeon2"
                ? bosses2Array[user.activeBoss.number][0].name
                : bossesArray[user.activeBoss.number][0].name
            }"`
          );
        } else {
          setModalError("Вы уже находитесь в бою с Рейдовым Боссом");
        }
      } else {
        let isAttack = true;
        if (
          (user.bosses2[pageNumber]?.countAttacks?.done || 0) >=
          10 + bossesLimit + user.bossLimits
        ) {
          isAttack = false;
          setModalError("Лимит в этом часу исчерпан");
        } else if (
          (user.bosses2[pageNumber]?.souls || 0) <
          bosses2Array[pageNumber][bossStage].soulsNeed
        ) {
          isAttack = false;
          setModalError("Недостаточно душ");
        }

        if (isAttack) {
          setIsFetching(true);
          setLoading(true);
          app
            .service("users")
            .patch(
              user._id,
              {
                $inc: {
                  [`bosses2.${pageNumber}.souls`]:
                    pageNumber > 0
                      ? -bosses2Array[pageNumber][bossStage].soulsNeed
                      : 0,
                  [`bosses2.${pageNumber}.countAttacks.done`]: 1,
                },
                "activeBoss.type": "dungeon2",
                "activeBoss.isSolo": isSolo,
                "activeBoss.stage": bossStage,
                "activeBoss.number": pageNumber,
                "activeBoss.currentHp":
                  bosses2Array[pageNumber][bossStage].allHp,
                "activeBoss.endTime":
                  bosses2Array[pageNumber][bossStage].time.maxTime * 3600000 +
                  serverTime,
                field: serverTime,
              },
              {
                query: {
                  $select: ["_id", "email", "bosses2", "activeBoss"],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
              setTimeout(() => {
                setLoading(false);
              }, 250);
              setIsFetching(false);
            })
            .catch((e) => {
              setModalError(e);
              console.log(e);
              setLoading(false);
              setIsFetching(false);
            });
        } else {
          setIsFetching(false);
        }
      }
    },
    [user, serverTime, pageNumber, bossStage]
  );

  const leaveBoss = useCallback(
    ({
      isWin,

      exp,
      silver,

      leave,
      stage,
      isSolo,
    }) => {
      setLoading(true);
      setIsFetching(true);

      let number = user.activeBoss.number;

      setPageNumber(number);
      setBossStage(stage);

      let isUp = 0;
      let currentExpOnLvl = user.currentExpOnLvl;
      let onLvlExpNeed = user.onLvlExpNeed;

      if (isWin) {
        if (currentExpOnLvl + Math.floor(exp) >= onLvlExpNeed) {
          currentExpOnLvl += Math.floor(exp) - onLvlExpNeed;
          onLvlExpNeed = roundLvlExp(
            onLvlExpNeed *
              (user.lvl > 100
                ? 1.1
                : user.lvl > 150
                ? 1.05
                : user.lvl > 200
                ? 1.02
                : 1.2)
          );
          isUp = 1;
          while (currentExpOnLvl >= onLvlExpNeed) {
            currentExpOnLvl -= onLvlExpNeed;
            onLvlExpNeed = roundLvlExp(
              onLvlExpNeed *
                (user.lvl > 100
                  ? 1.1
                  : user.lvl > 150
                  ? 1.05
                  : user.lvl > 200
                  ? 1.02
                  : 1.2)
            );
            isUp++;
          }
          app.service("users").removeListener("timer");
        } else {
          currentExpOnLvl += Math.floor(exp);
        }
      }

      const characteristicInfo = {};
      Object.entries(user.characteristic).forEach((elem) => {
        characteristicInfo[elem[0]] = {
          ...elem[1],
          lvl: (user.lvl + isUp) * 5,
        };
      });

      let bossSoul =
        isWin && number < bosses2Array.length - 1
          ? [`bosses2.${number + 1}.souls`]
          : "sex";
      let soulCount =
        isWin && number < bosses2Array.length - 1
          ? (user.bosses2[number + 1]?.souls || 0) + 1
          : user.sex;

      const scales = {};
      const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
      user.damageTalents.characteristicTalents.forEach((elem) => {
        scales[elem.property] =
          1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
      });

      const hp = Math.floor(
        Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * 5 * scales.health;
          }
          return acc + cur * 5;
        }, 0)
      );

      let goldUp = 0;
      for (let step = 0; step < isUp; step++) {
        goldUp += user.lvl + step + 1;
      }

      const points = isWin
        ? (number + 1) * (stage + 1) * (isSolo ? 2 + number : 1)
        : 0;

      app
        .service("users")
        .patch(
          user._id,
          {
            $inc: {
              weakBossesPoints: points * 100,
              leaveBoss: leave ? 1 : 0,
              [`bosses2.${number}.totalKills`]: isWin ? 1 : 0,
              [`bosses2.${number}.total2Kills`]: isWin && stage === 1 ? 1 : 0,
              [`bosses2.${number}.total3Kills`]: isWin && stage === 2 ? 1 : 0,
              [`bosses2.${number}.soloKills`]: isWin && isSolo ? 1 : 0,
              [`bosses2.${number}.solo2Kills`]:
                isWin && isSolo && stage === 1 ? 1 : 0,
              [`bosses2.${number}.solo3Kills`]:
                isWin && isSolo && stage === 2 ? 1 : 0,
              allExp: isWin ? Math.floor(exp) : 0,
              lvl: isUp,
              gold: goldUp,
              silver: isWin ? Math.floor(silver) : 0,
              currentEnergy:
                isUp &&
                user.currentEnergy <
                  user.damageTalents.simpleTalents.maxValueEnergy +
                    grindData["owl"].bonus[user.grind["owl"] - 1]
                  ? user.damageTalents.simpleTalents.maxValueEnergy +
                    grindData["owl"].bonus[user.grind["owl"] - 1] -
                    user.currentEnergy
                  : 0,
              "newQuest.results.bossesWins": isWin && number === 0 ? 1 : 0,
              "dayQuest.bossesWins": isWin ? 1 : 0,
              "dayQuest.bossesSoloWins": isWin && isSolo ? 1 : 0,
              "summerSave.results.bossesWins": isWin ? 1 : 0,
              "summerSave.results.bossesSoloWins": isWin && isSolo ? 1 : 0,
              "spells.points": isUp,
              "spells.pointsAll": isUp,
            },
            "activeBoss.damageTop": {},
            "activeBoss.type": "",
            "activeBoss.isFirst": true,
            "activeBoss.isShare": false,
            "activeBoss.damageInfo": [],
            "activeBoss.damageLog": [],
            "activeBoss.number": -1,
            "activeBoss.treasure": {
              lvl: 0,
              count: 0,
            },
            [bossSoul]: soulCount,
            currentExpOnLvl: currentExpOnLvl,
            onLvlExpNeed: onLvlExpNeed,
            health: {
              current: isUp ? hp : user.health.current,
              max: hp,
            },
            characteristic: characteristicInfo,
            field: serverTime,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "leaveBoss",
                "currentEnergy",
                "bosses2",
                "activeBoss",
                "currentExpOnLvl",
                "onLvlExpNeed",
                "health",
                "characteristic",
                "allExp",
                "lvl",
                "silver",
                "gold",
                "dayQuest",
                "spells",
                "weakBossesPoints",
                "newQuest",
                "summerSave",
              ],
            },
          }
        )
        .then((data) => {
          setIsModalOpen(false);
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
          setTimeout(() => {
            setLoading(false);
          }, 250);
          if (isUp) {
            setIsLvlUpOpen(true);
          }

          const tasks = [];

          if (isWin) {
            // randomBossClothes(number, stage, isSolo);

            tasks.push({ property: "bossesWins", count: 1 });
            if (isSolo) {
              tasks.push({ property: "bossesSoloWins", count: 1 });
            }
            if (stage === 1) {
              tasks.push({ property: "bossesStage1Wins", count: 1 });
            }
            if (stage === 2) {
              tasks.push({ property: "bossesStage2Wins", count: 1 });
            }
          }

          if (tasks.length) {
            warOrdenTask(tasks);
          }
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsModalOpen(false);
          setLoading(false);
        });
    },
    [user, serverTime]
  );

  function changeStage(number) {
    setBossStage(number);
  }

  return (
    <>
      {user.activeBoss.number > -1 && user.activeBoss.type === "dungeon2" ? (
        <>
          <BossInterface bossLog={bossLog} />
          <CentredWrapper>
            <ActiveBoss
              {...user.bosses2[user.activeBoss.number]}
              {...bosses2Array[user.activeBoss.number][user.activeBoss.stage]}
              {...user.activeBoss}
              classType={
                bosses2Array[user.activeBoss.number][user.activeBoss.stage]
                  .classType
              }
              photos={photos}
              bossExpScale={
                user.damageTalents.simpleTalents[
                  bossesTalents.bossExpScale[
                    bosses2Array[user.activeBoss.number][user.activeBoss.stage]
                      .classType
                  ]
                ]
              }
              bossSilverScale={
                user.damageTalents.simpleTalents[
                  bossesTalents.bossSilverScale[
                    bosses2Array[user.activeBoss.number][user.activeBoss.stage]
                      .classType
                  ]
                ]
              }
              bossKritChance={
                bosses2Array[user.activeBoss.number][user.activeBoss.stage]
                  .bossKritChance
              }
              bossEvasionChance={
                bosses2Array[user.activeBoss.number][user.activeBoss.stage]
                  .bossEvasionChance
              }
              number={user.activeBoss.number}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              serverTime={serverTime}
              setUser={setUser}
              leaveBoss={leaveBoss}
              app={app}
              user={user}
              friends={friends}
              setModalError={setModalError}
              allUsers={allUsers}
              useItem={useItem}
              setBossLog={setBossLog}
              bossLog={bossLog}
              bossesInfo={bossesInfo}
              setBossesInfo={setBossesInfo}
              ordenBonus={ordenBonus}
            />
          </CentredWrapper>
        </>
      ) : (
        <Content>
          <BookPages>
            <div>
              <Text size={18}>{bosses2Array[pageNumber][bossStage].name}</Text>

              <Text size={14}>Тип: Босс бестиария</Text>

              <Picture
                src={photos[pageNumber]}
                width={240}
                height={166}
                alt=""
              />

              <Text size={14}>
                Класс: {types[bosses2Array[pageNumber][bossStage].classType]}
              </Text>

              <Text>{bosses2Array[pageNumber][bossStage].string}</Text>

              <ArrowBack
                disabled={pageNumber === 0}
                onClick={changePageNumber.bind(null, false)}
                src={imgArrow}
                width={60}
                height={37}
                alt="стрелка"
              />
            </div>
            <div>
              <Stages>
                {bossesStages.map((elem, i) => {
                  return (
                    <MarkerButtonStyled
                      key={i}
                      onClick={changeStage.bind(null, i)}
                      disabled={bossStage === i}
                      size={12}
                    >
                      <div>{elem}</div>
                    </MarkerButtonStyled>
                  );
                })}
              </Stages>

              <Columns3>
                <div>
                  Лимит:
                  <span>
                    {user.bosses2[pageNumber]?.countAttacks?.done || 0}/
                    {10 + bossesLimit + user.bossLimits}
                  </span>
                </div>
                <div>
                  Сброс лимита:<span>{timeToNext}</span>
                </div>
                <div>
                  Души:
                  {bosses2Array[pageNumber][bossStage].soulsNeed <= 0 ? (
                    <span>Не требуются</span>
                  ) : (
                    <span>
                      {user.bosses2[pageNumber]?.souls || 0}/
                      {bosses2Array[pageNumber][bossStage].soulsNeed}
                    </span>
                  )}
                </div>
              </Columns3>

              <Line />

              <Text>Характеристики:</Text>

              <Columns4>
                <div>
                  Здоровье:
                  <span>
                    {roundProperty(
                      bosses2Array[pageNumber][bossStage].allHp
                    ).toLocaleString("ru")}
                  </span>
                </div>
                <div>
                  Урон:
                  <span>
                    {roundProperty(
                      bosses2Array[pageNumber][bossStage].attack
                    ).toLocaleString("ru")}
                  </span>
                </div>
                <div>
                  Крит:
                  <span>
                    {bosses2Array[pageNumber][bossStage].bossKritChance}%
                  </span>
                </div>
                <div>
                  Уклонение:
                  <span>
                    {bosses2Array[pageNumber][bossStage].bossEvasionChance}%
                  </span>
                </div>
              </Columns4>

              <Line />

              <Text>Награда:</Text>

              <Rewards>
                <RewardWrapper light={true} w={65}>
                  <div>
                    <Reward
                      name={"exp"}
                      count={Math.floor(
                        bosses2Array[pageNumber][bossStage].rewards.exp *
                          (bossExpScale > 0 ? 1 + bossExpScale / 100 : 1)
                      )}
                      w={28}
                      showCount={true}
                      round={true}
                      font={13}
                      reverseColumn={true}
                      color={"#281707"}
                    />
                  </div>
                </RewardWrapper>

                <RewardWrapper light={true} w={65}>
                  <div>
                    <Reward
                      name={"silver"}
                      count={Math.floor(
                        bosses2Array[pageNumber][bossStage].rewards.silver *
                          (bossSilverScale > 0 ? 1 + bossSilverScale / 100 : 1)
                      )}
                      w={28}
                      showCount={true}
                      round={true}
                      font={13}
                      reverseColumn={true}
                      color={"#281707"}
                    />
                  </div>
                </RewardWrapper>

                {pageNumber < bosses2Array.length - 1 && (
                  <RewardWrapper light={true} w={65}>
                    <div>
                      <Reward
                        name={"soul2"}
                        soul2={pageNumber + 1}
                        count={1}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#281707"}
                      />
                    </div>
                  </RewardWrapper>
                )}
              </Rewards>

              <Line />

              {user.health.max >
                bosses2Array[pageNumber][bossStage].attack * 2 &&
              (bossStage === 0 ||
                (bossStage === 1 &&
                  (user.bosses2[pageNumber]?.totalKills || 0) >= 100) ||
                (bossStage === 2 &&
                  (user.bosses2[pageNumber]?.totalKills || 0) >= 500)) ? (
                (user.bosses2[pageNumber]?.countAttacks?.done || 0) >=
                10 + bossesLimit + user.bossLimits ? (
                  <Limits>
                    <Text size={13}>Исчерпан лимит для нападения</Text>
                    <MarkerButtonStyled onClick={handleClearLimits}>
                      <div>Сбросить лимиты</div>
                    </MarkerButtonStyled>
                  </Limits>
                ) : (user.bosses2[pageNumber]?.souls || 0) <
                  bosses2Array[pageNumber][bossStage].soulsNeed ? (
                  <Limits>
                    <Text size={13}>Недостаточно душ для нападения</Text>
                    <MarkerButtonStyled onClick={handleAltarOpen}>
                      <div>Обменять души</div>
                    </MarkerButtonStyled>
                  </Limits>
                ) : (
                  <>
                    <Text>
                      Напасть на Босса можно двумя способами: сражаясь совместно
                      с друзьми или один на один с врагом, получив возможность
                      повысить награду в 3 раза.  Первый удар всегда за мной! На
                      бой даётся 8 часов.
                    </Text>
                    <ButtonsWrapper>
                      <MarkerButtonStyled
                        minW={90}
                        onClick={
                          !isFetching && !loading
                            ? attackBoss.bind(null, true)
                            : null
                        }
                      >
                        <div>Лично</div>
                      </MarkerButtonStyled>
                      <MarkerButtonStyled
                        minW={90}
                        onClick={
                          !isFetching && !loading
                            ? attackBoss.bind(null, false)
                            : null
                        }
                      >
                        <div>Совместно</div>
                      </MarkerButtonStyled>
                    </ButtonsWrapper>
                  </>
                )
              ) : (
                <Block>
                  <Text size={13}>Требования для открытия Босса:</Text>
                  {bossStage === 0 ? (
                    <TextBlock
                      isBlock={
                        user.health.max < bosses2Array[pageNumber][1].attack * 2
                      }
                    >
                      Не менее{" "}
                      {roundProperty(
                        bosses2Array[pageNumber][0].attack * 2
                      ).toLocaleString("ru")}{" "}
                      здоровья
                    </TextBlock>
                  ) : bossStage === 1 ? (
                    <>
                      <TextBlock
                        isBlock={
                          user.health.max <
                          bosses2Array[pageNumber][1].attack * 2
                        }
                      >
                        Не менее{" "}
                        {roundProperty(
                          bosses2Array[pageNumber][1].attack * 2
                        ).toLocaleString("ru")}{" "}
                        здоровья
                      </TextBlock>

                      <TextBlock
                        isBlock={
                          (user.bosses2[pageNumber]?.totalKills || 0) < 100
                        }
                      >
                        Одержать победу над Боссом:{" "}
                        {user.bosses2[pageNumber]?.totalKills || 0}/100
                      </TextBlock>
                    </>
                  ) : (
                    <>
                      <TextBlock
                        isBlock={
                          user.health.max <
                          bosses2Array[pageNumber][2].attack * 2
                        }
                      >
                        Не менее{" "}
                        {roundProperty(
                          bosses2Array[pageNumber][2].attack * 2
                        ).toLocaleString("ru")}{" "}
                        здоровья
                      </TextBlock>

                      <TextBlock
                        isBlock={
                          (user.bosses2[pageNumber]?.totalKills || 0) < 500
                        }
                      >
                        Одержать победу над Боссом:{" "}
                        {user.bosses2[pageNumber]?.totalKills || 0}/500
                      </TextBlock>
                    </>
                  )}
                </Block>
              )}

              <ArrowNext
                disabled={pageNumber === bosses2Array.length - 1}
                onClick={changePageNumber.bind(null, true)}
                src={imgArrow}
                width={60}
                height={37}
                alt="стрелка"
              />
            </div>
          </BookPages>
          <Tongue src={imgTongue} width={97} height={238} alt="язык" />
        </Content>
      )}

      <Popup
        isOpen={clearLimitOpen}
        setIsOpen={setClearLimitOpen}
        w={317}
        h={217}
        back={frame3}
      >
        <Close onClick={handleClearLimits} />
        <b>Желаете сбросить все лимиты в бестиарии?</b>
        <span>Доступно в сутки: {1 - (user.boss2LimitClear || 0)}/1</span>
        <Button
          disabled={loading || isFetching}
          onClick={!loading && !isFetching ? clearLimits : null}
        >
          <Reward button={true} name={"gold"} count={"Сбросить 500"} w={15} />
        </Button>
      </Popup>

      <Popup
        isOpen={bossRandomReward}
        setIsOpen={setBossRandomReward}
        w={317}
        h={217}
        back={frame3}
      >
        <Close onClick={changeBossReward.bind(null, null)} />
        <b>Разблокирован предмет</b>
        {bossRandomReward &&
          (bossRandomReward.category === "broom" ||
          bossRandomReward.category === "wand" ? (
            <img
              width={90}
              height={90}
              src={require(`../../img/clothes/${bossRandomReward.category}${
                bossRandomReward.lvl >= 1 ? `${bossRandomReward.lvl + 1}` : ""
              }/${bossRandomReward.src}`)}
              alt={bossRandomReward.category}
            />
          ) : (
            <img
              width={90}
              height={90}
              src={require(`../../img/clothes/${bossRandomReward.category}${
                bossRandomReward.lvl >= 1 ? `${bossRandomReward.lvl + 1}` : ""
              }/${user.sex}/${bossRandomReward.src}`)}
              alt={bossRandomReward.category}
            />
          ))}
      </Popup>
    </>
  );
}

export default React.memo(Bosses2);
