export function getMultipleRandom(array, length = 0) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array.slice(
    0,
    length > 0 && array.length >= length ? length : array.length,
  );
}

export function secondsToMs(seconds) {
  if (seconds <= 0) {
    return "00:00";
  } else {
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor((seconds % 3600) % 60);

    let mDisplay = m <= 9 ? "0" + m + ":" : m + ":";
    let sDisplay = s <= 9 ? "0" + s : s;

    return mDisplay + sDisplay;
  }
}

export function secondsToHms(seconds) {
  if (seconds <= 0) {
    return "00:00:00";
  } else {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor((seconds % 3600) % 60);

    let hDisplay = h <= 9 ? "0" + h + ":" : h + ":";
    let mDisplay = m <= 9 ? "0" + m + ":" : m + ":";
    let sDisplay = s <= 9 ? "0" + s : s;

    return hDisplay + mDisplay + sDisplay;
  }
}

export function secondsToHm(seconds) {
  if (seconds <= 0) {
    return "00ч. 00м.";
  } else {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);

    let hDisplay = h <= 9 ? "0" + h + "ч. " : h + "ч. ";
    let mDisplay = m <= 9 ? "0" + m + "м." : m + "м.";

    return hDisplay + mDisplay;
  }
}

export function secondsToH(seconds) {
  if (seconds <= 0) {
    return "0м.";
  } else {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);

    let hDisplay = h + "ч.";
    let mDisplay = m + "м.";

    return h === 0 ? mDisplay : hDisplay;
  }
}

export function secondsToHmShort(seconds) {
  if (seconds <= 0) {
    return "00:00";
  } else {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);

    let hDisplay = h <= 9 ? "0" + h + ":" : h + ":";
    let mDisplay = m <= 9 ? "0" + m : m;

    return hDisplay + mDisplay;
  }
}

export function secondsToDhm(seconds) {
  if (seconds <= 0) {
    return "0дн. 00ч. 00м.";
  } else {
    let d = Math.floor(seconds / (24 * 3600));
    let h = Math.floor(seconds / 3600) % 24;
    let m = Math.floor((seconds % 3600) / 60);

    let dDisplay = d + "дн. ";
    let hDisplay = h <= 9 ? "0" + h + "ч. " : h + "ч. ";
    let mDisplay = m <= 9 ? "0" + m + "м." : m + "м.";

    return dDisplay + hDisplay + mDisplay;
  }
}

export function secondsToDh(seconds) {
  if (seconds <= 0) {
    return "00:00:00";
  } else {
    let d = Math.floor(seconds / (24 * 3600));
    let h = Math.floor(seconds / 3600) % 24;

    let dDisplay = d + "дн. ";
    let hDisplay = h <= 9 ? "0" + h + "ч." : h + "ч.";

    return dDisplay + hDisplay;
  }
}

export function roundLvlExp(val) {
  return (
    Math.round(val / 10 ** (val.toString().length - 2)) *
    10 ** (val.toString().length - 2)
  );
}

export function roundProperty(val) {
  if (val.toString().length > 6) {
    let kol = Math.floor((val.toString().length - 1) / 3) - 1;
    const ost = (val.toString().length - 1) % 3;
    let string = parseFloat(
      (val / 10 ** (val.toString().length - 1 - ost)).toFixed(1),
    );
    while (kol--) {
      string += "м";
    }
    return string;
  }
  return val;
}

export function roundPropertyLow(val) {
  if (val.toString().length > 6) {
    let kol = Math.floor((val.toString().length - 1) / 3) - 1;
    const ost = (val.toString().length - 1) % 3;
    let string = parseFloat(
      Math.floor((val * 10) / 10 ** (val.toString().length - 1 - ost)) / 10,
    ).toFixed(1);
    while (kol--) {
      string += "м";
    }
    return string;
  }
  return val;
}

export function roundPropertyLow9(val) {
  if (val.toString().length > 9) {
    let kol = Math.floor((val.toString().length - 1) / 3) - 1;
    const ost = (val.toString().length - 1) % 3;
    let string = parseFloat(
      Math.floor((val * 10) / 10 ** (val.toString().length - 1 - ost)) / 10,
    ).toFixed(1);
    while (kol--) {
      string += "м";
    }
    return string;
  }
  return val;
}

export function roundConsumables(val) {
  if (val.toString().length > 6) {
    let kol = Math.floor((val.toString().length - 1) / 3) - 1;
    const ost = (val.toString().length - 1) % 3;
    let string = parseFloat(
      Math.floor(val / 10 ** (val.toString().length - 1 - ost)),
    );
    while (kol--) {
      string += "м";
    }
    return string;
  } else if (val.toString().length > 3) {
    let kol = Math.floor((val.toString().length - 1) / 3);
    const ost = (val.toString().length - 1) % 3;
    let string = parseFloat(
      Math.floor(val / 10 ** (val.toString().length - 1 - ost)),
    );
    while (kol--) {
      string += "т";
    }
    return string;
  }
  return val;
}

export function roundConsumablesFixed(val) {
  if (val.toString().length > 6 && val > 1) {
    let kol = Math.floor((val.toString().length - 1) / 3) - 1;
    const ost = (val.toString().length - 1) % 3;
    let string = parseFloat(
      Math.floor((val * 10) / 10 ** (val.toString().length - 1 - ost)) / 10,
    ).toFixed(1);
    while (kol--) {
      string += "м";
    }
    return string;
  } else if (val.toString().length > 3 && val > 1) {
    let kol = Math.floor((val.toString().length - 1) / 3);
    const ost = (val.toString().length - 1) % 3;
    let string = parseFloat(
      Math.floor((val * 10) / 10 ** (val.toString().length - 1 - ost)) / 10,
    ).toFixed(1);
    while (kol--) {
      string += "т";
    }
    return string;
  } else if (val < 1) {
  }
  return val;
}
