import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Collections = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const NeedCollections = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 1px #281707 inset;
  border: 2px solid #e98101;
  border-radius: 3px;
  padding: 5px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 506px;
  height: 502px;
  position: relative;
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px;
  }

  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const MenuButtons = styled.div`
  gap: 15px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px;

  & > span {
    font-size: 14px;
    color: #fdbb54;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  margin-bottom: 20px;
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e98101;
  margin: 0 0 5px 0;
`;
