import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 840px;
  height: 570px;
  left: 30px;
  top: 50px;
`;

export const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const Body = styled.div`
  position: absolute;
  width: 207px;
  height: 335px;
  left: 394px;
  top: 190px;

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

export const Broom = styled.div`
  display: flex;
  position: absolute;
  left: 13px;
  top: 180px;
`;

export const Owl = styled.div`
  display: flex;
  position: absolute;
  left: 229px;
  top: 191px;
`;

export const Book = styled.div`
  display: flex;
  position: absolute;
  left: 315px;
  top: 181px;
`;

export const Cup = styled.div`
  display: flex;
  position: absolute;
  left: 695px;
  top: 272px;
`;

export const BossesCup = styled.div`
  display: flex;
  position: absolute;
  left: 582px;
  top: 145px;
`;

export const ArenaCup = styled.div`
  display: flex;
  position: absolute;
  left: 688px;
  top: 206px;
`;

export const ClassroomsCup = styled.div`
  display: flex;
  position: absolute;
  left: 631px;
  top: 216px;
`;

export const GamesCup = styled.div`
  display: flex;
  position: absolute;
  left: 582px;
  top: 220px;
`;
