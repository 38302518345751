import styled from "styled-components";
import { MarkerButton } from "../Quests/Quests.styles";

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 3px;
`;

export const MarkerButtonStyled = styled(MarkerButton)`
  font-size: ${(props) => (props.size ? props.size : "11")}px;
  & > div {
    border-radius: 3px;
    padding: 3px 4px;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const CharactWrapper = styled.div`
  max-width: 660px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  margin: 20px 0;
`;

export const Essence = styled.div`
  display: flex;
`;
