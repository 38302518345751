import styled from "styled-components";
import buttonConnect from "../../img/ButtonConnect.png";

export const Wrapper = styled.div`
  position: relative;
`;

export const ButtonLeft = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: -5px;
  transform: rotateZ(-90deg) translate(50%, -100%);
  cursor: ${(props) => (props.limit ? "default" : "pointer")};
  transition: 0.2s linear;
  filter: ${(props) => (!props.limit ? "brightness(1)" : "brightness(0.6)")};
  &:hover {
    filter: ${(props) =>
      !props.limit ? "brightness(1.2)" : "brightness(0.6)"};
  }
  &:active {
    filter: ${(props) =>
      !props.limit ? "brightness(0.9)" : "brightness(0.6)"};
  }
`;

export const ButtonRight = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: rotateZ(90deg) translate(-50%, -100%);
  cursor: ${(props) => (props.limit ? "default" : "pointer")};
  transition: 0.15s linear;
  filter: ${(props) => (!props.limit ? "brightness(1)" : "brightness(0.6)")};
  &:hover {
    filter: ${(props) =>
      !props.limit ? "brightness(1.2)" : "brightness(0.6)"};
  }
  &:active {
    filter: ${(props) =>
      !props.limit ? "brightness(0.9)" : "brightness(0.6)"};
  }
`;

export const InfoHeallCount = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: 0.15s linear;
  display: flex;
  justify-content: center;
  width: 78px;
  min-width: fit-content;
  height: 30px;
  position: absolute;
  transform: translate(-50%, -100%) scale(1);
  left: 50%;
  top: -12px;
  cursor: default;
  color: #ffe3c8;
  font-family: "Bellota-Regular";
  font-size: 10px;
  line-height: 10px;

  background: rgba(17, 12, 9, 0.7);
  border-radius: 3px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
`;

export const InfoHeall = styled.div`
  transition: 0.1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
`;

export const ImgHeall = styled.div`
  color: white;
  font-family: "Bellota-Regular";
  padding: 2px 0;
  position: relative;
  display: flex;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  & > img {
    transition: 0.15s linear;
    border-radius: 50%;
    filter: ${(props) =>
      props.disabled ? "brightness(0.7)" : "brightness(1)"};
  }

  &:hover > img {
    filter: ${(props) =>
      props.disabled ? "brightness(0.7)" : "brightness(1.2)"};
  }

  &:hover > div {
    opacity: ${(props) => (props.disabled ? "0" : "1")};
    visibility: ${(props) => (props.disabled ? "hidden" : "visible")};
  }

  &:active > img {
    filter: ${(props) =>
      props.disabled ? "brightness(0.7)" : "brightness(0.9)"};
  }
`;

export const ButtonChange = styled.div`
  min-width: 30px;
  max-width: 30px;
  cursor: ${(props) => !props.disabled && "pointer"};
  width: ${(props) => props.width && props.width};
  position: relative;
  transition: filter 0.15s linear;
  backface-visibility: hidden;
  will-change: transform;
  &::after {
    visibility: ${(props) => props.islast && "hidden"};
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: -1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    & > div {
      filter: ${(props) => !props.disabled && "brightness(1.2)"};
    }
  }
  &:active {
    & > div {
      filter: ${(props) => !props.disabled && "brightness(0.9)"};
    }
  }
  border-radius: 7px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(0deg, rgba(255, 223, 175, 0.2), rgba(255, 223, 175, 0.2)), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : props.disabled
        ? `linear-gradient(0deg, #301E0D, #301E0D), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
        : `linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
linear-gradient(
180deg,
rgba(255, 217, 159, 0.1) 0%,
rgba(255, 223, 176, 0) 42.19%
),
#6b3700`};
  & > div {
    backface-visibility: hidden;
    will-change: transform;
    transition: filter 0.15s linear;
    filter: ${(props) => props.disabled && "brightness(0.7)"};
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 7px;
    padding: 1px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 11px;
    line-height: 14px;
  }
`;

export const ButtonChangeWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  gap: 2px;
  position: absolute;
  left: 50%;
  bottom: -6px;
  transform: translate(-50%, 100%);
  align-items: center;
`;

export const ButtonValue = styled.div`
  width: 30px;
  position: relative;

  &::after {
    visibility: ${(props) => props.islast && "hidden"};
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: -1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  border-radius: 7px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: linear-gradient(0deg, #3d2713 0%, #3d2713 100%),
    linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
    linear-gradient(
      180deg,
      rgba(255, 217, 159, 0.1) 0%,
      rgba(255, 223, 176, 0) 42.19%
    ),
    linear-gradient(0deg, #6b3700 0%, #6b3700 100%),
    linear-gradient(180deg, rgba(49, 24, 0, 0) 0%, #4e2700 100%),
    linear-gradient(
      180deg,
      rgba(255, 217, 159, 0.1) 0%,
      rgba(255, 223, 176, 0) 42.19%
    ),
    #201102;

  & > div {
    transition: filter 0.15s linear;
    filter: ${(props) => props.disabled && "brightness(0.7)"};
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 7px;
    padding: 1px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 11px;
    line-height: 14px;
  }
`;
