import React, { useEffect, useState } from "react";

function InfoDamage({ calcInfoDamage, countAttacks }) {
  const [damage, setDamage] = useState(calcInfoDamage());

  useEffect(() => {
    setDamage(calcInfoDamage());
  });

  return <>{(damage * countAttacks).toLocaleString("ru")}</>;
}

export default React.memo(InfoDamage);
