import React, { useEffect, useRef } from "react";

import * as S from "./Dice.styles";

function Dice({
  x,
  y,
  rollDice,
  active,
  diceIndex,
  disabled,
  gameStarted,
  rolling,
  number,
}) {
  const diceAreaRef = useRef(null);

  function handleRoll() {
    rollDice({ diceIndex });
  }

  useEffect(() => {
    if (diceAreaRef.current) {
      diceAreaRef.current.classList.remove("hide");
      for (let i = 1; i <= 6; i++) {
        diceAreaRef.current.classList.remove("show-" + i);
      }
      if (number > 0) {
        diceAreaRef.current.classList.add("show-" + number);
      } else {
        diceAreaRef.current.classList.add("hide");
      }
    }
  }, [number]);

  return (
    <S.Dice
      ref={diceAreaRef}
      onClick={active && !disabled ? handleRoll : null}
      x={x}
      y={y}
      active={active}
    >
      <S.Side
        className={`side one`}
        rolling={rolling}
        gameStarted={gameStarted}
        active={active}
      >
        <div className="dot one-1" />
      </S.Side>
      <S.Side
        className="side two"
        rolling={rolling}
        gameStarted={gameStarted}
        active={active}
      >
        <div className="dot two-1" />
        <div className="dot two-2" />
      </S.Side>
      <S.Side
        className="side three"
        rolling={rolling}
        gameStarted={gameStarted}
        active={active}
      >
        <div className="dot three-1" />
        <div className="dot three-2" />
        <div className="dot three-3" />
      </S.Side>
      <S.Side
        className="side four"
        rolling={rolling}
        gameStarted={gameStarted}
        active={active}
      >
        <div className="dot four-1" />
        <div className="dot four-2" />
        <div className="dot four-3" />
        <div className="dot four-4" />
      </S.Side>
      <S.Side
        className="side five"
        rolling={rolling}
        gameStarted={gameStarted}
        active={active}
      >
        <div className="dot five-1" />
        <div className="dot five-2" />
        <div className="dot five-3" />
        <div className="dot five-4" />
        <div className="dot five-5" />
      </S.Side>
      <S.Side
        className="side six"
        rolling={rolling}
        gameStarted={gameStarted}
        active={active}
      >
        <div className="dot six-1" />
        <div className="dot six-2" />
        <div className="dot six-3" />
        <div className="dot six-4" />
        <div className="dot six-5" />
        <div className="dot six-6" />
      </S.Side>
    </S.Dice>
  );
}

export default React.memo(Dice);
